import {
  IActivityBase,
  IActivityItemBase,
  IActivityScheduleBase,
  IActivityScheduleItemBase,
} from '../../models/activities';
import { IBookingQueueBase } from '../../models/bookingQueues';
import { IFeatureGroupBase } from '../../models/features';
import { ISpotBase, ISpotModalityBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export interface IDetailedBookingQueueActivity extends IActivityBase {
  spot: ISpotBase;
}

export interface IDetailedActivitySchedule extends IActivityScheduleBase {
  modality?: ISpotModalityBase;
}

export interface IDetailedBookingQueueActivityScheduleItem
  extends IActivityScheduleItemBase {
  user?: IUserBase;
}

export interface IDetailedBookingQueueUser extends IUserBase {
  featureGroup: IFeatureGroupBase;
}

export interface IDetailedBookingQueue extends IBookingQueueBase {
  activity: IDetailedBookingQueueActivity;
  activitySchedule?: IDetailedActivitySchedule;
  user: IDetailedBookingQueueUser;
  items: IActivityItemBase[];
  modalities: ISpotModalityBase[];
  scheduleItems: IDetailedBookingQueueActivityScheduleItem[];
}

export const showBookingQueuesService = async (
  bookingQueueId: string,
): Promise<IDetailedBookingQueue> => {
  const { data } = await jhsfBookingsApi.get<IDetailedBookingQueue>(
    `/booking-queues/${bookingQueueId}`,
  );

  return data;
};
