import {
  FlexProps,
  Flex,
  Heading,
  Icon,
  Box,
  SimpleGrid,
  HStack,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react';
import { RiEyeLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { IUserBase } from '../../../../../models/users';

interface IHostInfo extends FlexProps {
  host: IUserBase;
}

const HostInfo = ({ host, ...rest }: IHostInfo): JSX.Element => (
  <Flex direction="column" px="8" {...rest}>
    <Heading size="md" fontWeight="normal">
      Responsável
    </Heading>

    <Box mt="4" px="4">
      <SimpleGrid columns={[1, 1, 1, 1, 4]} spacing="4" w="100%">
        <Text fontSize="lg" fontWeight="bold">
          {host.name}
        </Text>

        <HStack>
          <Text fontSize="sm">Telefone:</Text>
          <Text color="gray.500" fontSize="sm">
            {host.phone}
          </Text>
        </HStack>

        <HStack>
          <Text fontSize="sm">E-mail:</Text>
          <Text color="gray.500" fontSize="sm">
            {host.email}
          </Text>
        </HStack>

        <ChakraLink
          ml="auto"
          as={Link}
          to={{
            pathname: '/members/details',
            state: { memberId: host.id },
          }}
        >
          <HStack>
            <Icon color="blue.300" as={RiEyeLine} fontSize="20" />
            <Text color="blue.300">Detalhes</Text>
          </HStack>
        </ChakraLink>
      </SimpleGrid>
    </Box>
  </Flex>
);

export default HostInfo;
