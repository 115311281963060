import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
} from '@chakra-ui/react';
import { IUserBase } from '../../models/users';

export interface IBookingScheduleItem {
  id: string;
  name: string;
  user?: IUserBase;
  totalAmount: number;
  formattedPrice: string;
}

interface IBookingItemProps extends BoxProps {
  scheduleItems: IBookingScheduleItem[];
}

export const BookingScheduleItemTable = ({
  scheduleItems,
  ...rest
}: IBookingItemProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Nome</Th>

          <Th>Prestador de serviço</Th>

          <Th w="48">Valor</Th>
        </Tr>
      </Thead>

      <Tbody>
        {scheduleItems.map((scheduleItem) => (
          <Tr key={scheduleItem.id}>
            <Td>{scheduleItem.name}</Td>

            <Td>{scheduleItem.user?.name || '-'}</Td>

            <Td>{scheduleItem.formattedPrice}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);
