import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiEyeLine,
} from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { ISpotListItem } from '../../../../../../../services/Spots/ListSpotsService';

export interface ISpot extends ISpotListItem {
  formattedCreatedAt: string;
}

interface ISpotTableProps extends BoxProps {
  spots: ISpot[];
  currentPage?: number;
  filterBy?: string;
  onChangeFilterBy?: (value: string) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onSort?: (sort: 'name' | 'createdAt') => void;
  order?: 'ASC' | 'DESC';
  sortBy?: string;
  totalPages?: number;
}

export const SpotTable = ({
  spots,
  currentPage,
  filterBy,
  onChangeFilterBy,
  onFilter,
  onOrder,
  onPageChange,
  onSort,
  order,
  sortBy,
  totalPages,
  ...rest
}: ISpotTableProps): JSX.Element => {
  const filterByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
    ],
    [],
  );

  const sortByOptions = useMemo(
    () => [
      {
        label: 'Nome',
        value: 'name',
      },
      {
        label: 'Data de cadastro',
        value: 'createdAt',
      },
    ],
    [],
  );

  return (
    <Box {...rest}>
      <TableFilters
        filterBy={filterBy}
        filterByOptions={filterByOptions}
        onChangeFilterBy={onChangeFilterBy}
        onFilter={onFilter}
        onOrder={onOrder}
        onSort={(sort) => !!onSort && onSort(sort as 'name' | 'createdAt')}
        order={order}
        sortBy={sortBy}
        sortByOptions={sortByOptions}
      />

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Local</Th>
            <Th textAlign="center">Ativo</Th>
            <Th textAlign="center">Data de cadastro</Th>
            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {spots.map((spot) => (
            <Tr key={spot.id}>
              <Td>
                <Box>
                  <Text fontWeight="bold">{spot.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {spot.venture.name}
                  </Text>
                </Box>
              </Td>

              <Td textAlign="center">
                {spot.isActive ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              <Td textAlign="center">{spot.formattedCreatedAt}</Td>

              <Td textAlign="right">
                <LinkButton
                  px={0}
                  to={{
                    pathname: '/spots/details',
                    state: {
                      spotId: spot.id,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
