import { useCallback, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Flex,
  Button,
  Text,
  Link as ChakraLink,
  useToast,
  VStack,
} from '@chakra-ui/react';
import qs from 'query-string';

import axios from 'axios';
import { MaskedInput } from '../../../components/Form/MaskedInput';
import resetPasswordsService from '../../../services/Auth/ResetPasswordsService';
import { translateError } from '../../../utils/errors';

type ResetPasswordFormData = {
  password: string;
  passwordConfirmation: string;
};

const resetPasswordFormSchema = Yup.object().shape({
  password: Yup.string().required('Senha requerida'),
  passwordConfirmation: Yup.string().oneOf(
    [null, Yup.ref('password')],
    'Senhas não coincidem',
  ),
});

export const ResetPassword = (): JSX.Element => {
  const { search } = useLocation();
  const toast = useToast();
  const { push } = useHistory();
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(resetPasswordFormSchema),
  });

  const token = useMemo(() => qs.parse(search).token?.toString(), [search]);

  const loginPermissions = useMemo(
    () => qs.parse(search).features?.toString().split(',') || [],
    [search],
  );
  // const ventureRef = useMemo(
  //   () => qs.parse(search).ventureref?.toString(),
  //   [search],
  // );

  const { errors } = formState;

  // useEffect(() => {
  //   if (
  //     isMobile &&
  //     ventureRef &&
  //     groupKey &&
  //     token &&
  //     ['MEMBER', 'GUEST'].includes(groupKey)
  //   ) {
  //     window.location.replace(
  //       `${ventureRef.toLowerCase()}://${ventureRef.toLowerCase()}/reset-password?token=${token}`,
  //     );
  //   }
  // }, [groupKey, token, ventureRef]);

  const handleResetPassword: SubmitHandler<ResetPasswordFormData> = useCallback(
    async ({ password, passwordConfirmation }) => {
      if (token) {
        try {
          const user = await resetPasswordsService({
            password,
            passwordConfirmation,
            token,
          });

          toast({
            title: 'Senha alterada com sucesso',
            description: 'Sua senha foi alterada corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          if (
            user.venture?.ref &&
            user.featureGroup?.features.some((feature) =>
              ['APP_CONCIERGE_ACCESS', 'APP_MEMBERS_ACCESS'].includes(
                feature.key,
              ),
            )
          ) {
            push({
              pathname: 'confirm',
              state: {
                loginPermissions,
                ventureRef: user.venture.ref,
              },
            });
          } else {
            // if user isnt an app user redirect to login
            push('/');
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            toast({
              title: 'Falha ao alterar senha',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao tentar alterar a senha, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [loginPermissions, push, toast, token],
  );

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        as="form"
        width="100%"
        maxWidth={360}
        bg="white"
        p="8"
        borderRadius={8}
        flexDir="column"
        onSubmit={handleSubmit(handleResetPassword)}
      >
        <Text fontSize="1.75rem" fontWeight="bold" letterSpacing="tight">
          JHSF-BOOKINGS
          <Text as="span" ml="1" color="blue.500">
            .
          </Text>
        </Text>

        <VStack spacing="8" mt="10">
          <MaskedInput
            id="password"
            type="password"
            label="Senha"
            error={errors.password}
            {...register('password')}
          />

          <MaskedInput
            id="passwordConfirmation"
            type="password"
            label="Confirmação de senha"
            error={errors.passwordConfirmation}
            {...register('passwordConfirmation')}
          />
        </VStack>

        <Button
          type="submit"
          isLoading={formState.isSubmitting}
          mt="8"
          colorScheme="green"
          size="lg"
        >
          Enviar
        </Button>

        {loginPermissions.some(
          (loginPermission) => loginPermission === 'WEB_ACCESS',
        ) && (
          <ChakraLink as={Link} to="/" mt="2" mx="auto">
            Voltar para o login
          </ChakraLink>
        )}
      </Flex>
    </Flex>
  );
};
