import { useLocation } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { showActivitiesService } from '../../../../../services/Activities/ShowActivitiesService';
import { translateError } from '../../../../../utils/errors';
import { useActivity } from '../../../../../hooks/activity';
import { ActivityDetailsInfo } from './components/ActivityDetailsInfo';
import ActivityDetailsData from './components/ActivityDetailsData';

interface ILocationState {
  activityId: string;
}

export const ActivityDetails = (): JSX.Element => {
  const toast = useToast();
  const { activity, handleActivity } = useActivity();

  const {
    state: { activityId },
  } = useLocation<ILocationState>();

  useEffect(() => {
    async function loadActivity(): Promise<void> {
      try {
        const activityData = await showActivitiesService(activityId);

        handleActivity(activityData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes da atividade, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadActivity();

    return () => handleActivity(undefined);
  }, [activityId, handleActivity, toast]);

  return (
    <DefaultLayout>
      {activity && (
        <Flex direction="column" w="100%">
          <ActivityDetailsInfo minW="510px" />

          <ActivityDetailsData flex="1" mt="8" minW="510px" />
        </Flex>
      )}
    </DefaultLayout>
  );
};
