import React, { createContext, useCallback, useState, useContext } from 'react';
import { IUserBase } from '../models/users';
import { IDetailedUser } from '../services/Users/ShowUsersService';
import { maskCnpj, maskCpf, maskDate } from '../utils/formatters/handleMask';

interface IHandleUserProps extends IDetailedUser {
  guests?: IUserBase[];
  dependants?: IUserBase[];
}

interface IUserContextState extends IDetailedUser {
  formattedBirthDate?: string;
  formattedBlockedUntil?: string;
  formattedCreatedAt: string;
  formattedHealthCheckDate?: string;
}

interface IUserContextData {
  user?: IUserContextState;
  handleUser: (user?: IHandleUserProps) => void;
}

const UserContext = createContext<IUserContextData>({} as IUserContextData);

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUserContextState>();

  const handleUser = useCallback((userData?: IHandleUserProps) => {
    if (!userData) {
      setUser(undefined);
      return;
    }

    setUser({
      ...userData,
      cpf: userData.cpf ? maskCpf(userData.cpf) : undefined,
      cnpj: userData.cnpj ? maskCnpj(userData.cnpj) : undefined,
      formattedBirthDate: userData.birthDate
        ? maskDate(userData.birthDate)
        : undefined,
      formattedBlockedUntil: userData.blockedUntil
        ? maskDate(userData.blockedUntil)
        : undefined,
      formattedCreatedAt: maskDate(userData.createdAt),
      formattedHealthCheckDate: userData.healthCheck
        ? maskDate(userData.healthCheck)
        : undefined,
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        handleUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): IUserContextData => useContext(UserContext);

export { UserProvider, useUser };
