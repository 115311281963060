import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import {
  IActivityScheduleBase,
  IActivityScheduleBlockBase,
  WaveSideEnum,
} from '../../models/activities';
import { IBookingBase } from '../../models/bookings';

interface IUpdateActivityScheduleBlocks {
  blockedDate: string;
}

interface IUpdateActivityScheduleProps {
  activityScheduleId: string;
  allowLinkBookings?: boolean;
  allowPartialTimeBookings?: boolean;
  bookingPrice?: number;
  daysOfWeek: Array<number>;
  endDate?: string | null;
  endTime: string;
  isActive?: boolean;
  isVerified?: boolean;
  minUserExperience: number;
  modalityId?: string | null;
  scheduleBlocks: IUpdateActivityScheduleBlocks[];
  startDate: string;
  startTime: string;
  guestVacancies: number;
  totalVacancies: number;
  waveSide?: WaveSideEnum;
}

export interface IUpdatedActivitySchedule extends IActivityScheduleBase {
  bookings: IBookingBase[];
  scheduleBlocks: IActivityScheduleBlockBase[];
}

export const updateActivitySchedulesService = async ({
  activityScheduleId,
  allowLinkBookings,
  allowPartialTimeBookings,
  bookingPrice,
  daysOfWeek,
  endDate,
  endTime,
  guestVacancies,
  isActive,
  isVerified,
  minUserExperience,
  modalityId,
  scheduleBlocks,
  startDate,
  startTime,
  totalVacancies,
  waveSide,
}: IUpdateActivityScheduleProps): Promise<IUpdatedActivitySchedule> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedActivitySchedule>(
    `/activities/schedules/${activityScheduleId}`,
    {
      allowLinkBookings,
      allowPartialTimeBookings,
      bookingPrice,
      daysOfWeek,
      endDate,
      endTime,
      guestVacancies,
      isActive,
      minUserExperience,
      modalityId,
      scheduleBlocks,
      startDate,
      startTime,
      totalVacancies,
      waveSide,
    },
    { params: { isVerified } },
  );

  return data;
};
