const apiConfig = {
  baseURL:
    process.env.REACT_APP_API_URL ??
    'https://api-clubs.jhsfdigital-sandbox.com',
};

if (!process.env.REACT_APP_API_URL) {
  if (window.location.hostname.includes('clubs-test')) {
    apiConfig.baseURL = 'https://api-test-clubs.jhsfdigital-sandbox.com';
  } else if (window.location.hostname.includes('sandbox')) {
    apiConfig.baseURL = 'https://api-clubs.jhsfdigital-sandbox.com';
  } else {
    apiConfig.baseURL = 'https://api-clubs.jhsfdigital.com';
  }

  if (window.location.hostname.includes('localhost')) {
    apiConfig.baseURL = 'http://localhost:4000';
  }
}

export default apiConfig.baseURL;
