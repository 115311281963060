import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Heading,
  useToast,
  Icon,
  Flex,
  Button,
  Text,
  CircularProgress,
  ModalFooter,
} from '@chakra-ui/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { RiAddLine } from 'react-icons/ri';
import { useBooking } from '../../hooks/booking';
import { createBookingCheckinsService } from '../../services/Bookings/CreateBookingCheckinsService';
import deleteBookingCheckinsService from '../../services/Bookings/DeleteBookingCheckinsService';
import { listBookingCheckinsService } from '../../services/Bookings/ListBookingCheckinsService';
import { translateError } from '../../utils/errors';
import { maskDateTime } from '../../utils/formatters/handleMask';
import { ConfirmationModal } from '../ConfirmationModal';
import { CheckinTable, ICheckin } from './components/CheckinTable';

interface ICheckinListModalProps {
  bookingId: string;
  checkinEnabled: boolean;
  isOpen: boolean;
  onClose: () => void;
}
export const CheckinListModal = ({
  bookingId,
  checkinEnabled,
  isOpen,
  onClose,
}: ICheckinListModalProps): JSX.Element => {
  const toast = useToast();

  const { booking, handleBooking } = useBooking();

  const [checkinList, setCheckinList] = useState<ICheckin[]>([]);
  const [loading, setLoading] = useState(false);
  const [
    isCreateCheckinConfirmationModalVisible,
    setIsCreateCheckinConfirmationModalVisible,
  ] = useState(false);

  const handleToggleCreateCheckinConfirmationModal = (): void => {
    setIsCreateCheckinConfirmationModalVisible((prevState) => !prevState);
  };

  useEffect(() => {
    async function loadCheckins(): Promise<void> {
      try {
        setLoading(true);

        const bookingCheckins = await listBookingCheckinsService({ bookingId });

        setCheckinList(
          bookingCheckins.map((checkin) => ({
            ...checkin,
            formattedCreatedAt: maskDateTime(checkin.createdAt),
          })),
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados dos checkins, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } finally {
        setLoading(false);
      }
    }

    loadCheckins();
  }, [bookingId, toast]);

  const handleCreateCheckin = async (): Promise<void> => {
    try {
      setLoading(true);

      handleToggleCreateCheckinConfirmationModal();

      const checkin = await createBookingCheckinsService(bookingId);

      setCheckinList((prevState) => [
        { ...checkin, formattedCreatedAt: maskDateTime(checkin.createdAt) },
        ...prevState,
      ]);

      if (booking) {
        handleBooking({ ...booking, checkins: [...booking.checkins, checkin] });
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao cadastrar',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao cadastrar o checkin, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCheckin = async (checkinId: string): Promise<void> => {
    try {
      setLoading(true);
      await deleteBookingCheckinsService(checkinId);

      setCheckinList((prevState) =>
        prevState.filter((checkin) => checkin.id !== checkinId),
      );

      if (booking) {
        handleBooking({
          ...booking,
          checkins: booking.checkins.filter(
            (checkin) => checkin.id !== checkinId,
          ),
        });
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao excluir',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao excluir o checkin, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isCreateCheckinConfirmationModalVisible}
        onClose={handleToggleCreateCheckinConfirmationModal}
        onConfirm={handleCreateCheckin}
        title="Confirmar checkin"
        message="Deseja realmente criar um checkin para essa reserva?"
      />

      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <Heading size="lg" fontWeight="normal">
              Checkins da reserva
            </Heading>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody maxH="80vh" overflow="auto">
            <Flex justify="space-between">
              <Flex justify="center" flex={1}>
                {loading && !!checkinList.length && (
                  <Flex align="center">
                    <CircularProgress
                      isIndeterminate
                      color="teal"
                      size="2rem"
                    />

                    <Text ml="2" color="gray.600">
                      Carregando...
                    </Text>
                  </Flex>
                )}
              </Flex>

              {checkinEnabled && (
                <Button
                  size="sm"
                  colorScheme="green"
                  leftIcon={<Icon as={RiAddLine} fontSize="16" />}
                  onClick={handleToggleCreateCheckinConfirmationModal}
                >
                  Checkin
                </Button>
              )}
            </Flex>

            {checkinList.length ? (
              <CheckinTable
                checkins={checkinList}
                onDelete={handleDeleteCheckin}
              />
            ) : (
              <Flex w="full" h="32" justify="center" align="center">
                {loading ? (
                  <Flex align="center">
                    <CircularProgress
                      isIndeterminate
                      color="teal"
                      size="2rem"
                    />

                    <Text ml="2" color="gray.600">
                      Carregando...
                    </Text>
                  </Flex>
                ) : (
                  <Text color="gray.600">Nenhum checkin cadastrado</Text>
                )}
              </Flex>
            )}
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};
