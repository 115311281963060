import { Box, Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { ReactNode, useCallback } from 'react';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import { FaUserCircle } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';

interface IAvatarDropzoneProps extends DropzoneProps {
  avatarUrl?: string;
  onChange: (file: File) => void;
  onDelete: () => void;
}

export const AvatarDropzone = ({
  avatarUrl,
  onChange,
  onDelete,
  disabled,
  ...rest
}: IAvatarDropzoneProps): JSX.Element => {
  const renderDragMessage = useCallback(
    (isDragActive: boolean, isDragReject: boolean): ReactNode => {
      if (!isDragActive) {
        return (
          <Flex h="12" mt="4" justify="center" align="center">
            <Text fontSize="12" color="gray.500">
              Clique para selecionar ou arraste e solte uma imagem aqui
            </Text>
          </Flex>
        );
      }

      if (isDragReject) {
        return (
          <Flex h="12" mt="4" justify="center" align="center">
            <Text fontSize="12" color="red.500">
              Arquivo não suportado
            </Text>
          </Flex>
        );
      }

      return (
        <Flex h="12" mt="4" justify="center" align="center">
          <Text fontSize="12" color="green.500">
            Solte o arquivo aqui
          </Text>
        </Flex>
      );
    },
    [],
  );

  return (
    <Box position="relative">
      <Dropzone
        accept="image/*"
        disabled={disabled}
        onDropAccepted={(files) => onChange(files[0])}
        {...rest}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
          let currentBorderColor = 'gray.300';
          let currentBgColor = 'gray.100';
          let currentIconColor = 'gray.400';

          if (isDragActive) {
            currentBorderColor = 'green.300';
            currentIconColor = 'green.400';
            currentBgColor = 'green.50';
          }

          if (isDragReject) {
            currentBorderColor = 'red.300';
            currentIconColor = 'red.400';
            currentBgColor = 'red.50';
          }

          return (
            <Box
              h="40"
              w="40"
              p={avatarUrl ? '0' : '4'}
              bg={currentBgColor}
              borderColor={currentBorderColor}
              borderWidth={1}
              borderRadius={8}
              cursor="pointer"
              textAlign="center"
              className="dropzone"
              {...getRootProps()}
            >
              {!avatarUrl && (
                <Box>
                  <Icon
                    fontSize="60"
                    color={currentIconColor}
                    as={FaUserCircle}
                  />
                  {renderDragMessage(isDragActive, isDragReject)}
                </Box>
              )}

              {avatarUrl && (
                <Image
                  h="100%"
                  w="100%"
                  borderRadius={8}
                  src={avatarUrl}
                  alt="avatar"
                />
              )}

              <input {...getInputProps()} />
            </Box>
          );
        }}
      </Dropzone>

      {avatarUrl && !disabled && (
        <Button
          p={0}
          size="sm"
          borderRadius="50%"
          colorScheme="red"
          onClick={onDelete}
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top="-4"
          right="-4"
        >
          <Icon as={RiDeleteBinLine} fontSize="16" />
        </Button>
      )}
    </Box>
  );
};
