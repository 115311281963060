import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import { IDetailedBooking } from './ShowBookingsService';

export type IBookingExternalPayment = IDetailedBooking;

export const createBookingExternalPaymentsService = async (
  bookingId: string,
): Promise<IBookingExternalPayment> => {
  const { data } = await jhsfBookingsApi.post<IBookingExternalPayment>(
    `/bookings/${bookingId}/external-payments`,
  );

  return data;
};
