import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ButtonGroup,
  VStack,
  useToast,
  Box,
  Text,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory } from 'react-router';
import { parseISO } from 'date-fns';
import StateManager, { GroupTypeBase, OptionTypeBase } from 'react-select';
import Select from 'react-select/src/Select';
import {
  IActivityItemBase,
  IActivityScheduleItemBase,
} from '../../models/activities';
import { ISpotModalityBase } from '../../models/spots';
import { createBookingQueuesService } from '../../services/BookingQueues/CreateBookingQueuesService';
import { listUsersService } from '../../services/Users/ListUsersService';
import { translateError } from '../../utils/errors';
import { AsyncSelectOption, AsyncSelect } from '../Form/AsyncSelect';
import { DatePicker } from '../Form/DatePicker';
import { MaskedInput } from '../Form/MaskedInput';
import { ReactMultiSelect } from '../Form/ReactMultiSelect';
import { SelectOption, ReactSelect } from '../Form/ReactSelect';
import {
  IBookingQueueContextState,
  useBookingQueue,
} from '../../hooks/bookingQueue';
import { IActivityContextState } from '../../hooks/activity';
import { updateBookingQueuesService } from '../../services/BookingQueues/UpdateBookingQueuesService';
import { createBookingsService } from '../../services/Bookings/CreateBookingsService';
import { ConfirmationModal } from '../ConfirmationModal';
import {
  IWeekdayActivitySchedule,
  listWeekdaysActivitiesSchedulesService,
} from '../../services/Activities/ListWeekdaysActivitySchedulesService';
import { UserExperience } from '../../models/users';
import { maskMoney } from '../../utils/formatters/handleMask';
import { listAvailableActivityScheduleItemsService } from '../../services/Activities/ListAvailableActivityScheduleItemsService';

interface ILoadSchedulesProps {
  activityId: string;
  userId: string;
}

type NewBookingQueueFormData = {
  activityScheduleId?: string;
  bookedDate: Date;
  description?: string;
  itemsId?: string[];
  modalitiesId?: string[];
  scheduleItemsId?: string[];
  selectedUser: SelectOption;
};

export interface INewBookingQueueData extends NewBookingQueueFormData {
  items?: IActivityItemBase[];
  modalities?: ISpotModalityBase[];
}

interface IHandleBookingQueueModalScheduleItem
  extends IActivityScheduleItemBase {
  formattedPrice?: string;
}

interface IHandleBookingQueueModalProps {
  activity: IActivityContextState;
  bookingQueue?: IBookingQueueContextState;
  isBookSubmiting?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const registerBookingFormSchema = Yup.object().shape({
  activityScheduleId: Yup.string().uuid().nullable(),
  bookedDate: Yup.date()
    .required('Requerido')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  itemsId: Yup.array()
    .nullable()
    .transform((_, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
  scheduleItemsId: Yup.array()
    .nullable()
    .transform((_, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
  modalitiesId: Yup.array()
    .nullable()
    .transform((_, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
  selectedUser: Yup.object()
    .shape({
      label: Yup.string().required('Requerido'),
      value: Yup.string().uuid().nullable().required('Requerido'),
    })
    .nullable()
    .required('Requerido'),
});

export const HandleBookingQueueModal = ({
  activity,
  bookingQueue,
  isBookSubmiting = false,
  isOpen,
  onClose,
}: IHandleBookingQueueModalProps): JSX.Element => {
  const toast = useToast();

  const { bookingQueue: currentBookingQueue, handleBookingQueue } =
    useBookingQueue();
  const { push } = useHistory();

  const formRef = useRef<HTMLElement & HTMLFormElement>(null);
  const activityScheduleSelectRef =
    useRef<
      StateManager<
        OptionTypeBase,
        false,
        GroupTypeBase<OptionTypeBase>,
        Select<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>>
      >
    >(null);

  let startMonth = new Date().getMonth();
  let startYear = new Date().getFullYear();

  if (bookingQueue?.activitySchedule) {
    startMonth =
      Number(bookingQueue?.activitySchedule?.startDate.slice(5, 7)) - 1;
    startYear = Number(bookingQueue?.activitySchedule?.startDate.slice(0, 4));
  }

  const [includeDates, setIncludeDates] = useState<Date[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(startMonth);
  const [selectedYear, setSelectedYear] = useState(startYear);
  const [activityScheduleSelectOptions, setActivityScheduleSelectOptions] =
    useState<SelectOption[]>([]);
  const [eventSchedules, setEventSchedules] = useState<
    IWeekdayActivitySchedule[]
  >([]);
  const [scheduleItems, setScheduleItems] = useState<
    IHandleBookingQueueModalScheduleItem[]
  >([]);
  const [scheduleRequiredItems, setScheduleRequiredItems] = useState<
    IHandleBookingQueueModalScheduleItem[]
  >([]);
  const [itemsSelectOptions, setItemsSelectOptions] = useState<SelectOption[]>(
    [],
  );
  const [modalitiesSelectOptions, setModalitiesSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const scheduleItemsSelectOptions = useMemo(
    () =>
      scheduleItems.map((scheduleItem) => ({
        label: scheduleItem.formattedPrice
          ? scheduleItem.name.concat(` - ${scheduleItem.formattedPrice}`)
          : scheduleItem.name,
        value: scheduleItem.id,
      })),
    [scheduleItems],
  );

  const {
    register,
    formState,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(registerBookingFormSchema),
  });

  const { errors } = formState;

  const selectedBookedDateTime: Date | undefined = watch('bookedDate');

  const selectedBookedDate = selectedBookedDateTime
    ? selectedBookedDateTime.toLocaleDateString('fr-CA', {
        timeZone: 'America/Sao_Paulo',
      })
    : undefined;

  const selectedActivityScheduleId: string | undefined =
    watch('activityScheduleId');

  const selectedModalities: string[] | undefined = watch('modalitiesId');

  const currentUser: { value: string } | undefined = watch('selectedUser');

  useEffect(() => {
    async function loadSchedules({
      activityId,
      userId,
    }: ILoadSchedulesProps): Promise<void> {
      try {
        const eventsSchedulesData =
          await listWeekdaysActivitiesSchedulesService({
            activityId,
            selectedMonth,
            selectedYear,
            userId,
            showExpired: false,
          });

        const scheduleMonthDaysAvailable = eventsSchedulesData.map((event) => {
          const [year, month, day] = event.date.split('-');

          return new Date(+year, +month - 1, +day);
        });

        setEventSchedules(eventsSchedulesData);
        setIncludeDates(scheduleMonthDaysAvailable);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar as datas disponíveis, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (!!activity && !!currentUser) {
      loadSchedules({ activityId: activity.id, userId: currentUser.value });
    }
  }, [activity, selectedYear, selectedMonth, toast, currentUser]);

  useEffect(() => {
    if (!!eventSchedules.length && !!selectedBookedDate) {
      const parsedSchedulesSelectOptions = eventSchedules
        .filter((eventSchedule) => eventSchedule.date === selectedBookedDate)
        .map((eventSchedule) => ({
          label: `${eventSchedule.startTime.slice(
            0,
            5,
          )} - ${eventSchedule.endTime.slice(0, 5)}${
            eventSchedule.modalityTitle
              ? ` - ${eventSchedule.modalityTitle}`
              : ''
          } - ${UserExperience[eventSchedule.experience]}`,
          value: eventSchedule.activityScheduleId,
        }));

      setActivityScheduleSelectOptions(parsedSchedulesSelectOptions);
    }
  }, [
    bookingQueue?.modalities,
    isBookSubmiting,
    eventSchedules,
    selectedBookedDate,
    selectedModalities,
  ]);

  useEffect(() => {
    if (activity.items.length) {
      setItemsSelectOptions(
        activity.items
          .filter((item) => item.isActive)
          .map((item) => ({
            label: item.name,
            value: item.id,
          })),
      );
    }
  }, [activity.items]);

  useEffect(() => {
    if (activity.spot.modalities.length) {
      setModalitiesSelectOptions(
        activity.spot.modalities
          .filter((modality) => modality.isActive)
          .map((modality) => ({
            label: modality.title,
            value: modality.id,
          })),
      );
    }
  }, [activity.spot.modalities]);

  useEffect(() => {
    if (selectedActivityScheduleId) {
      setValue('modalitiesId', []);
    }
  }, [selectedActivityScheduleId, setValue]);

  useEffect(() => {
    const loadSchedule = async (id: string): Promise<void> => {
      try {
        const { activityScheduleItems, activityScheduleRequiredItems } =
          await listAvailableActivityScheduleItemsService({
            scheduleId: id,
            bookedDate: selectedBookedDate,
          });

        setScheduleItems(
          activityScheduleItems.map((scheduleItem) => ({
            ...scheduleItem,
            formattedPrice: scheduleItem.price
              ? maskMoney(scheduleItem.price)
              : undefined,
          })),
        );

        setScheduleRequiredItems(
          activityScheduleRequiredItems.map((scheduleItem) => ({
            ...scheduleItem,
            formattedPrice: scheduleItem.price
              ? maskMoney(scheduleItem.price)
              : undefined,
          })),
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os opcionais do evento, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    };

    if (selectedActivityScheduleId) {
      loadSchedule(selectedActivityScheduleId);
    }
  }, [selectedActivityScheduleId, selectedBookedDate, toast]);

  useEffect(() => {
    if (!bookingQueue) {
      setValue('activityScheduleId', null);

      activityScheduleSelectRef.current?.select.clearValue();

      setValue('bookedDate', null);

      setActivityScheduleSelectOptions([]);
    }
  }, [bookingQueue, currentUser, setValue]);

  useEffect(() => {
    if (!bookingQueue) {
      setValue('activityScheduleId', null);

      activityScheduleSelectRef.current?.select.clearValue();
    }
  }, [bookingQueue, selectedBookedDate, setValue]);

  const handleClearActivitySchedule = (): void => {
    setValue('activityScheduleId', null);

    activityScheduleSelectRef.current?.select.clearValue();
  };

  useEffect(() => {
    if (bookingQueue) {
      reset({
        activityScheduleId: bookingQueue.activityScheduleId,
        bookedDate: parseISO(bookingQueue.bookedDate),
        description: bookingQueue.description,
        itemsId: bookingQueue.items.map((item) => ({
          label: item.name,
          value: item.id,
        })),
        scheduleItemsId: bookingQueue.scheduleItems.map((scheduleItem) => ({
          label: scheduleItem.formattedPrice
            ? scheduleItem.name.concat(` - ${scheduleItem.formattedPrice}`)
            : scheduleItem.name,
          value: scheduleItem.id,
        })),
        modalitiesId: bookingQueue.modalities.map((modality) => ({
          label: modality.title,
          value: modality.id,
        })),
        selectedUser: {
          label: bookingQueue.user.name,
          value: bookingQueue.userId,
        },
      });
    }
  }, [bookingQueue, reset]);

  const handleLoadUserSelectOption = useCallback(
    async (name?: string): Promise<AsyncSelectOption[]> => {
      if (!activity) {
        return [];
      }

      const { items: users } = await listUsersService({
        name,
        featureGroups: ['MEMBER', 'DEPENDANT', 'GUEST'],
        ventureId: activity.spot.ventureId,
        limit: 4,
      });

      const parsedUsersSelectOption: AsyncSelectOption[] = [
        ...users.map((user) => ({
          label: user.name,
          value: user.id,
        })),
      ];

      return parsedUsersSelectOption;
    },
    [activity],
  );

  const handleToggleConfirmationModal = useCallback(() => {
    setIsConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleBookingConfirm = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (isVerified) {
      formRef.current?.requestSubmit();
    }
  }, [isVerified]);

  const handleCloseModal = useCallback(() => {
    reset();

    setIncludeDates([]);

    setSelectedMonth(new Date().getMonth());

    setSelectedYear(new Date().getFullYear());

    setActivityScheduleSelectOptions([]);

    setEventSchedules([]);

    setItemsSelectOptions([]);

    setModalitiesSelectOptions([]);

    onClose();
  }, [onClose, reset]);

  const handleBookingQueueSubmit: SubmitHandler<NewBookingQueueFormData> =
    useCallback(
      async ({
        activityScheduleId,
        bookedDate,
        description,
        itemsId,
        scheduleItemsId,
        modalitiesId,
        selectedUser,
      }) => {
        const selectedItems = activity.items.filter((item) =>
          itemsId?.includes(item.id),
        );

        const selectedScheduleItems = scheduleItems
          .filter((item) => scheduleItemsId?.includes(item.id))
          .map(({ formattedPrice: _, ...scheduleItem }) => scheduleItem);

        const modalities = activity.spot.modalities.filter((modality) =>
          modalitiesId?.includes(modality.id),
        );

        if (!isBookSubmiting && !bookingQueue) {
          try {
            const bookingQueueData = await createBookingQueuesService({
              activityId: activity.id,
              activityScheduleId,
              bookedDate,
              description,
              items: selectedItems,
              modalities,
              scheduleItems: selectedScheduleItems,
              userId: selectedUser.value,
            });

            toast({
              title: 'Cadastrado com sucesso',
              description: 'A reserva foi cadastrada corretamente na fila.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });

            push('/booking-queues/details', {
              bookingQueueId: bookingQueueData.id,
            });
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha no cadastro',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao cadastrar a reserva na fila, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        } else if (!isBookSubmiting && !!bookingQueue) {
          try {
            const bookingQueueData = await updateBookingQueuesService({
              activityScheduleId,
              bookingQueueId: bookingQueue.id,
              bookedDate,
              description,
              items: selectedItems,
              scheduleItems: selectedScheduleItems,
              modalities,
            });

            toast({
              title: 'Atualizado com sucesso',
              description: 'A reserva foi atualizada corretamente na fila.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });

            if (currentBookingQueue) {
              handleBookingQueue({
                ...currentBookingQueue,
                ...bookingQueueData,
              });
            }

            handleCloseModal();
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha no cadastro',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao atualizar a reserva na fila, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        }

        if (!!isBookSubmiting && !activityScheduleId) {
          setError('activityScheduleId', {
            message: 'Requerido',
          });

          return;
        }

        if (!!isBookSubmiting && !!bookingQueue && !!activityScheduleId) {
          try {
            const booking = await createBookingsService({
              activityScheduleId,
              bookedDate,
              bookingQueueId: bookingQueue.id,
              description,
              items: selectedItems,
              isVerified,
              notifyCustomer: true,
              scheduleItems: selectedScheduleItems,
              skipLimits: true,
              userId: bookingQueue.userId,
            });

            toast({
              title: 'Cadastrado com sucesso',
              description: 'A reserva foi cadastrada corretamente.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });

            setIsVerified(false);
            setIsConfirmationModalVisible(false);

            push('/bookings/details', {
              bookingId: booking.id,
            });
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              if (err.response?.data.message === 'spot-already-booked') {
                setIsConfirmationModalVisible(true);

                return;
              }

              if (
                [
                  'user-address-missing',
                  'user-birth-date-missing',
                  'user-cpf-missing',
                  'user-phone-missing',
                ].includes(err.response?.data.message)
              ) {
                toast({
                  title: 'Cadastro incompleto',
                  description: `A reserva foi efetuada, necessário completar o cadastro do membro para efetuar pagamento. ${translateError(
                    { message: err.response?.data.message },
                  )}`,
                  status: 'warning',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });

                setIsConfirmationModalVisible(false);

                push('/bookings/details', {
                  bookingId: err.response?.data.metaData.bookingId,
                });

                return;
              }

              toast({
                title: 'Falha no cadastro',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao cadastrar a reserva, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        }
      },
      [
        activity.items,
        activity.spot.modalities,
        activity.id,
        scheduleItems,
        isBookSubmiting,
        bookingQueue,
        toast,
        push,
        currentBookingQueue,
        handleCloseModal,
        handleBookingQueue,
        setError,
        isVerified,
      ],
    );

  const handleMonthChange = useCallback((date: Date) => {
    setSelectedMonth(date.getMonth());
    setSelectedYear(date.getFullYear());
  }, []);

  useEffect(() => {
    const bookedDate = bookingQueue?.bookedDate
      ? new Date(bookingQueue.bookedDate.concat('T00:00'))
      : undefined;

    if (bookedDate) {
      handleMonthChange(bookedDate);
    }
  }, [bookingQueue?.bookedDate, handleMonthChange]);

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ConfirmationModal
        isOpen={isConfirmationModalVisible}
        onClose={handleToggleConfirmationModal}
        onConfirm={handleBookingConfirm}
        message="Já existe reservas pendentes para esse membro nesse local, deseja
        criar nova reserva?"
        title="Confirmação de reserva"
      />

      <ModalOverlay />

      <ModalContent
        as="form"
        ref={formRef}
        onSubmit={handleSubmit(handleBookingQueueSubmit)}
      >
        <ModalHeader>
          {!!isBookSubmiting && (
            <Heading size="lg" fontWeight="normal">
              Cadastrar reserva
            </Heading>
          )}

          {!isBookSubmiting && !bookingQueue ? (
            <Heading size="lg" fontWeight="normal">
              Cadastrar reserva na fila
            </Heading>
          ) : (
            !isBookSubmiting && (
              <Heading size="lg" fontWeight="normal">
                Editar reserva na fila
              </Heading>
            )
          )}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="8">
            <AsyncSelect
              isDisabled={!!bookingQueue}
              label="Membro"
              name="selectedUser"
              loadOptions={handleLoadUserSelectOption}
              control={control}
              error={errors.selectedUser}
            />

            <DatePicker
              label="Data"
              includeDates={includeDates}
              isClearable={false}
              isDisabled={!includeDates.length || !!isBookSubmiting}
              minDate={new Date()}
              onMonthChange={handleMonthChange}
              onFocus={handleClearActivitySchedule}
              control={control}
              error={errors.bookedDate}
              {...register('bookedDate')}
            />

            <ReactSelect
              ref={activityScheduleSelectRef}
              label="Horário"
              name="activityScheduleId"
              isClearable
              isDisabled={
                !currentUser ||
                !selectedBookedDate ||
                (!!isBookSubmiting && !!bookingQueue?.activityScheduleId)
              }
              options={activityScheduleSelectOptions}
              control={control}
              error={errors.activityScheduleId}
            />

            {activity.items.length && (
              <ReactMultiSelect
                label="Opcionais da atividade"
                name="itemsId"
                options={itemsSelectOptions}
                control={control}
                error={errors.itemsId}
              />
            )}

            {scheduleItemsSelectOptions.length && (
              <ReactMultiSelect
                label="Opcionais do evento"
                name="scheduleItemsId"
                options={scheduleItemsSelectOptions}
                control={control}
                error={errors.scheduleItemsId}
              />
            )}

            {activity.spot.modalities.length && !selectedActivityScheduleId && (
              <ReactMultiSelect
                label="Modalidades"
                name="modalitiesId"
                isDisabled={!!isBookSubmiting}
                options={modalitiesSelectOptions}
                control={control}
                error={errors.modalitiesId}
              />
            )}

            {scheduleRequiredItems.map((scheduleItem) => (
              <Text>
                {scheduleItem.formattedPrice
                  ? scheduleItem.name.concat(
                      ` - ${scheduleItem.formattedPrice}`,
                    )
                  : scheduleItem.name}
              </Text>
            ))}

            <MaskedInput
              label="Descrição"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.description}
              {...register('description')}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Box>
            <ButtonGroup mt="6" w="full" justifyContent="flex-end">
              <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
                Cancelar
              </Button>

              <Button
                colorScheme="green"
                type="submit"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
