import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
} from '@chakra-ui/react';

export interface IBookingItem {
  id: string;
  description?: string;
  name: string;
}

interface IBookingItemProps extends BoxProps {
  bookingItems: IBookingItem[];
}

export const BookingItemTable = ({
  bookingItems,
  ...rest
}: IBookingItemProps): JSX.Element => (
  <Box {...rest}>
    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Nome</Th>

          <Th>Descrição</Th>
        </Tr>
      </Thead>

      <Tbody>
        {bookingItems.map((bookingItem) => (
          <Tr key={bookingItem.id}>
            <Td>{bookingItem.name}</Td>

            <Td>{bookingItem.description}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Box>
);
