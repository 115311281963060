import { IFeatureBase, IFeatureGroupBase } from '../../models/features';
import { IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export interface IUpdateUserDetailedFeatureGroup extends IFeatureGroupBase {
  features: IFeatureBase[];
}

export interface IUpdatedUser extends IUserBase {
  featureGroup: IUpdateUserDetailedFeatureGroup;
}

export const enableUsersPaymentRegisterService = async (
  userId: string,
): Promise<IUpdatedUser> => {
  const { data } = await jhsfBookingsApi.post<IUpdatedUser>(
    `/users/${userId}/enable-payment`,
  );

  return data;
};
