import {
  Button,
  CircularProgress,
  Text,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import React from 'react';
import { ToolbarProps } from 'react-big-calendar';
import ReactDatePicker from 'react-datepicker';
import { maskLongDate } from '../../../../../../../../../../../../../utils/formatters/handleMask';

type Props = ToolbarProps & {
  loading?: boolean;
};

export const ToolbarComponent: React.FC<Props> = ({
  label,
  onNavigate,
  loading,
  onView,
  view,
  date,
}: Props) => {
  const handleChangeDate = (selectedDate: Date): void =>
    onNavigate('DATE', selectedDate);

  return (
    <HStack justify="space-between" p="4" zIndex={1000}>
      <HStack spacing="4">
        <HStack>
          <IconButton
            aria-label="Anterior"
            icon={<ChevronLeftIcon />}
            onClick={() => onNavigate('PREV')}
            size="sm"
          />

          <Button size="sm" ml={4} mr={4} onClick={() => onNavigate('TODAY')}>
            Hoje
          </Button>

          <IconButton
            aria-label="Próxima"
            icon={<ChevronRightIcon />}
            onClick={() => onNavigate('NEXT')}
            size="sm"
          />
        </HStack>

        <HStack>
          <ReactDatePicker
            dateFormat="dd/MM/yyyy"
            locale="pt-BR"
            onChange={handleChangeDate}
            todayButton="Hoje"
            selected={date}
            customInput={
              <Button size="sm" display="flex" alignItems="center">
                <Text textTransform="capitalize" fontWeight="bold">
                  <CalendarIcon mr={2} />

                  {view === 'day' ? maskLongDate(date) : label}
                </Text>
              </Button>
            }
          />

          {loading && (
            <CircularProgress isIndeterminate color="teal" size="2rem" />
          )}
        </HStack>
      </HStack>

      <HStack>
        <Button
          bg={view === 'day' ? 'blue.100' : 'transparent'}
          borderRadius={50}
          color={view === 'day' ? 'blue.700' : 'gray.800'}
          onClick={() => onView('day')}
          size="sm"
        >
          Dia
        </Button>

        <Button
          bg={view === 'week' ? 'blue.100' : 'transparent'}
          borderRadius={50}
          color={view === 'week' ? 'blue.700' : 'gray.800'}
          onClick={() => onView('week')}
          size="sm"
        >
          Semana
        </Button>
      </HStack>
    </HStack>
  );
};
