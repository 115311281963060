import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Heading,
  ButtonGroup,
  HStack,
} from '@chakra-ui/react';

interface IPaymentConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const PaymentConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: IPaymentConfirmationModalProps): JSX.Element => (
  <Modal size="lg" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="lg" fontWeight="normal">
          Confirmação de pagamento externo
        </Heading>
      </ModalHeader>

      <ModalCloseButton />

      <ModalBody>
        <HStack spacing="1">
          <Text>A reserva será marcada como</Text>
          <Text as="ins" fontWeight="500">
            paga externamente.
          </Text>
        </HStack>

        <Text mt="6" fontSize="sm" color="red.500">
          Em caso de cancelamento o reembolso também deverá ser feito
          externamente.
        </Text>

        <Text mt="4" fontSize="sm" color="red.500">
          Não haverá integração automática dos valores dessa reserva com o
          sistema financeiro, dados para geração da nota fiscal deverão ser
          repassados externamente.
        </Text>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button colorScheme="blackAlpha" onClick={onClose}>
            Cancelar
          </Button>

          <Button colorScheme="green" onClick={onConfirm}>
            Confirmar
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
