import { IFeatureBase, IFeatureGroupBase } from '../../models/features';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export interface IDetailedFeatureGroup extends IFeatureGroupBase {
  features: IFeatureBase[];
  featuresCount: number;
  usersCount: number;
}

export const showFeatureGroupsService = async (
  featureGroupId: string,
): Promise<IDetailedFeatureGroup> => {
  const { data } = await jhsfBookingsApi.get<IDetailedFeatureGroup>(
    `/feature-groups/${featureGroupId}`,
  );

  return data;
};
