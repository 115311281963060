import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  ButtonGroup,
  IconButton,
  HStack,
  Switch,
  VStack,
  RadioGroup,
  Radio,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import {
  RiEyeLine,
  RiLoginCircleLine,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';
import axios from 'axios';
import { UserExperience } from '../../models/users';
import {
  IBookingListCheckin,
  IBookingListItem,
} from '../../services/Bookings/ListBookingsService';
import { ConfirmationModal } from '../ConfirmationModal';
import { LinkButton } from '../LinkButton';
import { Pagination } from '../Pagination';
import { TableFilters } from '../TableFilters';
import { createBookingCheckinsService } from '../../services/Bookings/CreateBookingCheckinsService';
import { translateError } from '../../utils/errors';
import { maskDateTime } from '../../utils/formatters/handleMask';
import { useAuth } from '../../hooks/auth';
import { BookingPaymentStatus } from '../../models/bookings';
import { PaymentConfirmationModal } from '../PaymentConfirmationModal';

type SelectOption = {
  label: string;
  value: string;
};

interface IBookingTableItemCheckin extends IBookingListCheckin {
  formattedCreatedAt: string;
}

export interface IBookingTableItem extends IBookingListItem {
  checkins: IBookingTableItemCheckin[];
  formattedBookedDate: string;
  formattedCreatedAt: string;
  formattedDeletedAt?: string;
  formattedEndTime: string;
  formattedStartTime: string;
}

interface IBookingTableProps extends BoxProps {
  bookings: IBookingTableItem[];
  currentPage?: number;
  filterBy?: string;
  filterByOptions?: SelectOption[];
  onChangeFilterBy?: (value: string) => void;
  onCheckin?: (booking: IBookingTableItem) => void;
  onFilter?: (value: string) => void;
  onOrder?: (order: 'ASC' | 'DESC') => void;
  onPageChange?: (page: number) => void;
  onPay?: (bookingId: string) => void;
  onSort?: (sort: 'bookedDate' | 'createdAt') => void;
  onShowPending: () => void;
  onToggleBookingsTypeFilter?: (
    bookingsType: 'memberBookings' | 'guestsBookings',
  ) => void;
  toggleShowMyBookingsOnly?: () => void;
  order?: 'ASC' | 'DESC';
  showActivityColumn?: boolean;
  showMemberColumns?: boolean;
  showMyBookingsOnly?: boolean;
  showPending: boolean;
  sortBy?: string;
  sortByOptions?: SelectOption[];
  totalPages?: number;
}

export const BookingTable = ({
  bookings,
  currentPage,
  filterBy,
  filterByOptions = [
    {
      label: 'Nome da atividade',
      value: 'activityName',
    },
    {
      label: 'Nome do local',
      value: 'spotName',
    },
    {
      label: 'Nome do membro',
      value: 'userName',
    },
    {
      label: 'Documento do membro',
      value: 'userDocument',
    },
    {
      label: 'Data da reserva',
      value: 'bookedDate',
    },
  ],
  onChangeFilterBy,
  onCheckin,
  onFilter,
  onOrder,
  onPageChange,
  onPay,
  onSort,
  onShowPending,
  onToggleBookingsTypeFilter,
  order,
  showActivityColumn = true,
  showMemberColumns = true,
  showPending,
  showMyBookingsOnly,
  toggleShowMyBookingsOnly,
  sortBy,
  sortByOptions = [
    {
      label: 'Data da reserva',
      value: 'bookedDate',
    },
    {
      label: 'Data de cadastro',
      value: 'createdAt',
    },
  ],
  totalPages,
  ...rest
}: IBookingTableProps): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const [handlingBooking, setHandlingBooking] = useState<IBookingTableItem>();
  const [bookingsTypeFilter, setBookingsTypeFilter] = useState<
    'memberBookings' | 'guestsBookings'
  >('memberBookings');
  const [
    isCheckinConfirmationModalVisible,
    setIsCheckinConfirmationModalVisible,
  ] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);

  const handleTooggleCheckinConfirmationModal = useCallback(() => {
    setIsCheckinConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleTogglePaymentModal = useCallback(() => {
    setIsPaymentModalVisible((prevState) => !prevState);
  }, []);

  const handlePayment = useCallback(
    (booking: IBookingTableItem) => {
      setHandlingBooking(booking);

      handleTogglePaymentModal();
    },
    [handleTogglePaymentModal],
  );

  const handleCreateCheckin = async (): Promise<void> => {
    if (handlingBooking && !!onCheckin) {
      try {
        const checkin = await createBookingCheckinsService(handlingBooking.id);

        handleTooggleCheckinConfirmationModal();

        onCheckin({
          ...handlingBooking,
          checkins: [
            {
              ...checkin,
              formattedCreatedAt: maskDateTime(checkin.createdAt),
            },
            ...handlingBooking.checkins,
          ],
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao cadastrar',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar o checkin, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  };

  const handleToggleBookingsTypeFilter = useCallback(
    (value: 'memberBookings' | 'guestsBookings') => {
      setBookingsTypeFilter(value);

      if (onToggleBookingsTypeFilter) {
        onToggleBookingsTypeFilter(value);
      }
    },
    [onToggleBookingsTypeFilter],
  );

  return (
    <Box {...rest}>
      {handlingBooking && (
        // <CheckinListModal
        //   bookingId={handlingBooking.id}
        //   checkinEnabled={
        //     !handlingBooking.isExpired && !handlingBooking.deletedAt
        //   }
        //   isOpen={isCheckinListModalVisible}
        //   onClose={() => {
        //     setHandlingBooking(undefined);
        //     handleToggleCheckinListModal();
        //   }}
        // />

        <ConfirmationModal
          isOpen={isCheckinConfirmationModalVisible}
          onClose={handleTooggleCheckinConfirmationModal}
          onConfirm={handleCreateCheckin}
          title="Confirmar checkin"
          message="Deseja realmente criar um checkin para essa reserva?"
        />
      )}

      {!!handlingBooking && !!onPay && (
        <PaymentConfirmationModal
          isOpen={isPaymentModalVisible}
          onClose={() => {
            setHandlingBooking(undefined);
            handleTogglePaymentModal();
          }}
          onConfirm={() => {
            onPay(handlingBooking.id);
            setHandlingBooking(undefined);
            handleTogglePaymentModal();
          }}
        />
      )}

      <HStack align="flex-end" spacing="4">
        <TableFilters
          filterBy={filterBy}
          filterByOptions={filterByOptions}
          filterInputType={filterBy === 'bookedDate' ? 'datepicker' : 'input'}
          onChangeFilterBy={onChangeFilterBy}
          onFilter={onFilter}
          onOrder={onOrder}
          onSort={(sort) =>
            !!onSort && onSort(sort as 'bookedDate' | 'createdAt')
          }
          order={order}
          sortBy={sortBy}
          sortByOptions={sortByOptions}
        />

        <HStack align="flex-end" justifyContent="space-between" flex={1}>
          <ButtonGroup spacing="6">
            <HStack>
              <Switch
                name="isActive"
                id="isActive"
                isChecked={showPending}
                onChange={onShowPending}
              />
              <Text htmlFor="isActive">Reservas pendentes</Text>
            </HStack>

            {toggleShowMyBookingsOnly && (
              <HStack>
                <Switch
                  name="showOnlyMyBookings"
                  id="showOnlyMyBookings"
                  isChecked={showMyBookingsOnly}
                  onChange={toggleShowMyBookingsOnly}
                />
                <Text htmlFor="showOnlyMyBookings">Minhas reservas</Text>
              </HStack>
            )}
          </ButtonGroup>

          {!!onToggleBookingsTypeFilter && (
            <VStack>
              <Text>Reservas</Text>

              <RadioGroup
                onChange={handleToggleBookingsTypeFilter}
                value={bookingsTypeFilter}
              >
                <HStack>
                  <Radio defaultChecked value="memberBookings">
                    Membro
                  </Radio>
                  <Radio value="guestsBookings">Convidados</Radio>
                </HStack>
              </RadioGroup>
            </VStack>
          )}
        </HStack>
      </HStack>

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            {!!showActivityColumn && <Th>Atividade</Th>}

            {!!showMemberColumns && (
              <>
                <Th>Membro</Th>

                <Th>Nível de experiência</Th>
              </>
            )}

            <Th>Local</Th>

            <Th textAlign="center">Data/horário da reserva</Th>

            <Th textAlign="center">Reservado em</Th>

            {!showPending && <Th textAlign="center">Cancelado em</Th>}

            <Th>Checkins</Th>

            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {bookings.map((booking) => (
            <Tr key={booking.id}>
              {!!showActivityColumn && (
                <Td fontWeight="bold">
                  {booking.activitySchedule.activity.name}
                </Td>
              )}

              {!!showMemberColumns && (
                <>
                  <Td>{booking.user.name}</Td>

                  <Td>{UserExperience[booking.user.experience]}</Td>
                </>
              )}

              <Td>{booking.activitySchedule.activity.spot.name}</Td>

              <Td textAlign="center">
                <Box>
                  <Text>{booking.formattedBookedDate}</Text>
                  <Text>{`${booking.formattedStartTime} - ${booking.formattedEndTime}`}</Text>
                </Box>
              </Td>

              <Td textAlign="center">
                <Text>{booking.formattedCreatedAt.split('-')[0]}</Text>
                <Text>{booking.formattedCreatedAt.split('-')[1]}</Text>
              </Td>

              {!showPending && (
                <Td textAlign="center">
                  <Text>
                    {booking.formattedDeletedAt?.split('-')[0] || '-'}
                  </Text>
                  <Text>{booking.formattedDeletedAt?.split('-')[1]}</Text>
                </Td>
              )}

              <Td>
                {booking.checkins.map((checkin) => (
                  <Text fontSize="sm">{`${checkin.user.name} - ${checkin.formattedCreatedAt}`}</Text>
                ))}
              </Td>

              <Td textAlign="right">
                <ButtonGroup>
                  {booking.payment &&
                    [
                      BookingPaymentStatus.WAITING,
                      BookingPaymentStatus.FAILED,
                      BookingPaymentStatus.REJECTED,
                    ].includes(booking.payment.status) &&
                    !booking.isExpired &&
                    !!onPay &&
                    authenticatedUser.featureGroup.features.some((feature) =>
                      [
                        'BOOKING_PAYMENT_FULL_ACCESS',
                        'BOOKING_PAYMENT_WRITE_ACCESS',
                      ].includes(feature.key),
                    ) && (
                      <IconButton
                        aria-label="Payment"
                        size="sm"
                        icon={
                          <Icon as={RiMoneyDollarCircleLine} fontSize="18" />
                        }
                        fontSize="16"
                        colorScheme="red"
                        onClick={() => handlePayment(booking)}
                      />
                    )}

                  {(!booking.payment ||
                    [
                      BookingPaymentStatus.APPROVED,
                      BookingPaymentStatus.PAYMENT_EXTERNAL,
                    ].includes(booking.payment.status)) &&
                    !booking.isExpired &&
                    !booking.deletedAt &&
                    !!onCheckin &&
                    authenticatedUser.featureGroup.features.some((feature) =>
                      [
                        'BOOKING_CHECKIN_FULL_ACCESS',
                        'BOOKING_CHECKIN_READ_ACCESS',
                        'BOOKING_CHECKIN_WRITE_ACCESS',
                      ].includes(feature.key),
                    ) && (
                      <IconButton
                        aria-label="Checkins"
                        size="sm"
                        icon={<Icon as={RiLoginCircleLine} fontSize="16" />}
                        fontSize="16"
                        colorScheme="green"
                        onClick={() => {
                          setHandlingBooking(booking);
                          handleTooggleCheckinConfirmationModal();
                        }}
                      />
                    )}

                  <LinkButton
                    px={0}
                    to={{
                      pathname: '/bookings/details',
                      state: {
                        bookingId: booking.id,
                      },
                    }}
                  >
                    <Icon as={RiEyeLine} fontSize="16" />
                  </LinkButton>
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
    </Box>
  );
};
