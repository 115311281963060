import { Stack } from '@chakra-ui/react';
import {
  RiContactsLine,
  RiDashboardLine,
  RiInputMethodLine,
  RiShieldUserLine,
  RiEqualizerLine,
  RiAncientGateLine,
  RiFlagLine,
  RiGitRepositoryPrivateLine,
  RiBookmarkLine,
  // RiListCheck2,
} from 'react-icons/ri';
import { useAuth } from '../../hooks/auth';
import { NavLink } from './NavLink';
import { NavSection } from './NavSection';

export const SidebarNav = (): JSX.Element => {
  const { user } = useAuth();

  return (
    <Stack spacing="12" align="flex-start" minWidth="170px">
      <NavSection title="Geral">
        <NavLink icon={RiDashboardLine} to="/dashboard">
          Dashboard
        </NavLink>

        {user.featureGroup.features.some((feature) =>
          ['ACTIVITY_FULL_ACCESS', 'ACTIVITY_READ_ACCESS'].includes(
            feature.key,
          ),
        ) && (
          <NavLink icon={RiInputMethodLine} to="/activities">
            Atividades
          </NavLink>
        )}

        {user.featureGroup.features.some((feature) =>
          [
            'BOOKING_FULL_ACCESS',
            'BOOKING_READ_ACCESS',
            'BOOKING_SELLER_READ_ACCESS',
          ].includes(feature.key),
        ) && (
          <NavLink icon={RiBookmarkLine} to="/bookings">
            Reservas
          </NavLink>
        )}

        {/* {user.featureGroup.features.some((feature) =>
          ['BOOKING_QUEUE_FULL_ACCESS', 'BOOKING_QUEUE_READ_ACCESS'].includes(
            feature.key,
          ),
        ) && (
          <NavLink icon={RiListCheck2} to="/booking-queues">
            Filas de espera
          </NavLink>
        )} */}
      </NavSection>

      {user.featureGroup.features.some((feature) =>
        [
          'USER_FULL_ACCESS',
          'USER_READ_ACCESS',
          'SPOT_FULL_ACCESS',
          'SPOT_READ_ACCESS',
          'VENTURE_FULL_ACCESS',
          'VENTURE_READ_ACCESS',
        ].includes(feature.key),
      ) && (
        <>
          <NavSection title="Administrativo">
            {user.featureGroup.features.some((feature) =>
              ['USER_FULL_ACCESS', 'USER_READ_ACCESS'].includes(feature.key),
            ) && (
              <>
                <NavLink icon={RiContactsLine} to="/users">
                  Funcionários
                </NavLink>

                <NavLink icon={RiShieldUserLine} to="/members">
                  Membros
                </NavLink>
              </>
            )}

            {user.featureGroup.features.some((feature) =>
              ['SPOT_FULL_ACCESS', 'SPOT_READ_ACCESS'].includes(feature.key),
            ) && (
              <NavLink icon={RiFlagLine} to="/spots">
                Locais
              </NavLink>
            )}

            {user.featureGroup.features.some((feature) =>
              ['VENTURE_FULL_ACCESS', 'VENTURE_READ_ACCESS'].includes(
                feature.key,
              ),
            ) && (
              <NavLink icon={RiAncientGateLine} to="/ventures">
                Empreendimentos
              </NavLink>
            )}
          </NavSection>
        </>
      )}

      {user.featureGroup.features.some((feature) =>
        [
          'FEATURE_GROUP_FULL_ACCESS',
          'FEATURE_GROUP_READ_ACCESS',
          'FEATURE_FULL_ACCESS',
          'FEATURE_READ_ACCESS',
        ].includes(feature.key),
      ) && (
        <NavSection title="Configurações">
          {user.featureGroup.features.some((feature) =>
            ['FEATURE_GROUP_FULL_ACCESS', 'FEATURE_GROUP_READ_ACCESS'].includes(
              feature.key,
            ),
          ) && (
            <NavLink icon={RiEqualizerLine} to="/feature-groups">
              Grupos de permissões
            </NavLink>
          )}

          {user.featureGroup.features.some((feature) =>
            ['FEATURE_FULL_ACCESS', 'FEATURE_READ_ACCESS'].includes(
              feature.key,
            ),
          ) && (
            <NavLink icon={RiGitRepositoryPrivateLine} to="/features">
              Permissões
            </NavLink>
          )}
        </NavSection>
      )}
    </Stack>
  );
};
