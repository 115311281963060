import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  SimpleGrid,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Icon,
} from '@chakra-ui/react';

import axios from 'axios';
import {
  RiBookmark3Line,
  RiDeleteBinLine,
  RiEditLine,
  RiSaveLine,
} from 'react-icons/ri';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { translateError } from '../../../../../utils/errors';
import { createFeatureGroupsService } from '../../../../../services/FeatureGroups/CreateFeatureGroupsService';
import {
  IDetailedFeatureGroup,
  showFeatureGroupsService,
} from '../../../../../services/FeatureGroups/ShowFeatureGroupsService';
import { FeaturesAssignModal } from './components/FeaturesAssignModal';
import { IFeatureBase } from '../../../../../models/features';
import { updateFeatureGroupsService } from '../../../../../services/FeatureGroups/UpdateFeatureGroupsService';
import { GroupFeatureTable } from './components/GroupFeatureTable';
import deleteFeatureGroupsService from '../../../../../services/FeatureGroups/DeleteFeatureGroupsService';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';

interface ILocationState {
  featureGroupId?: string;
}

type HandleFeatureGroupFormData = {
  authLevel: number;
  key: string;
  name: string;
};

const registerFeatureGroupFormSchema = Yup.object().shape({
  authLevel: Yup.number()
    .integer()
    .min(0)
    .nullable()
    .required('Requerido')
    .transform((value) => (typeof value === 'number' ? value : null)),
  key: Yup.string().uppercase().required('Chave requerida'),
  name: Yup.string().required('Nome requerido'),
});

export const HandleFeatureGroup = (): JSX.Element => {
  const { push } = useHistory();
  const toast = useToast();

  const { state } = useLocation<ILocationState | undefined>();

  const featureGroupId = state?.featureGroupId;

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(registerFeatureGroupFormSchema),
  });

  const { errors } = formState;

  const [featureGroup, setFeatureGroup] = useState<IDetailedFeatureGroup>();
  const [selectedFeatures, setSelectedFeatures] = useState(
    featureGroup?.features || [],
  );
  const [isEditing, setIsEditing] = useState(!featureGroupId);
  const [isFeaturesAssignModalVisible, setIsFeaturesAssignModalVisible] =
    useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  useEffect(() => {
    async function loadFeatureGroups(fGroupId: string): Promise<void> {
      try {
        const fGroup = await showFeatureGroupsService(fGroupId);

        setFeatureGroup(fGroup);
        setSelectedFeatures(fGroup.features || []);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados do grupo de permissões, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (featureGroupId) {
      loadFeatureGroups(featureGroupId);
    }
  }, [featureGroupId, toast]);

  useEffect(() => {
    if (featureGroup) {
      reset({
        authLevel: featureGroup.authLevel,
        key: featureGroup.key,
        name: featureGroup.name,
      });
    }
  }, [featureGroup, reset]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleFeatureGroup: SubmitHandler<HandleFeatureGroupFormData> =
    useCallback(
      async ({ authLevel, key, name }) => {
        if (!featureGroupId) {
          try {
            await createFeatureGroupsService({
              authLevel,
              key,
              features: selectedFeatures,
              name,
            });

            toast({
              title: 'Cadastrado com sucesso',
              description: 'O grupo de permissões foi cadastrado corretamente.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });

            push('/feature-groups');
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha no cadastro',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao cadastrar o grupo de permissões, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        } else {
          try {
            await updateFeatureGroupsService({
              featureGroupId,
              authLevel,
              key,
              features: selectedFeatures,
              name,
            });

            toast({
              title: 'Atualizado com sucesso',
              description: 'O grupo de permissões foi atualizado corretamente.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });

            push('/feature-groups');
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha na atualização',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao atualizar o grupo de permissões, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        }
      },
      [featureGroupId, push, selectedFeatures, toast],
    );

  const handleToggleIsEditing = useCallback(() => {
    setIsEditing((prevState) => !prevState);
  }, []);

  const handleToggleFeaturesAssignModal = useCallback(() => {
    setIsFeaturesAssignModalVisible((prevState) => !prevState);
  }, []);

  const handleFeatures = useCallback(
    (features: IFeatureBase[]) => {
      setSelectedFeatures(features);

      handleToggleFeaturesAssignModal();
    },
    [handleToggleFeaturesAssignModal],
  );

  const handleDeleteFeatureGroup = useCallback(async () => {
    if (featureGroupId) {
      try {
        await deleteFeatureGroupsService(featureGroupId);

        toast({
          title: 'Excluído com sucesso',
          description: 'O grupo de permissões foi excluído corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/feature-groups');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao excluir',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao excluir o grupo de permissões, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [featureGroupId, push, toast]);

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteFeatureGroup}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      {featureGroup && (
        <FeaturesAssignModal
          groupFeatures={featureGroup.features}
          isOpen={isFeaturesAssignModalVisible}
          onClose={handleToggleFeaturesAssignModal}
          onSubmit={handleFeatures}
        />
      )}

      {!featureGroupId && (
        <FeaturesAssignModal
          isOpen={isFeaturesAssignModalVisible}
          onClose={handleToggleFeaturesAssignModal}
          onSubmit={handleFeatures}
        />
      )}

      <Box
        as="form"
        borderRadius={8}
        flex="1"
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleFeatureGroup)}
      >
        <Flex mb="8" justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Grupo de permissões
          </Heading>

          <ButtonGroup>
            {!isEditing && featureGroupId && (
              <Button
                size="sm"
                fontSize="sm"
                color="white"
                colorScheme="yellow"
                onClick={handleToggleIsEditing}
                leftIcon={<Icon as={RiEditLine} fontSize="20" />}
              >
                Editar
              </Button>
            )}

            {(isEditing || !featureGroupId) && (
              <Button
                size="sm"
                type="submit"
                colorScheme="green"
                leftIcon={<Icon as={RiSaveLine} fontSize="20" />}
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            )}

            {featureGroup && (
              <Button
                size="sm"
                fontSize="sm"
                colorScheme="red"
                onClick={handleToggleDeleteConfirmationModal}
                leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
              >
                Excluir
              </Button>
            )}
          </ButtonGroup>
        </Flex>

        <Divider my="6" borderColor="gray.300" />

        <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
          <MaskedInput
            isDisabled={!isEditing}
            label="Nome"
            error={errors.name}
            {...register('name')}
          />

          <MaskedInput
            isDisabled={!isEditing}
            label="Chave"
            mask="ref"
            textTransform="uppercase"
            error={errors.key}
            {...register('key')}
          />

          <MaskedInput
            isDisabled={!isEditing}
            label="Nível de permissão"
            error={errors.authLevel}
            {...register('authLevel')}
          />
        </SimpleGrid>

        <Box mt="8">
          <Flex mb="8" justify="space-between" align="center">
            <Heading size="md" fontWeight="normal">
              Permissões
            </Heading>

            <Button
              disabled={!isEditing}
              size="sm"
              fontSize="sm"
              colorScheme="green"
              onClick={handleToggleFeaturesAssignModal}
              leftIcon={<Icon as={RiBookmark3Line} fontSize="20" />}
            >
              Gerenciar permissões
            </Button>
          </Flex>

          <GroupFeatureTable mt="4" features={selectedFeatures} />
        </Box>
      </Box>
    </DefaultLayout>
  );
};
