import { useLocation } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { showUsersService } from '../../../../../services/Users/ShowUsersService';
import { translateError } from '../../../../../utils/errors';
import { useUser } from '../../../../../hooks/user';
import { MemberDetailsInfo } from './components/MemberDetailsInfo';
import MemberDetailsData from './components/MemberDetailsData';

interface ILocationState {
  memberId: string;
}

export const MemberDetails = (): JSX.Element => {
  const toast = useToast();
  const { handleUser: handleMember, user: member } = useUser();

  const {
    state: { memberId },
  } = useLocation<ILocationState>();

  useEffect(() => {
    async function loadMember(id: string): Promise<void> {
      try {
        const memberData = await showUsersService(id);

        handleMember(memberData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes do membro, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadMember(memberId);

    return () => handleMember(undefined);
  }, [memberId, handleMember, toast]);

  return (
    <DefaultLayout>
      {member?.id === memberId && (
        <Flex direction="column" w="100%">
          <MemberDetailsInfo minW="510px" />

          <MemberDetailsData flex="1" mt="8" minW="510px" />
        </Flex>
      )}
    </DefaultLayout>
  );
};
