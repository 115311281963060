/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BoxProps, Select } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '../Form/DatePicker';
import { listSelectActivitiesService } from '../../services/Activities/ListActivitiesService';
import { ISelectListItem } from '../../models/shared/select-list-item';
import { listActivitiesSchedulesService } from '../../services/Activities/ListActivitySchedulesService';
import { UserExperience } from '../../models/users';

interface IBookingQueueFilterProps extends BoxProps {
  onSelectFilterValues: (
    bookedDate: Date | undefined,
    activityId: string | undefined,
    activityScheduleId: string | undefined,
  ) => void;
}

const BookingQueueFilter = ({
  onSelectFilterValues,
}: IBookingQueueFilterProps): JSX.Element => {
  type FormValues = {
    bookedDate: Date | undefined;
  };

  const { control, register, watch } = useForm<FormValues>({
    defaultValues: {
      bookedDate: new Date(),
    },
  });

  const bookedDate = watch('bookedDate');
  const [isScheduleSelectDisabled, setScheduleSelectDisabled] = useState(true);
  const [activityScheduleOptions, setActivityScheduleOptions] = useState<
    ISelectListItem[]
  >([]);
  const [selectedActivitySchedule, setSelectedActivitySchedule] = useState<
    string | undefined
  >('');
  const [activityOptions, setActivityOptions] = useState<ISelectListItem[]>([]);
  const [selectedActivity, setSelectedActivityState] = useState<string>('');

  useEffect(() => {
    onSelectFilterValues(
      bookedDate,
      selectedActivity,
      selectedActivitySchedule,
    );
  }, [bookedDate, selectedActivity, selectedActivitySchedule]);

  const onSelectActivitySchedule = async (newValue: string): Promise<void> => {
    setSelectedActivitySchedule(newValue);
  };

  const fetchActivities = async (): Promise<ISelectListItem[]> =>
    listSelectActivitiesService();

  const populateActivityScheduleSelect = async (
    activityId: string,
  ): Promise<void> => {
    const activitySchedules = await listActivitiesSchedulesService({
      activityId,
      showExpired: false,
    });

    const activityScheduleOptionsMap = activitySchedules.items.map(
      (activitySchedule) => ({
        id: activitySchedule.id,
        name: `${activitySchedule.startTime.slice(0, 5)} às 
          ${activitySchedule.endTime.slice(0, 5)} - 
          ${UserExperience[activitySchedule.minUserExperience]}`,
      }),
    );

    setActivityScheduleOptions(activityScheduleOptionsMap);
  };

  const onSelectActivity = async (newValue: string): Promise<void> => {
    setSelectedActivityState(newValue);
    setSelectedActivitySchedule(undefined);
    await populateActivityScheduleSelect(newValue);
  };

  useEffect(() => {
    setScheduleSelectDisabled(!selectedActivity);
  }, [selectedActivity]);

  useEffect(() => {
    const loadActivities = async (): Promise<void> => {
      const activities = await fetchActivities();
      setActivityOptions(activities);
    };

    loadActivities();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        marginBottom: '20px',
        width: '100%',
      }}
    >
      <div style={{ width: '25%', flexShrink: 0 }}>
        <label
          htmlFor="date-select"
          style={{ display: 'block', marginBottom: '0.5rem' }}
        >
          Data
          <DatePicker
            id="date-select"
            hideTodayButton
            label={undefined}
            error={undefined}
            control={control}
            isDisabled={false}
            minDate={new Date()}
            placeholder="Selecione uma data"
            style={{ fontSize: '15px', height: '34px' }}
            {...register('bookedDate')}
          />
        </label>
      </div>
      <div style={{ width: '25%', flexShrink: 0 }}>
        <label
          htmlFor="activity-select"
          style={{ display: 'block', marginBottom: '0.5rem' }}
        >
          Atividade
          <Select
            id="activity-select"
            placeholder="Selecione uma atividade"
            value={selectedActivity}
            style={{ width: '100%', height: '48px' }}
            onChange={(e) => onSelectActivity(e.target.value)}
          >
            {activityOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Select>
        </label>
      </div>
      <div style={{ width: '25%', flexShrink: 0 }}>
        <label
          htmlFor="schedule-select"
          style={{ display: 'block', marginBottom: '0.5rem' }}
        >
          Evento
          <Select
            id="schedule-select"
            isDisabled={isScheduleSelectDisabled}
            placeholder="Selecione um horário"
            value={selectedActivitySchedule}
            onChange={(e) => onSelectActivitySchedule(e.target.value)}
            style={{ width: '100%', height: '48px' }}
          >
            {activityScheduleOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Select>
        </label>
      </div>
    </div>
  );
};

export default BookingQueueFilter;
