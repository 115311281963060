import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { EventProps } from 'react-big-calendar';
import { ICalendarEvent } from '../..';

export const EventComponent: React.FC<EventProps<ICalendarEvent>> = ({
  title,
  event,
}: EventProps<ICalendarEvent>) => {
  const period = `${event.schedules[0].startTime.slice(
    0,
    5,
  )} - ${event.schedules[0].endTime.slice(0, 5)}`;

  return (
    <Box display="flex" width="100%" height="100%">
      <Box
        bg={event.isFull ? 'red.400' : 'green.500'}
        opacity={event.isActive ? 1 : 0.5}
        display="flex"
        height="100%"
        borderRadius="4"
        p="1"
        px="2"
        flex="1"
        alignItems="center"
      >
        <Text
          color="white"
          fontSize="xs"
          mr="1"
          opacity="0.8"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {period}
        </Text>
        <Text
          fontSize="xs"
          color="white"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          fontWeight="bold"
        >
          {title}
        </Text>
      </Box>
    </Box>
  );
};
