import {
  Box,
  BoxProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { BookingItemTable } from '../../../../../../../../../components/BookingItemTable';
import { BookingScheduleItemTable } from '../../../../../../../../../components/BookingScheduleItemTable';
import { useBooking } from '../../../../../../../../../hooks/booking';

export const BookingDetailsTabs = (props: BoxProps): JSX.Element => {
  const { booking } = useBooking();

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChanges = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  return booking ? (
    <Box {...props}>
      <Tabs
        index={tabIndex}
        onChange={handleTabChanges}
        variant="enclosed-colored"
        isLazy
      >
        <TabList>
          <Tab>Opcionais da atividade</Tab>

          <Tab>Opcionais do evento</Tab>
        </TabList>

        <TabPanels mt="4">
          <TabPanel>
            <BookingItemTable bookingItems={booking.bookingItems} />
          </TabPanel>

          <TabPanel>
            <BookingScheduleItemTable
              scheduleItems={booking.bookingScheduleItems}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  ) : (
    <></>
  ); // loading view
};
