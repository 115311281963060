import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { createSpotsService } from '../../../../../services/Spots/CreateSpotsService';
import { Switch } from '../../../../../components/Form/Switch';
import { translateError } from '../../../../../utils/errors';
import { updateSpotAvatarsService } from '../../../../../services/Spots/UpdateSpotAvatarsService';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { useAuth } from '../../../../../hooks/auth';

interface ILocationState {
  ventureId?: string;
}

type NewSpotFormData = {
  description?: string;
  isActive: boolean;
  name: string;
  ventureId: string;
};

const registerSpotFormSchema = Yup.object().shape({
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  isActive: Yup.boolean().required('Requerido'),
  name: Yup.string().required('Requerido'),
  ventureId: Yup.string().uuid().required('Requerido'),
});

export const SpotRegister = (): JSX.Element => {
  const { user } = useAuth();
  const { goBack, push } = useHistory();
  const toast = useToast();

  const { state } = useLocation<ILocationState | undefined>();

  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [venturesSelectOptions, setVenturesSelectOptions] = useState<
    SelectOption[]
  >([]);

  const { register, handleSubmit, formState, control, setValue } = useForm({
    resolver: yupResolver(registerSpotFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items: ventures } = await listVenturesService();

      setVenturesSelectOptions(
        ventures.map((venture) => ({
          label: venture.name,
          value: venture.id,
        })),
      );
    }

    if (!user.ventureId) {
      loadVentures();
    } else {
      setValue('ventureId', user.ventureId);
    }
  }, [setValue, user.ventureId]);

  useEffect(() => {
    if (!!state?.ventureId && !!venturesSelectOptions.length) {
      setValue('ventureId', state.ventureId);
    }
  }, [setValue, state?.ventureId, venturesSelectOptions.length]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleDeleteAvatar = useCallback(() => {
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewSpot: SubmitHandler<NewSpotFormData> = useCallback(
    async ({ description, isActive, name, ventureId }) => {
      try {
        const spot = await createSpotsService({
          description,
          isActive,
          name,
          ventureId,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          await updateSpotAvatarsService({
            avatarData: formData,
            spotId: spot.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O local foi cadastrado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/spots/details', {
          spotId: spot.id,
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar o local, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast],
  );

  return (
    <DefaultLayout>
      <Box
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleNewSpot)}
      >
        <Heading size="lg" fontWeight="normal">
          Cadastrar local
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleDeleteAvatar}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput
            label="Título"
            error={errors.name}
            {...register('name')}
          />

          {!user.ventureId && (
            <ReactSelect
              label="Empreendimento"
              name="ventureId"
              options={venturesSelectOptions}
              control={control}
              error={errors.ventureId}
            />
          )}

          <MaskedInput
            label="Descrição"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.description}
            {...register('description')}
          />

          <Box mx="auto">
            <Switch
              label="Ativo"
              error={errors.isActive}
              defaultChecked
              {...register('isActive')}
            />
          </Box>
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
