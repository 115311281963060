import axios from 'axios';
import { IAddressBase } from '../../models/address';

export interface ICepAddressResponse {
  bairro: string;
  cep: string;
  complemento: string;
  erro?: boolean;
  localidade: string;
  logradouro: string;
  uf: string;
}

export const searchCepService = async (
  value: string,
): Promise<Omit<IAddressBase, 'number'>> => {
  const { data: cepResponse } = await axios.get<ICepAddressResponse>(
    `https://viacep.com.br/ws/${value}/json`,
  );

  if (cepResponse.erro) {
    throw Error();
  }

  const address: Omit<IAddressBase, 'number'> = {
    city: cepResponse.localidade,
    country: 'BR',
    complement: cepResponse.complemento,
    neighborhood: cepResponse.bairro,
    state: cepResponse.uf,
    street: cepResponse.logradouro,
    zipCode: cepResponse.cep,
  };

  return address;
};
