import {
  FormLabel,
  FormControl,
  FormErrorMessage,
  Button,
} from '@chakra-ui/react';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';

import './style.css';
import { useState } from 'react';

type Error = {
  message: string;
};

interface IDatePickerProps extends ReactDatePickerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: Error;
  label?: string;
  name: string;
  selectedDates: Date[];
}

registerLocale('pt-BR', ptBr);

const DatePickerMultipleBase = ({
  error,
  label,
  name,
  selectedDates,
  ...rest
}: IDatePickerProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const handleIsOpen = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <FormControl isInvalid={!!error} display="flex" flexDirection="column">
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <ReactDatePicker
        name={name}
        dateFormat="dd/MM/yyyy"
        locale="pt-BR"
        disabledKeyboardNavigation
        highlightDates={selectedDates}
        onInputClick={handleIsOpen}
        onClickOutside={handleIsOpen}
        open={isOpen}
        selected={null}
        shouldCloseOnSelect={false}
        todayButton="Hoje"
        customInput={
          <Button
            _disabled={{
              background: 'gray.100',
              borderColor: 'gray.300',
              color: '#99999a',
            }}
            width="100%"
            size="lg"
            fontWeight="normal"
            colorScheme={!isOpen ? 'blue' : 'gray'}
          >
            {!isOpen ? 'Selecionar' : 'Fechar'}
          </Button>
        }
        {...rest}
      />

      {!!error && (
        <FormErrorMessage position="absolute" bottom={-7}>
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export const DatePickerMultiple = DatePickerMultipleBase;
