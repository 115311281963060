import { IBookingCheckinBase } from '../../models/bookings';
import { IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IListBookingCheckinProps {
  bookingId: string;
}

export interface ICheckinListItem extends IBookingCheckinBase {
  user: IUserBase;
}

export const listBookingCheckinsService = async ({
  bookingId,
}: IListBookingCheckinProps): Promise<ICheckinListItem[]> => {
  const { data } = await jhsfBookingsApi.get<ICheckinListItem[]>(
    `/bookings/${bookingId}/checkins`,
  );

  return data;
};
