import { useHistory } from 'react-router-dom';
import {
  Heading,
  Flex,
  Button,
  Icon,
  Link as ChakraLink,
  Text,
  Avatar,
  useToast,
  ButtonGroup,
  HStack,
  VStack,
  Divider,
  useBreakpointValue,
  FlexProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  RiBookmarkLine,
  RiDeleteBinLine,
  RiEditLine,
  RiLink,
  RiListCheck3,
  RiSettings3Line,
  RiWhatsappLine,
} from 'react-icons/ri';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { translateError } from '../../../../../../../utils/errors';
import { useUser } from '../../../../../../../hooks/user';
import {
  FriendlyForeignerUserDocumentType,
  UserExperience,
} from '../../../../../../../models/users';
import createProfileConfirmationsService from '../../../../../../../services/Users/CreateProfileConfirmationsService';
import deleteUsersService from '../../../../../../../services/Users/DeleteUsersService';
import { useAuth } from '../../../../../../../hooks/auth';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';
import HostInfo from '../../../../components/HostInfo';
import { UserBlockModal } from '../../../../components/UserBlockModal';
import AddressDetails from '../../../../../../../components/AddressDetails';
import resetUsersOnboardingService from '../../../../../../../services/Users/ResetUsersOnboardingService';

export const GuestDetailsInfo = (props: FlexProps): JSX.Element => {
  const toast = useToast();

  const { goBack } = useHistory();

  const { user: authenticatedUser } = useAuth();

  const { user: guest, handleUser: handleGuest } = useUser();

  const dividerOrientation = useBreakpointValue({
    base: 'horizontal',
    xl: 'vertical',
  });

  const [
    isSendProfileConfirmationModalVisible,
    setIsSendProfileConfirmationModalVisible,
  ] = useState(false);
  const [isResetOnboardingModalVisible, setIsResetOnboardingModalVisible] =
    useState(false);
  const [isUserBlockModalVisible, setIsUserBlockModalVisible] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const handleToggleUserBlockModal = useCallback(() => {
    setIsUserBlockModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleProfileConfirmationModal = useCallback(() => {
    setIsSendProfileConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleResetOnboardingModal = useCallback(() => {
    setIsResetOnboardingModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleProfileConfirmation = useCallback(async () => {
    try {
      if (guest) {
        await createProfileConfirmationsService(guest.id);
      }

      toast({
        title: 'Notificado com sucesso',
        description: 'O convidado foi notificado corretamente.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });

      handleToggleProfileConfirmationModal();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao notificar',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao enviar a notificação, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [guest, toast, handleToggleProfileConfirmationModal]);

  const handleCopyUrl = useCallback(
    async (linkUrl: string) => {
      try {
        await navigator.clipboard.writeText(linkUrl);

        toast({
          description: 'O link foi copiado corretamente.',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
          status: 'success',
          title: 'Copiado com sucesso',
          variant: 'subtle',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            description: 'Ocorreu um erro ao copiar o link, tente novamente.',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
            status: 'error',
            title: 'Falha ao copiar',
            variant: 'subtle',
          });
        }
      }
    },
    [toast],
  );

  const handleResetOnboarding = useCallback(async () => {
    if (!guest) {
      return;
    }

    try {
      const updatedGuest = await resetUsersOnboardingService(guest.id);

      handleGuest({
        ...guest,
        onboardingToken: updatedGuest.onboardingToken,
        onboardingPending: updatedGuest.onboardingPending,
        onboardingUrl: updatedGuest.onboardingUrl,
      });

      handleToggleResetOnboardingModal();

      toast({
        title: 'Reiniciado com sucesso',
        description: 'O onboarding do convidado foi reiniciado corretamente.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        variant: 'subtle',
        position: 'top-right',
      });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao reiniciar onboarding',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao reiniciar o onboarding do convidado, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    }
  }, [handleGuest, handleToggleResetOnboardingModal, guest, toast]);

  const handleDeleteGuest = useCallback(async () => {
    if (guest?.id) {
      try {
        await deleteUsersService(guest.id);

        toast({
          title: 'Excluído com sucesso',
          description: 'O convidado foi excluído corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao excluir',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao excluir o convidado, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [guest?.id, goBack, toast]);

  return (
    <>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteGuest}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      <ConfirmationModal
        isOpen={isSendProfileConfirmationModalVisible}
        onClose={handleToggleProfileConfirmationModal}
        onConfirm={handleProfileConfirmation}
        title="Enviar notificação"
        message="Deseja reenviar a notificação?"
      />

      <ConfirmationModal
        isOpen={isResetOnboardingModalVisible}
        onClose={handleToggleResetOnboardingModal}
        onConfirm={handleResetOnboarding}
        title="Reiniciar onboarding"
        message="Deseja reiniciar o onboarding desse membro?"
      />

      <UserBlockModal
        description="Ao bloquear ou suspender um convidado serão canceladas todas as reservas/filas pendentes para ele."
        isOpen={isUserBlockModalVisible}
        onClose={handleToggleUserBlockModal}
        onSave={handleToggleUserBlockModal}
      />

      <Flex
        direction="column"
        w="100%"
        borderRadius={8}
        bg="white"
        p="8"
        {...props}
      >
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do convidado
          </Heading>
        </Flex>

        {guest && (
          <Flex direction="column" mt="8">
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="4"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 12, 8]}>
                <Flex direction="column" px="8">
                  <Flex alignItems="flex-start">
                    <Avatar
                      size="2xl"
                      name={guest.name}
                      src={guest.avatarUrl}
                    />

                    <VStack flex="1" alignItems="flex-start" ml="8">
                      <Text fontSize="4xl" fontWeight="bold">
                        {guest.name}
                      </Text>

                      <HStack>
                        <Text>Nível de experiência:</Text>
                        <Text color="gray.500">
                          {UserExperience[guest.experience]}
                        </Text>
                      </HStack>

                      <HStack>
                        <Text>Telefone:</Text>
                        {guest.phone && (
                          <ChakraLink
                            href={`https://wa.me/${guest.phone?.replace(
                              /\D/g,
                              '',
                            )}`}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={RiWhatsappLine} mx="2" fontSize="24" />
                            <Text as="span" color="blue.500">
                              {guest.phone}
                            </Text>
                          </ChakraLink>
                        )}
                      </HStack>

                      <HStack>
                        <Text>E-mail:</Text>
                        <Text color="gray.500">{guest.email}</Text>
                      </HStack>

                      {guest.foreignerDocument &&
                      guest.foreignerDocumentType ? (
                        <HStack>
                          <Text>Documento internacional:</Text>
                          <Text color="gray.500">
                            {`${
                              FriendlyForeignerUserDocumentType[
                                guest.foreignerDocumentType
                              ]
                            } ${guest.foreignerDocument}`}
                          </Text>
                        </HStack>
                      ) : (
                        <HStack>
                          <Text>CPF:</Text>
                          <Text color="gray.500">{guest.cpf}</Text>
                        </HStack>
                      )}

                      <HStack>
                        <Text>Empreendimento:</Text>
                        <Text color="gray.500">{guest.venture?.name}</Text>
                      </HStack>

                      <HStack>
                        <Text>Data de nascimento:</Text>
                        <Text color="gray.500">{guest.formattedBirthDate}</Text>
                      </HStack>

                      <HStack>
                        <Text>Data de cadastro:</Text>
                        <Text color="gray.500">{guest.formattedCreatedAt}</Text>
                      </HStack>

                      {!!guest.onboardingUrl && (
                        <>
                          <HStack>
                            <Text>Link de onboarding:</Text>
                            <Text color="gray.500">{guest.onboardingUrl}</Text>
                          </HStack>

                          <HStack spacing="4">
                            <Button
                              size="sm"
                              leftIcon={<Icon as={RiLink} fontSize="20" />}
                              fontSize="sm"
                              colorScheme="blue"
                              onClick={() =>
                                handleCopyUrl(guest.onboardingUrl || '')
                              }
                            >
                              Copiar link
                            </Button>

                            {/* <ChakraLink
                              as={Link}
                              target="_blank"
                              referrerPolicy="no-referrer"
                              to={{ pathname: guest.onboardingUrl }}
                            >
                              <Text>{guest.onboardingUrl}</Text>
                            </ChakraLink> */}
                          </HStack>
                        </>
                      )}

                      {!!guest.formattedHealthCheckDate && (
                        <HStack>
                          <Text>Exame médico:</Text>
                          <Text color="gray.500">
                            {guest.formattedHealthCheckDate}
                          </Text>
                        </HStack>
                      )}

                      {(guest.blockedUntil || guest.isBlocked) && (
                        <HStack>
                          <Text>Suspenso até:</Text>

                          <Text color="red.500">
                            {guest.formattedBlockedUntil
                              ? `${guest.formattedBlockedUntil} às 00:00 horas`
                              : 'indeterminado'}
                          </Text>
                        </HStack>
                      )}

                      {guest.hostBlocked && (
                        <HStack>
                          <Text color="red.500">Responsável bloqueado</Text>
                        </HStack>
                      )}

                      {guest.titularBlocked && (
                        <HStack>
                          <Text color="red.500">Titular bloqueado</Text>
                        </HStack>
                      )}
                    </VStack>
                  </Flex>

                  <ButtonGroup mt="8">
                    {/* {guest?.confirmPending &&
                      authenticatedUser.featureGroup.features.some((feature) =>
                        [
                          'USER_FULL_ACCESS',
                          'USER_PROFILE_CONFIRMATION_WRITE_ACCESS',
                        ].includes(feature.key),
                      ) && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          colorScheme="blue"
                          onClick={handleToggleProfileConfirmationModal}
                          leftIcon={<Icon as={RiMailSendLine} fontSize="20" />}
                        >
                          Reenviar confirmação
                        </Button>
                      )} */}

                    {!guest?.onboardingPending ||
                      (!guest?.onboardingUrl &&
                        authenticatedUser.featureGroup.features.some(
                          (feature) =>
                            ['USER_FULL_ACCESS', 'USER_WRITE_ACCESS'].includes(
                              feature.key,
                            ),
                        ) && (
                          <Button
                            size="sm"
                            fontSize="sm"
                            colorScheme="blue"
                            onClick={handleToggleResetOnboardingModal}
                            leftIcon={<Icon as={RiListCheck3} fontSize="20" />}
                          >
                            Resetar onboarding
                          </Button>
                        ))}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['USER_FULL_ACCESS', 'USER_WRITE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="blue"
                        onClick={handleToggleUserBlockModal}
                        leftIcon={<Icon as={RiSettings3Line} fontSize="20" />}
                      >
                        Gerenciar bloqueios
                      </Button>
                    )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['USER_FULL_ACCESS', 'USER_WRITE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <LinkButton
                        colorScheme="yellow"
                        color="white"
                        icon={<Icon as={RiEditLine} fontSize="16" />}
                        to={{
                          pathname: '/guests/update',
                          state: {
                            guestId: guest.id,
                          },
                        }}
                      >
                        Editar
                      </LinkButton>
                    )}
                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['USER_FULL_ACCESS', 'USER_DELETE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="red"
                        onClick={handleToggleDeleteConfirmationModal}
                        leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                      >
                        Excluir
                      </Button>
                    )}
                  </ButtonGroup>
                </Flex>
              </GridItem>

              <GridItem colSpan={[12, 12, 12, 12, 4]}>
                <Flex height="100%">
                  <Divider
                    hidden={dividerOrientation === 'horizontal'}
                    my="auto"
                    px="2"
                    height="100%"
                    borderColor="gray.300"
                    orientation="vertical"
                  />

                  <Flex flexDirection="column" w="100%">
                    <Divider
                      hidden={dividerOrientation === 'vertical'}
                      borderColor="gray.300"
                      mt="4"
                      mb="8"
                    />

                    <Flex flexDirection="column" px={[8, 8, 8, 8, 0]} w="100%">
                      {guest.address && (
                        <AddressDetails
                          mt={[0, 0, 0, 0, 2]}
                          address={guest.address}
                        />
                      )}

                      {guest.bio && (
                        <>
                          <HStack mt="4">
                            <Icon as={RiBookmarkLine} fontSize="20" />
                            <Text fontSize="lg">Biografia</Text>
                          </HStack>

                          <Text
                            fontSize="sm"
                            color="gray.500"
                            mt="4"
                            px={[4, 4, 4, 4, 0]}
                          >
                            {guest.bio}
                          </Text>
                        </>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </GridItem>
            </Grid>

            {guest.host && (
              <>
                <Divider borderColor="gray.300" my="8" />

                <HostInfo host={guest.host} />
              </>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
};
