import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
} from '@chakra-ui/react';
import { RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { Pagination } from '../../../../../../../components/Pagination';
import { TableFilters } from '../../../../../../../components/TableFilters';
import { IFeatureGroupListItem } from '../../../../../../../services/FeatureGroups/ListFeatureGroupsService';

export type IFeatureGroup = IFeatureGroupListItem;

interface IFeatureGroupTableProps extends BoxProps {
  currentPage?: number;
  featureGroups: IFeatureGroup[];
  onPageChange?: (page: number) => void;
  onSearch: (search: string) => void;
  totalPages?: number;
}

export const FeatureGroupTable = ({
  currentPage,
  featureGroups,
  onPageChange,
  onSearch,
  totalPages,
  ...rest
}: IFeatureGroupTableProps): JSX.Element => (
  <Box {...rest}>
    <TableFilters searchLabel="Buscar" onSearch={onSearch} />

    <Table colorScheme="blue" mt="4">
      <Thead>
        <Tr>
          <Th>Nome do grupo</Th>
          <Th>Chave</Th>
          <Th textAlign="center">Nível de permissão</Th>
          <Th textAlign="center"># Permissões</Th>
          <Th textAlign="center"># Usuários</Th>
          <Th textAlign="right" w="8" />
        </Tr>
      </Thead>

      <Tbody>
        {featureGroups.map((featureGroup) => (
          <Tr key={featureGroup.id}>
            <Td>
              <Text fontWeight="bold">{featureGroup.name}</Text>
            </Td>

            <Td>{featureGroup.key}</Td>

            <Td textAlign="center">{featureGroup.authLevel}</Td>

            <Td textAlign="center">{featureGroup.featuresCount}</Td>

            <Td textAlign="center">{featureGroup.usersCount}</Td>

            <Td textAlign="right">
              <LinkButton
                px={0}
                to={{
                  pathname: '/feature-groups/management',
                  state: {
                    featureGroupId: featureGroup.id,
                  },
                }}
              >
                <Icon as={RiEyeLine} fontSize="16" />
              </LinkButton>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>

    {!!currentPage && !!onPageChange && !!totalPages && totalPages > 1 && (
      <Pagination
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    )}
  </Box>
);
