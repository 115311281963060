import { IActivityBase } from '../../models/activities';
import { ISelectListItem } from '../../models/shared/select-list-item';
import { ISpotBase } from '../../models/spots';
import { IVentureBase } from '../../models/ventures';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IListActivityProps {
  name?: string;
  order?: 'ASC' | 'DESC';
  sort?: 'name' | 'isActive' | 'createdAt';
  limit?: number;
  page?: number;
  spotId?: string;
  ventureId?: string;
}

interface IActivityListItemSpot extends ISpotBase {
  venture: IVentureBase;
}

export interface IActivityListItem extends IActivityBase {
  spot: IActivityListItemSpot;
}

export interface IActivityList {
  items: IActivityListItem[];
  pages: number;
  total: number;
}

export const listActivitiesService = async (
  filters?: IListActivityProps,
): Promise<IActivityList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          name: filters.name || undefined,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
          spotId: filters.spotId || undefined,
          ventureId: filters.ventureId || undefined,
        }
      : undefined;

  const { data } = await jhsfBookingsApi.get<IActivityList>('/activities', {
    params,
  });
  return data;
};

export const listSelectActivitiesService = async (): Promise<
  ISelectListItem[]
> => {
  const { data } = await jhsfBookingsApi.get<ISelectListItem[]>(
    '/activities/select',
  );
  return data;
};
