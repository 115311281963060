import { Flex, FlexProps } from '@chakra-ui/react';
import { GuestDetailsTabs } from './components/GuestDetailsTabs';

const GuestDetailsData = (props: FlexProps): JSX.Element => (
  <Flex
    direction="column"
    w="100%"
    borderRadius={8}
    bg="white"
    p="8"
    {...props}
  >
    <GuestDetailsTabs />
  </Flex>
);

export default GuestDetailsData;
