import { IActivityBase } from '../../models/activities';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface ICreateActivityProps {
  bookingPrice?: number;
  description?: string;
  isActive?: boolean;
  name: string;
  spotId: string;
}

export type INewActivity = IActivityBase;

export const createActivitiesService = async ({
  bookingPrice,
  description,
  isActive,
  name,
  spotId,
}: ICreateActivityProps): Promise<INewActivity> => {
  const { data } = await jhsfBookingsApi.post<INewActivity>('/activities', {
    bookingPrice,
    description,
    isActive,
    name,
    spotId,
  });

  return data;
};
