import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  IconButton,
  ButtonGroup,
  Button,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { RiAddLine, RiDeleteBinLine, RiEditLine } from 'react-icons/ri';
import { ConfirmationModal } from '../../../../../../../../../../../components/ConfirmationModal';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { useVenture } from '../../../../../../../../../../../hooks/venture';
import { IUserCategoryBase } from '../../../../../../../../../../../models/ventures';
import deleteUserCategoriesService from '../../../../../../../../../../../services/UserCategories/DeleteUserCategoriesService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { maskMoney } from '../../../../../../../../../../../utils/formatters/handleMask';
import { HandleUserCategoryModal } from './components/HandleUserCategoryModal';

export const UserCategoryTable = (props: BoxProps): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const { venture, handleVenture } = useVenture();

  const [handlingUserCategory, setHandlingUserCategory] =
    useState<IUserCategoryBase>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isHandleUserCategoryModalVisible,
    setIsHandleUserCategoryModalVisible,
  ] = useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleUserCategoryModal = useCallback(() => {
    setIsHandleUserCategoryModalVisible((prevState) => !prevState);
  }, []);

  const handleUserCategory = useCallback(
    (userCategory: IUserCategoryBase, action: 'delete' | 'update') => {
      setHandlingUserCategory(userCategory);

      switch (action) {
        case 'delete':
          handleToggleDeleteConfirmationModal();

          break;

        case 'update':
          handleToggleHandleUserCategoryModal();

          break;

        default:
          break;
      }
    },
    [handleToggleDeleteConfirmationModal, handleToggleHandleUserCategoryModal],
  );

  const handleDeleteUserCategory = useCallback(
    async (userCategoryId: string) => {
      if (venture) {
        try {
          await deleteUserCategoriesService(userCategoryId);

          setHandlingUserCategory(undefined);

          handleVenture({
            ...venture,
            userCategories: venture.userCategories.filter(
              (userCategory) => userCategory.id !== userCategoryId,
            ),
          });

          toast({
            title: 'Excluído com sucesso',
            description: 'A categoria foi excluída corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao excluir',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao excluir a categoria, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [handleVenture, toast, venture],
  );

  return (
    <Box {...props}>
      {handlingUserCategory && (
        <ConfirmationModal
          isOpen={isDeleteConfirmationModalVisible}
          onClose={() => {
            setHandlingUserCategory(undefined);
            handleToggleDeleteConfirmationModal();
          }}
          onConfirm={() => handleDeleteUserCategory(handlingUserCategory.id)}
          title="Confirmar exclusão"
          message="Deseja realmente excluir?"
        />
      )}

      <HandleUserCategoryModal
        category={handlingUserCategory}
        isOpen={isHandleUserCategoryModalVisible}
        onClose={() => {
          setHandlingUserCategory(undefined);
          handleToggleHandleUserCategoryModal();
        }}
        onSave={() => {
          setHandlingUserCategory(undefined);
          handleToggleHandleUserCategoryModal();
        }}
      />

      {authenticatedUser.featureGroup.features.some((feature) =>
        ['USER_CATEGORY_FULL_ACCESS', 'USER_CATEGORY_WRITE_ACCESS'].includes(
          feature.key,
        ),
      ) && (
        <ButtonGroup width="100%" justifyContent="flex-end">
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<Icon as={RiAddLine} fontSize="16" />}
            onClick={() => {
              setHandlingUserCategory(undefined);
              handleToggleHandleUserCategoryModal();
            }}
          >
            Nova categoria
          </Button>
        </ButtonGroup>
      )}

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Título</Th>
            <Th w="72" textAlign="center">
              Reservas por dia
            </Th>
            <Th w="72" textAlign="center">
              Reservas de convidados por dia
            </Th>
            <Th w="72" textAlign="center">
              Valor da reserva de convidados
            </Th>
            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {venture?.userCategories.map((userCategory) => (
            <Tr key={userCategory.id}>
              <Td>{userCategory.title}</Td>

              <Td textAlign="center">{userCategory.maxDayBookings || '-'}</Td>

              <Td textAlign="center">
                {userCategory.maxGuestDayBookings || '-'}
              </Td>

              <Td textAlign="center">
                {userCategory.guestBookingPrice
                  ? maskMoney(userCategory.guestBookingPrice)
                  : '-'}
              </Td>

              <Td textAlign="right">
                <ButtonGroup>
                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'USER_CATEGORY_FULL_ACCESS',
                      'USER_CATEGORY_WRITE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Edit"
                      size="sm"
                      icon={<Icon as={RiEditLine} />}
                      fontSize="16"
                      color="white"
                      colorScheme="yellow"
                      onClick={() => handleUserCategory(userCategory, 'update')}
                    />
                  )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'USER_CATEGORY_FULL_ACCESS',
                      'USER_CATEGORY_DELETE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<Icon as={RiDeleteBinLine} />}
                      fontSize="16"
                      colorScheme="red"
                      onClick={() => handleUserCategory(userCategory, 'delete')}
                    />
                  )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
