import { useHistory } from 'react-router-dom';
import {
  Heading,
  Flex,
  Button,
  Icon,
  Link as ChakraLink,
  Text,
  Avatar,
  useToast,
  ButtonGroup,
  HStack,
  VStack,
  Divider,
  useBreakpointValue,
  FlexProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  RiBookmarkLine,
  RiDeleteBinLine,
  RiEditLine,
  RiWhatsappLine,
} from 'react-icons/ri';
import { useCallback, useState } from 'react';
import axios from 'axios';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { translateError } from '../../../../../../../utils/errors';
import { useVenture } from '../../../../../../../hooks/venture';
import deleteVenturesService from '../../../../../../../services/Ventures/DeleteVenturesService';
import { useAuth } from '../../../../../../../hooks/auth';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';

export const VentureDetailsInfo = (props: FlexProps): JSX.Element => {
  const toast = useToast();

  const { push } = useHistory();

  const { user: authenticatedUser } = useAuth();

  const { venture } = useVenture();

  const dividerOrientation = useBreakpointValue({
    base: 'horizontal',
    xl: 'vertical',
  });

  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteVenture = useCallback(async () => {
    if (venture?.id) {
      try {
        await deleteVenturesService(venture.id);

        toast({
          title: 'Excluído com sucesso',
          description: 'O empreendimento foi excluído corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/ventures');
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao excluir',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao excluir o empreendimento, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [venture?.id, push, toast]);

  return (
    <>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteVenture}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      <Flex
        direction="column"
        w="100%"
        borderRadius={8}
        bg="white"
        p="8"
        {...props}
      >
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes do empreendimento
          </Heading>
        </Flex>

        {venture && (
          <Flex direction="column" mt="8">
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="4"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 12, 8]}>
                <Flex direction="column" px="8">
                  <Flex alignItems="flex-start">
                    <Avatar
                      size="2xl"
                      name={venture.name}
                      src={venture.avatarUrl}
                    />

                    <VStack flex="1" alignItems="flex-start" ml="8">
                      <Text fontSize="4xl" fontWeight="bold">
                        {venture.name}
                      </Text>

                      <HStack>
                        <Text>Ref:</Text>
                        <Text color="gray.500">{venture.ref}</Text>
                      </HStack>

                      <HStack>
                        <Text>Appstore Id:</Text>
                        <Text color="gray.500">{venture.appstoreId}</Text>
                      </HStack>

                      <HStack>
                        <Text>Playstore Id:</Text>
                        <Text color="gray.500">{venture.playstoreId}</Text>
                      </HStack>

                      <HStack>
                        <Text>Telefone:</Text>
                        {venture.phone && (
                          <ChakraLink
                            href={`https://wa.me/${venture.phone?.replace(
                              /\D/g,
                              '',
                            )}`}
                            display="flex"
                            alignItems="center"
                          >
                            <Icon as={RiWhatsappLine} mx="2" fontSize="24" />
                            <Text as="span" color="blue.500">
                              {venture.phone}
                            </Text>
                          </ChakraLink>
                        )}
                      </HStack>

                      <HStack>
                        <Text>E-mail:</Text>
                        <Text color="gray.500">{venture.email}</Text>
                      </HStack>

                      <HStack>
                        <Text>CNPJ:</Text>
                        <Text color="gray.500">{venture.cnpj}</Text>
                      </HStack>

                      <HStack>
                        <Text>Data de cadastro:</Text>
                        <Text color="gray.500">
                          {venture.formattedCreatedAt}
                        </Text>
                      </HStack>
                    </VStack>
                  </Flex>

                  <ButtonGroup mt="8">
                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['VENTURE_FULL_ACCESS', 'VENTURE_WRITE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <LinkButton
                        colorScheme="yellow"
                        color="white"
                        icon={<Icon as={RiEditLine} fontSize="16" />}
                        to={{
                          pathname: '/ventures/update',
                          state: {
                            ventureId: venture.id,
                          },
                        }}
                      >
                        Editar
                      </LinkButton>
                    )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['VENTURE_FULL_ACCESS', 'VENTURE_DELETE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="red"
                        onClick={handleToggleDeleteConfirmationModal}
                        leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                      >
                        Excluir
                      </Button>
                    )}
                  </ButtonGroup>
                </Flex>
              </GridItem>

              {venture.bio && (
                <GridItem colSpan={[12, 12, 12, 12, 4]}>
                  <Flex height="100%">
                    <Divider
                      hidden={dividerOrientation === 'horizontal'}
                      px="2"
                      height="80%"
                      borderColor="gray.300"
                      orientation="vertical"
                    />

                    <Flex flexDirection="column" w="100%">
                      <Divider
                        hidden={dividerOrientation === 'vertical'}
                        borderColor="gray.300"
                        mt="4"
                        mb="8"
                      />
                      <Flex
                        flexDirection="column"
                        px={[8, 8, 8, 8, 0]}
                        w="100%"
                      >
                        <HStack mt={[0, 0, 0, 0, 2]}>
                          <Icon as={RiBookmarkLine} fontSize="20" />
                          <Text>Descrição</Text>
                        </HStack>

                        <Text
                          fontSize="sm"
                          color="gray.500"
                          mt="4"
                          px={[4, 4, 4, 4, 0]}
                        >
                          {venture.bio}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </GridItem>
              )}
            </Grid>
          </Flex>
        )}
      </Flex>
    </>
  );
};
