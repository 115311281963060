import { Flex, HStack, Text } from '@chakra-ui/react';
import { PaginationItem } from './PaginationItem';

interface IGeneratePagesArrayProps {
  from: number;
  to: number;
}

interface IPaginationProps {
  currentPage: number;
  onPageChange: (page: number) => void;
  totalPages: number;
}

// How much siblings pages to be show on pagination
const siblingsCount = 1;

const generatePagesArray = ({
  from,
  to,
}: IGeneratePagesArrayProps): Array<number> =>
  [...new Array(to - from)]
    .map((_, index) => from + index + 1)
    .filter((page) => page > 0);

export const Pagination = ({
  currentPage,
  onPageChange,
  totalPages,
}: IPaginationProps): JSX.Element => {
  const previousPages =
    currentPage > 1
      ? generatePagesArray({
          from: currentPage - 1 - siblingsCount,
          to: currentPage - 1,
        })
      : [];

  const nextPages =
    currentPage < totalPages
      ? generatePagesArray({
          from: currentPage,
          to: Math.min(currentPage + siblingsCount, totalPages),
        })
      : [];

  return (
    <Flex justify="flex-end">
      <HStack mt="8" spacing="2">
        {/* Show first page */}
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem number={1} onClick={() => onPageChange(1)} />
            {currentPage > 2 + siblingsCount && (
              <Text color="blue.500" fontWeight="bold" w="8" textAlign="center">
                ...
              </Text>
            )}
          </>
        )}

        {/* Show previous siblings pages */}
        {previousPages.length > 0 &&
          previousPages.map((page) => (
            <PaginationItem
              key={page}
              number={page}
              onClick={() => onPageChange(page)}
            />
          ))}

        <PaginationItem number={currentPage} isCurrent />

        {/* Show next siblings pages */}
        {nextPages.length > 0 &&
          nextPages.map((page) => (
            <PaginationItem
              key={page}
              number={page}
              onClick={() => onPageChange(page)}
            />
          ))}

        {/* Show last page */}
        {currentPage + siblingsCount < totalPages && (
          <>
            {currentPage + 1 + siblingsCount < totalPages && (
              <Text color="blue.500" fontWeight="bold" w="8" textAlign="center">
                ...
              </Text>
            )}
            <PaginationItem
              number={totalPages}
              onClick={() => onPageChange(totalPages)}
            />
          </>
        )}
      </HStack>
    </Flex>
  );
};
