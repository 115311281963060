import React, { createContext, useCallback, useState, useContext } from 'react';
import { IWeekdayActivitySchedule } from '../services/Activities/ListWeekdaysActivitySchedulesService';

export interface ICalendarEventContextState {
  id: string;
  title: string;
  start: Date;
  end: Date;
  schedules: IWeekdayActivitySchedule[];
  isFull?: boolean;
  isActive: boolean;
}

interface ICalendarEventContextData {
  calendarEvents: ICalendarEventContextState[];
  handleCalendarEvents: (calendarEvents: ICalendarEventContextState[]) => void;
}

const CalendarEventContext = createContext<ICalendarEventContextData>(
  {} as ICalendarEventContextData,
);

const CalendarEventProvider: React.FC = ({ children }) => {
  const [calendarEvents, setCalendarEvents] = useState<
    ICalendarEventContextState[]
  >([]);

  const handleCalendarEvents = useCallback(
    (calendarEventsData: ICalendarEventContextState[]) => {
      setCalendarEvents(calendarEventsData);
    },
    [],
  );

  return (
    <CalendarEventContext.Provider
      value={{
        calendarEvents,
        handleCalendarEvents,
      }}
    >
      {children}
    </CalendarEventContext.Provider>
  );
};

const useCalendarEvents = (): ICalendarEventContextData =>
  useContext(CalendarEventContext);

export { CalendarEventProvider, useCalendarEvents };
