import moment from 'moment';
import { Box, Heading, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { translateError } from '../../../../../utils/errors';
import {
  IBookingQueue,
  listBookingQueuesService,
} from '../../../../../services/BookingQueues/ListBookingQueuesService';
import { BookingQueueTable } from '../../../../../components/BookingQueueTable/BookingQueueTable';
import { updateBookingQueuesPriorityService } from '../../../../../services/BookingQueues/UpdateBookingQueuesPriorityService';
import BookingQueueFilter from '../../../../../components/BookingQueueTable/BookingQueueFilter';

interface IListPagedBookingQueueProps {
  bookedDate?: Date;
  activityId?: string;
  activityScheduleId?: string;
  page?: number;
  limit?: number;
  totalPages?: number;
}

export const BookingQueueList = (): JSX.Element => {
  const toast = useToast();

  const [items, setQueueList] = useState<IBookingQueue[] | undefined>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>();

  const loadQueue = useCallback(
    async ({
      bookedDate,
      activityId,
      activityScheduleId,
    }: IListPagedBookingQueueProps) => {
      try {
        if (!bookedDate || !activityId || !activityScheduleId) {
          setTotalPages(0);
          setQueueList(undefined);
          return;
        }

        const data = await listBookingQueuesService({
          bookedDate: bookedDate
            ? moment(bookedDate).format('YYYY-MM-DD')
            : undefined,
          activityId,
          activityScheduleId,
        });

        setQueueList(data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Erro ao carregar as filas, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    setTotalPages(1);
    setQueueList([]);
  }, [currentPage, loadQueue]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handlePriority = async (bookingQueueId: string): Promise<void> => {
    await updateBookingQueuesPriorityService({
      bookingQueueId,
    });
  };

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <Heading size="lg" fontWeight="normal">
          Filas de espera
        </Heading>

        <BookingQueueFilter
          onSelectFilterValues={(bookedDate, activityId, activityScheduleId) =>
            loadQueue({ bookedDate, activityId, activityScheduleId })
          }
        />

        <BookingQueueTable
          mt="4"
          items={items}
          currentPage={currentPage}
          onChangePriority={handlePriority}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
