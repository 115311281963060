import { UserAvatarStatus } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface ICheckUserFaceProps {
  faceData: FormData;
}

export interface IUserFaceStatus {
  avatarStatus: UserAvatarStatus;
}

export const checkUsersFaceService = async ({
  faceData,
}: ICheckUserFaceProps): Promise<IUserFaceStatus> => {
  const { data } = await jhsfBookingsApi.post<IUserFaceStatus>(
    '/users/face/check',
    faceData,
  );

  return data;
};
