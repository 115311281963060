import { IVentureBase } from '../../models/ventures';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface ICreateVenturesProps {
  appstoreId?: string;
  bio?: string;
  cnpj: string;
  email: string;
  name: string;
  phone?: string;
  playstoreId?: string;
  ref: string;
}

export type INewVenture = IVentureBase;

export const createVenturesService = async ({
  appstoreId,
  bio,
  cnpj,
  email,
  name,
  phone,
  playstoreId,
  ref,
}: ICreateVenturesProps): Promise<INewVenture> => {
  const { data } = await jhsfBookingsApi.post<INewVenture>('/ventures', {
    appstoreId,
    bio,
    cnpj,
    email,
    name,
    phone,
    playstoreId,
    ref,
  });

  return data;
};
