import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Control, Controller, FieldError } from 'react-hook-form';
import ptBr from 'date-fns/locale/pt-BR';

import 'react-datepicker/dist/react-datepicker.css';

interface IDatePickerProps extends ChakraInputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  error?: FieldError;
  excludeDates?: Date[];
  hideTodayButton?: boolean;
  includeDates?: Date[];
  isClearable?: boolean;
  isDisabled?: boolean;
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  name: string;
  onMonthChange?(date: Date): void;
  onFocus?(): void;
}

registerLocale('pt-BR', ptBr);

const DatePickerBase = ({
  name,
  label,
  control,
  error,
  excludeDates,
  hideTodayButton = false,
  includeDates,
  isClearable = true,
  isDisabled = false,
  maxDate,
  minDate,
  onMonthChange,
  onFocus,
  placeholder,
  ...rest
}: IDatePickerProps): JSX.Element => (
  <FormControl
    isInvalid={!!error}
    display="flex"
    flexDirection="column"
    zIndex={3}
  >
    {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          excludeDates={excludeDates}
          includeDates={includeDates}
          isClearable={isClearable}
          disabled={isDisabled}
          locale="pt-BR"
          maxDate={maxDate}
          minDate={minDate}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onMonthChange={onMonthChange}
          placeholderText={placeholder}
          selected={value}
          todayButton={!hideTodayButton ? 'Hoje' : undefined}
          customInput={
            <ChakraInput
              _disabled={{
                background: 'gray.100',
                borderColor: 'gray.300',
                color: '#99999a',
                opacity: 0.8,
                cursor: 'not-allowed',
              }}
              borderColor="gray.300"
              bg="gray.100"
              focusBorderColor="blue.300"
              onKeyUp={(e) => {
                e.currentTarget.maxLength = 10;

                e.currentTarget.value = e.currentTarget.value
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '$1/$2')
                  .replace(/(\d{2})(\d)/, '$1/$2');
              }}
              size="lg"
              variant="outline"
            />
          }
        />
      )}
      {...rest}
    />

    {!!error && (
      <FormErrorMessage position="absolute" bottom={-7}>
        {error.message}
      </FormErrorMessage>
    )}
  </FormControl>
);

export const DatePicker = DatePickerBase;
