import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useAuth } from '../../../../hooks/auth';

import { ICheckinListItem } from '../../../../services/Bookings/ListBookingCheckinsService';
import { ConfirmationModal } from '../../../ConfirmationModal';

export interface ICheckin extends ICheckinListItem {
  formattedCreatedAt: string;
}

interface ICheckinTableProps extends BoxProps {
  checkins: ICheckin[];
  onDelete: (checkinId: string) => void;
}

export const CheckinTable = ({
  checkins,
  onDelete,
  ...rest
}: ICheckinTableProps): JSX.Element => {
  const { user: authenticatedUser } = useAuth();

  const [handlingCheckin, setHandlingCheckin] = useState<ICheckin>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteCheckin = useCallback(
    (checkinId: string) => {
      if (handlingCheckin) {
        setHandlingCheckin(undefined);

        handleToggleDeleteConfirmationModal();

        onDelete(checkinId);
      }
    },
    [handleToggleDeleteConfirmationModal, handlingCheckin, onDelete],
  );

  return (
    <Box {...rest}>
      {handlingCheckin && (
        <ConfirmationModal
          isOpen={isDeleteConfirmationModalVisible}
          onClose={handleToggleDeleteConfirmationModal}
          onConfirm={() => handleDeleteCheckin(handlingCheckin.id)}
          title="Confirmar exclusão"
          message="Deseja realmente excluir o checkin?"
        />
      )}

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Responsável</Th>
            <Th>Criado em</Th>
            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {checkins.map((checkin) => (
            <Tr key={checkin.id}>
              <Td>
                <Text fontWeight="bold">{checkin.user.name}</Text>
              </Td>

              <Td>{checkin.formattedCreatedAt}</Td>

              <Td textAlign="right">
                {authenticatedUser.featureGroup.features.some((feature) =>
                  [
                    'BOOKING_CHECKIN_FULL_ACCESS',
                    'BOOKING_CHECKIN_DELETE_ACCESS',
                  ].includes(feature.key),
                ) && (
                  <IconButton
                    aria-label="Delete"
                    size="sm"
                    icon={<Icon as={RiDeleteBinLine} />}
                    fontSize="16"
                    colorScheme="red"
                    onClick={() => {
                      setHandlingCheckin(checkin);
                      handleToggleDeleteConfirmationModal();
                    }}
                  />
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
