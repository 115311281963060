import { Box, Heading, useToast, Icon, HStack } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { LinkButton } from '../../../../../components/LinkButton';
import {
  maskCnpj,
  maskShortDate,
} from '../../../../../utils/formatters/handleMask';
import { IVenture, VentureTable } from './components/VentureTable';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import { translateError } from '../../../../../utils/errors';
import { useAuth } from '../../../../../hooks/auth';

interface ILoadVenturesProps {
  cnpj?: string;
  email?: string;
  name?: string;
  phone?: string;
  sort?: 'cnpj' | 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const VentureList = (): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const [venturesList, setVenturesList] = useState<IVenture[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('name');
  const [sortBy, setSortBy] = useState<
    'cnpj' | 'name' | 'phone' | 'email' | 'createdAt'
  >('createdAt');
  const [totalPages, setTotalPages] = useState<number>();

  const loadVentures = useCallback(
    async ({
      cnpj,
      email,
      name,
      phone,
      page,
      sort,
      order,
    }: ILoadVenturesProps) => {
      try {
        const ventures = await listVenturesService({
          cnpj: cnpj || undefined,
          email: email || undefined,
          name: name || undefined,
          phone: phone || undefined,
          limit: 20,
          page,
          sort,
          order,
        });

        const parsedVentures = ventures.items.map((venture) => ({
          ...venture,
          cnpj: maskCnpj(venture.cnpj).toString(),
          formattedDate: maskShortDate(venture.createdAt),
        }));

        setTotalPages(ventures.pages);
        setVenturesList(parsedVentures);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados dos empreendimentos, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    loadVentures({
      ...currentFilters,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [currentFilters, currentPage, sortBy, loadVentures, currentOrdering]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters({ [filterBy]: value });
    },
    [filterBy],
  );

  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback(
    (sort: 'cnpj' | 'name' | 'phone' | 'email' | 'createdAt') => {
      setSortBy(sort);
    },
    [],
  );

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
    <DefaultLayout>
      <Box flex="1" borderRadius={8} bg="white" p="8">
        <HStack justify="space-between">
          <Heading size="lg" fontWeight="normal">
            Empreendimentos
          </Heading>

          {authenticatedUser.featureGroup.features.some((feature) =>
            ['VENTURE_FULL_ACCESS', 'VENTURE_WRITE_ACCESS'].includes(
              feature.key,
            ),
          ) && (
            <LinkButton
              colorScheme="green"
              icon={<Icon as={RiAddLine} fontSize="16" />}
              to="/ventures/register"
            >
              Cadastrar novo
            </LinkButton>
          )}
        </HStack>

        <VentureTable
          mt="4"
          ventures={venturesList}
          currentPage={currentPage}
          filterBy={filterBy}
          onChangeFilterBy={handleFilterBy}
          onFilter={debounceHandleFilter}
          onOrder={handleOrderList}
          onPageChange={handlePageChange}
          onSort={handleSortList}
          order={currentOrdering}
          sortBy={sortBy}
          totalPages={totalPages}
        />
      </Box>
    </DefaultLayout>
  );
};
