import { HStack, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { PiCheckCircle, PiWarningCircle } from 'react-icons/pi';
import {
  UserAvatarStatus,
  UserFaceIdStatus,
} from '../../../../../models/users';

enum FaceIdStatusLabel {
  ENABLED = 'FaceID configurado',
  DISABLED = 'FaceID não configurado',
}

enum FaceIdStatusColor {
  ENABLED = 'green',
  DISABLED = 'gray',
}

enum AvatarStatusLabel {
  EMPTY = 'O FaceID não poderá ser {action} sem o envio de uma foto',
  VALID = 'O FaceID será {action} automaticamente ao salvar os dados',
  INVALID = 'O FaceID não poderá ser {action}',
  NOT_VERIFIED = 'O FaceID não poderá ser {action}',
  UNAVAILABLE = 'FaceID desativado',
}

enum AvatarStatusColor {
  EMPTY = 'gray',
  VALID = 'green',
  INVALID = 'yellow',
  NOT_VERIFIED = 'yellow',
  UNAVAILABLE = 'gray',
}

enum IconType {
  CHECK = 'CHECK',
  WARNING = 'WARNING',
}

interface IFaceIdStatusTagProps {
  faceIdStatus: UserFaceIdStatus;
  avatarStatus?: UserAvatarStatus;
  avatarChanged: boolean;
}

const FaceIdStatusTag = ({
  faceIdStatus,
  avatarStatus,
  avatarChanged = false,
}: IFaceIdStatusTagProps): JSX.Element => {
  const [iconType, setIconType] = useState<IconType>();
  const [color, setColor] = useState<AvatarStatusColor | FaceIdStatusColor>();
  const [label, setLabel] = useState<string>();

  const handleFaceIdStatus = useCallback(() => {
    if (!avatarChanged) {
      setColor(FaceIdStatusColor[faceIdStatus]);
      setLabel(FaceIdStatusLabel[faceIdStatus]);
      setIconType(
        faceIdStatus === UserFaceIdStatus.ENABLED
          ? IconType.CHECK
          : IconType.WARNING,
      );
    }

    if (avatarChanged && avatarStatus) {
      setColor(AvatarStatusColor[avatarStatus]);

      const action =
        faceIdStatus === UserFaceIdStatus.ENABLED
          ? 'atualizado'
          : 'configurado';

      setLabel(
        AvatarStatusLabel[avatarStatus]?.toString().replace('{action}', action),
      );

      setIconType(
        avatarStatus === UserAvatarStatus.VALID
          ? IconType.CHECK
          : IconType.WARNING,
      );
    }
  }, [avatarChanged, avatarStatus, faceIdStatus]);

  useEffect(() => {
    handleFaceIdStatus();
  }, [handleFaceIdStatus]);

  return (
    <HStack spacing={4}>
      <Tag size="lg" variant="outline" colorScheme={color} py={1.5}>
        <TagLeftIcon
          as={iconType === IconType.CHECK ? PiCheckCircle : PiWarningCircle}
          fontSize={24}
        />
        <TagLabel fontWeight="normal">{label}</TagLabel>
      </Tag>
    </HStack>
  );
};

export default FaceIdStatusTag;
