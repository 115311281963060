import { useLocation } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { showBookingsService } from '../../../../../services/Bookings/ShowBookingsService';
import { translateError } from '../../../../../utils/errors';
import BookingDetailsData from './components/BookingDetailsData';
import { BookingDetailsInfo } from './components/BookingDetailsInfo';
import { useBooking } from '../../../../../hooks/booking';

interface ILocationState {
  bookingId: string;
}

export const BookingDetails = (): JSX.Element => {
  const toast = useToast();
  const { handleBooking, booking } = useBooking();

  const {
    state: { bookingId },
  } = useLocation<ILocationState>();

  useEffect(() => {
    async function loadBooking(): Promise<void> {
      try {
        const bookingData = await showBookingsService(bookingId);

        handleBooking(bookingData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes da reserva, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadBooking();

    return () => handleBooking(undefined);
  }, [bookingId, handleBooking, toast]);

  return (
    <DefaultLayout>
      {booking && (
        <Flex direction="column" w="100%">
          <BookingDetailsInfo minW="510px" />

          <BookingDetailsData flex="1" mt="8" minW="510px" />
        </Flex>
      )}
    </DefaultLayout>
  );
};
