import { IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IUpdateUserProps {
  userId: string;
  blockExpiresAt?: Date | null;
  isBlocked: boolean;
}
export type IUpdatedUser = IUserBase;

export const UpdateUserBlocksService = async ({
  userId,
  blockExpiresAt,
  isBlocked,
}: IUpdateUserProps): Promise<IUpdatedUser> => {
  const { data } = await jhsfBookingsApi.patch<IUpdatedUser>(
    `/users/${userId}/blocks`,
    {
      blockExpiresAt,
      isBlocked,
    },
  );

  return data;
};
