import {
  FlexProps,
  Flex,
  Heading,
  Icon,
  Box,
  SimpleGrid,
  HStack,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react';
import { RiEyeLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { IFeatureGroupBase } from '../../../../../../../../../models/features';
import { IUserBase } from '../../../../../../../../../models/users';

interface IBookingQueueDetailsInfoUser extends IUserBase {
  featureGroup: IFeatureGroupBase;
}

interface IBookingQueueDetailsUserInfoProps extends FlexProps {
  user: IBookingQueueDetailsInfoUser;
}

const BookingQueueDetailsUserInfo = ({
  user,
  ...rest
}: IBookingQueueDetailsUserInfoProps): JSX.Element => (
  <Flex direction="column" px="8" {...rest}>
    <Heading size="md" fontWeight="normal">
      Usuário
    </Heading>

    <Box mt="4" px="4">
      <SimpleGrid columns={[1, 1, 1, 1, 4]} spacing="4" w="100%">
        <Text fontSize="lg" fontWeight="bold">
          {user.name}
        </Text>

        <HStack>
          <Text fontSize="sm">Telefone:</Text>
          <Text color="gray.500" fontSize="sm">
            {user.phone}
          </Text>
        </HStack>

        <HStack>
          <Text fontSize="sm">E-mail:</Text>
          <Text color="gray.500" fontSize="sm">
            {user.email}
          </Text>
        </HStack>

        <ChakraLink
          ml="auto"
          as={Link}
          to={
            user.featureGroup?.key.includes('GUEST')
              ? {
                  pathname: '/guests/details',
                  state: { guestId: user.id },
                }
              : {
                  pathname: '/members/details',
                  state: { memberId: user.id },
                }
          }
        >
          <HStack>
            <Icon color="blue.300" as={RiEyeLine} fontSize="20" />
            <Text color="blue.300">Detalhes</Text>
          </HStack>
        </ChakraLink>
      </SimpleGrid>
    </Box>
  </Flex>
);

export default BookingQueueDetailsUserInfo;
