import { useLocation } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { translateError } from '../../../../../utils/errors';
import { showBookingQueuesService } from '../../../../../services/BookingQueues/ShowBookingQueuesService';
import { BookingQueueDetailsInfo } from './components/BookingQueueDetailsInfo';
import { useBookingQueue } from '../../../../../hooks/bookingQueue';
import BookingQueueDetailsData from './components/BookingQueueDetailsData';

interface ILocationState {
  bookingQueueId: string;
}

export const BookingQueueDetails = (): JSX.Element => {
  const toast = useToast();
  const { handleBookingQueue, bookingQueue } = useBookingQueue();

  const {
    state: { bookingQueueId },
  } = useLocation<ILocationState>();

  useEffect(() => {
    async function loadBookingQueue(): Promise<void> {
      try {
        const bookingQueueData = await showBookingQueuesService(bookingQueueId);

        handleBookingQueue(bookingQueueData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes da reserva, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadBookingQueue();

    return () => handleBookingQueue(undefined);
  }, [bookingQueueId, handleBookingQueue, toast]);

  return (
    <DefaultLayout>
      {bookingQueue && (
        <Flex direction="column" w="100%">
          <BookingQueueDetailsInfo minW="510px" />

          <BookingQueueDetailsData flex="1" mt="8" minW="510px" />
        </Flex>
      )}
    </DefaultLayout>
  );
};
