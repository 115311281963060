import { OptionTypeBase, StylesConfig } from 'react-select';
import theme from '../../styles/theme';

export const useAsyncSelectStyles: StylesConfig<OptionTypeBase, false> = {
  menu: (provided) => ({
    ...provided,
    borderRadius: theme.radii.md,
    padding: 15,
    zIndex: 4,
  }),
  control: (provided, state) => {
    let shadow = provided.boxShadow;

    if (state.isFocused || state.menuIsOpen) {
      shadow = `0 0 0 1px ${theme.colors.blue[300]}`;
    }

    let border = theme.colors.gray[300];

    if (state.isFocused || state.menuIsOpen) {
      border = `${theme.colors.blue[300]}`;
    }

    return {
      ...provided,
      backgroundColor: theme.colors.gray[100],
      borderRadius: `0 ${theme.radii.sm} ${theme.radii.sm} 0`,
      borderColor: 'border',
      boxShadow: shadow,
      minHeight: 32,
      height: 32,
      padding: '0 4px',
      ':hover': {
        borderColor: border,
      },
    };
  },
  dropdownIndicator: (provided) => ({
    ...provided,
    color: theme.colors.gray[700],
    ':hover': {
      color: theme.colors.gray[700],
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: 32,
    height: 32,
    padding: 0,
  }),
  option: (provided, state: { isSelected: boolean }) => ({
    ...provided,
    borderRadius: theme.radii.md,
    color: state.isSelected ? 'white' : theme.colors.gray[700],
    padding: '10px 20px',
    marginBottom: 4,
    cursor: 'pointer',
    height: 44,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
    fontSize: '14px',
    minHeight: 32,
    height: 32,
  }),
};
