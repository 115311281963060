import { FlexProps, Flex, Heading, Box, HStack, Text } from '@chakra-ui/react';
import { IAddressEntity } from '../../models/address';
import { maskZipCode } from '../../utils/formatters/handleMask';

interface IAddressDetails extends FlexProps {
  address: IAddressEntity;
}

const AddressDetails = ({ address, ...rest }: IAddressDetails): JSX.Element => (
  <Flex direction="column" {...rest}>
    <Heading size="md" fontWeight="normal">
      Endereço
    </Heading>

    <Box mt="4" px="4">
      <HStack>
        <Text>Logradouro:</Text>
        <Text color="gray.500">{address.street}</Text>
      </HStack>

      <HStack>
        <Text>Número:</Text>
        <Text color="gray.500">{address.number}</Text>
      </HStack>

      <HStack>
        <Text>Bairro:</Text>
        <Text color="gray.500">{address.neighborhood}</Text>
      </HStack>

      <HStack>
        <Text>Cidade:</Text>
        <Text color="gray.500">{address.city}</Text>
      </HStack>

      <HStack>
        <Text>Estado:</Text>
        <Text color="gray.500">{address.state}</Text>
      </HStack>

      <HStack>
        <Text>País:</Text>
        <Text color="gray.500">{address.country}</Text>
      </HStack>

      <HStack>
        <Text>CEP:</Text>
        <Text color="gray.500">{maskZipCode(address.zipCode)}</Text>
      </HStack>
    </Box>
  </Flex>
);

export default AddressDetails;
