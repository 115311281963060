import {
  Box,
  Button,
  Text,
  HStack,
  Icon,
  IconButton,
  VStack,
  Fade,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import {
  RiCloseLine,
  RiNotificationFill,
  RiNotificationLine,
} from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { INotificationBase } from '../../models/notifications';
import { listNotifications } from '../../services/Notifications/listNotifications';
import { updateNotifications } from '../../services/Notifications/updateNotifications';
import { socket } from '../../services/Socket';

interface IRemoveNotificationsProps {
  bookingId?: string;
  bookingQueueId?: string;
}

export const NotificationsNav = (): JSX.Element => {
  const { push } = useHistory();
  const [notifications, setNotifications] = useState<INotificationBase[]>([]);
  const [showNotifications, setShowNotifications] = useState(false);

  const hasViewPendingNotifications = useMemo(
    () => notifications.some((notification) => notification.isUnread),
    [notifications],
  );

  useEffect(() => {
    async function loadNotifications(): Promise<void> {
      const response = await listNotifications({ isUnread: true });

      setNotifications(response);
    }

    loadNotifications();
  }, []);

  useEffect(() => {
    function addNotifications(notification: INotificationBase): void {
      setNotifications((prevState) => [notification, ...prevState]);
    }

    function removeNotifications({
      bookingId,
      bookingQueueId,
    }: IRemoveNotificationsProps): void {
      if (bookingId) {
        setNotifications((prevState) =>
          prevState.filter(
            (notification) => notification.bookingId !== bookingId,
          ),
        );
      }

      if (bookingQueueId) {
        setNotifications((prevState) =>
          prevState.filter(
            (notification) => notification.bookingQueueId !== bookingQueueId,
          ),
        );
      }
    }

    socket.on('booking-notification', addNotifications);
    socket.on('cancel-booking-notification', removeNotifications);

    return () => {
      socket.off('booking-notification', addNotifications);
      socket.off('cancel-booking-notification', removeNotifications);
    };
  }, []);

  const toggleShowNotifications = (): void => {
    setShowNotifications((prevState) => !prevState);
  };

  const setNotificationRead = async (notificationId: string): Promise<void> => {
    setNotifications((prevState) =>
      prevState.filter((notification) => notification.id !== notificationId),
    );

    await updateNotifications({
      isUnread: false,
      notificationId,
    });
  };

  const showBookingDetails = async (
    notification: INotificationBase,
  ): Promise<void> => {
    setNotificationRead(notification.id);

    if (notification.bookingId) {
      push('/bookings/details', {
        bookingId: notification.bookingId,
      });
    }

    if (notification.bookingQueueId) {
      push('/booking-queues/details', {
        bookingQueueId: notification.bookingQueueId,
      });
    }
  };

  return (
    <Box position="relative">
      <HStack
        spacing={['6', '8']}
        mx={['6', '8']}
        pr={['6', '8']}
        py="1"
        borderRightWidth={1}
        borderColor="gray.700"
      >
        <Button
          onClick={toggleShowNotifications}
          _focus={{ borderColor: 'transparent' }}
        >
          {!hasViewPendingNotifications ? (
            <Icon as={RiNotificationLine} fontSize="24" />
          ) : (
            <Icon as={RiNotificationFill} fontSize="24" fill="red.500" />
          )}
        </Button>
      </HStack>

      <Box
        display={showNotifications ? 'block' : 'none'}
        zIndex={10000}
        position="absolute"
        top={16}
      >
        <Fade in={showNotifications}>
          <VStack
            bg="black"
            borderRadius={8}
            overflow="auto"
            opacity={0.75}
            w="22rem"
            h="96"
            p="2"
          >
            <HStack justifyContent="space-between" w="full" pl="2">
              <Text color="white">Notificações</Text>

              <IconButton
                colorScheme="blackAlpha"
                aria-label="close-notifications"
                onClick={toggleShowNotifications}
                _focus={{ borderColor: 'transparent' }}
              >
                <Icon as={RiCloseLine} fontSize="24" />
              </IconButton>
            </HStack>

            <Box w="full">
              {notifications.map((notification) => (
                <VStack
                  key={notification.id}
                  align="flex-start"
                  spacing={1}
                  color="gray.300"
                  _notFirst={{
                    borderTopWidth: 1,
                    borderTopColor: 'gray.600',
                  }}
                  py="4"
                >
                  <Button
                    w="full"
                    flex={1}
                    colorScheme="blackAlpha"
                    onClick={() => showBookingDetails(notification)}
                    disabled={
                      !notification.bookingId && !notification.bookingQueueId
                    }
                    _focus={{ borderColor: 'transparent' }}
                  >
                    <VStack
                      w="full"
                      fontWeight="normal"
                      align="flex-start"
                      spacing={2}
                      _hover={{ textDecoration: 'underline' }}
                    >
                      <Text fontSize="sm">{notification.title}</Text>
                      <Text fontSize="sm">{notification.description}</Text>
                    </VStack>
                  </Button>

                  {notification.isUnread ? (
                    <Button
                      colorScheme="blackAlpha"
                      alignSelf="center"
                      py="1"
                      px="4"
                      h="min"
                      _focus={{ borderColor: 'transparent' }}
                      onClick={() => setNotificationRead(notification.id)}
                      _hover={{ backgroundColor: 'gray.900' }}
                    >
                      <Text fontWeight="normal" fontSize="sm" color="gray.400">
                        Marcar como visualizado
                      </Text>
                    </Button>
                  ) : (
                    <Text>Visualizado</Text>
                  )}
                </VStack>
              ))}
            </Box>
          </VStack>
        </Fade>
      </Box>
    </Box>
  );
};
