import {
  IActivityBase,
  IActivityItemBase,
  IActivityScheduleBase,
  IActivityScheduleItemBase,
} from '../../models/activities';
import { ISpotBase, ISpotModalityBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export interface IShowActivityScheduleItem extends IActivityScheduleItemBase {
  user?: IUserBase;
}

export interface IShowActivitySchedulesActivity extends IActivityBase {
  items: IActivityItemBase[];
  spot: ISpotBase;
}

export interface IShowActivitySchedule extends IActivityScheduleBase {
  activity: IShowActivitySchedulesActivity;
  items: IShowActivityScheduleItem[];
  modality?: ISpotModalityBase;
}

interface IShowActivitySchedulesProps {
  scheduleId: string;
  showInactiveItems?: boolean;
}

export const showActivitySchedulesService = async ({
  scheduleId,
  showInactiveItems,
}: IShowActivitySchedulesProps): Promise<IShowActivitySchedule> => {
  const { data } = await jhsfBookingsApi.get<IShowActivitySchedule>(
    `/activities/schedules/${scheduleId}`,
    {
      params: {
        showInactiveItems,
      },
    },
  );

  return data;
};
