import { IBookingQueueBase } from '../../models/bookingQueues';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IUpdateBookingQueueProps {
  bookingQueueId: string;
}

export type IUpdatedBookingQueue = IBookingQueueBase;

export const updateBookingQueuesPriorityService = async ({
  bookingQueueId,
}: IUpdateBookingQueueProps): Promise<IUpdatedBookingQueue> => {
  const { data } = await jhsfBookingsApi.patch<IUpdatedBookingQueue>(
    `/booking-queues/${bookingQueueId}/priority`,
  );

  return data;
};
