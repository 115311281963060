import {
  Box,
  Heading,
  VStack,
  Grid,
  GridItem,
  SimpleGrid,
  BoxProps,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import { searchCepService } from '../../services/Address/SearchCepService';
import CountrySelect from '../Form/CountrySelect';
import { MaskedInput } from '../Form/MaskedInput';
import ZipCodeInput from '../Form/ZipCodeInput';

export const addressValidation = Yup.object({
  city: Yup.string()
    .nullable()
    .required('Cidade requerida')
    .matches(/^\D+$/, 'Valor inválido, apenas letras')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  complement: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  country: Yup.string()
    .nullable()
    .length(2, 'Valor inválido, requerido 2 dígitos')
    .required('País requerido')
    .matches(/^\D+$/, 'Valor inválido, apenas letras')
    .transform((value, originalValue) =>
      originalValue === '' ? null : String(value).toUpperCase(),
    ),
  neighborhood: Yup.string()
    .nullable()
    .required('Bairro requerido')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  number: Yup.string()
    .nullable()
    .required('Número requerido')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  zipCode: Yup.string()
    .nullable()
    .length(8, 'Valor inválido, requerido 8 dígitos')
    .required('Código postal requerido')
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  state: Yup.string()
    .nullable()
    .length(2, 'Valor inválido, requerido 2 dígitos')
    .required('Estado requerido')
    .matches(/^\D+$/, 'Valor inválido, apenas letras')
    .transform((value, originalValue) =>
      originalValue === '' ? null : String(value).toUpperCase(),
    ),
  street: Yup.string()
    .nullable()
    .required('Logradouro requerido')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

type IAddressFormProps = BoxProps;

const AddressForm = (props: IAddressFormProps): JSX.Element => {
  const toast = useToast();

  const [loadingCep, setLoadingCep] = useState<boolean>(false);

  const {
    control,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const handleCepData = useCallback(
    async (value: string): Promise<void> => {
      if (value.length < 9) {
        return;
      }

      setLoadingCep(true);

      try {
        const cep = await searchCepService(value.replace('-', ''));

        setValue('address.city', cep.city);
        setValue('address.complement', cep.complement);
        setValue('address.country', cep.country);
        setValue('address.neighborhood', cep.neighborhood);
        setValue('address.state', cep.state);
        setValue('address.street', cep.street);
        setValue('address.number', '');
      } catch (err) {
        toast({
          description: 'Preencha os campos manualmente',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
          status: 'warning',
          title: 'CEP não encontrado',
          variant: 'subtle',
        });
      } finally {
        setLoadingCep(false);
      }
    },
    [setValue, toast],
  );

  return (
    <Box {...props}>
      <Heading size="md" fontWeight="normal">
        Endereço
      </Heading>

      <VStack spacing="8" mt="8">
        <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
          <ZipCodeInput
            errors={errors}
            register={register}
            loadingCep={loadingCep}
            onChange={(event) => handleCepData(event.target.value)}
          />

          <CountrySelect control={control} errors={errors} />
        </SimpleGrid>
        <Grid
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(12, 1fr)',
          ]}
          gap="6"
          width="100%"
        >
          <GridItem colSpan={[12, 12, 10, 10]}>
            <MaskedInput
              label="Logradouro"
              error={errors.address?.street}
              {...register('address.street')}
            />
          </GridItem>

          <GridItem colSpan={[12, 12, 2, 2]}>
            <MaskedInput
              label="Número"
              error={errors.address?.number}
              {...register('address.number')}
            />
          </GridItem>
        </Grid>

        <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
          <MaskedInput
            label="Complemento"
            error={errors.address?.complement}
            {...register('address.complement')}
          />
          <MaskedInput
            label="Bairro"
            error={errors.address?.neighborhood}
            {...register('address.neighborhood')}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="240px" spacing="6" w="100%">
          <MaskedInput
            label="Cidade"
            error={errors.address?.city}
            {...register('address.city')}
          />
          <MaskedInput
            label="Estado"
            placeholder="SP"
            textTransform="uppercase"
            error={errors.address?.state}
            {...register('address.state')}
          />
        </SimpleGrid>
      </VStack>
    </Box>
  );
};
export default AddressForm;
