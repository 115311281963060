import { IActivityBase } from '../../models/activities';
import { IVentureBase } from '../../models/ventures';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IUpdateVenturesProps {
  ventureId: string;
  appstoreId?: string;
  bio?: string;
  cnpj: string;
  email: string;
  name: string;
  phone?: string;
  playstoreId?: string;
  ref: string;
}

export interface IUpdatedVenture extends IVentureBase {
  activities: IActivityBase[];
}

export const updateVenturesService = async ({
  ventureId,
  appstoreId,
  bio,
  cnpj,
  email,
  name,
  phone,
  playstoreId,
  ref,
}: IUpdateVenturesProps): Promise<IUpdatedVenture> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedVenture>(
    `/ventures/${ventureId}`,
    {
      appstoreId,
      bio,
      cnpj,
      email,
      name,
      phone,
      playstoreId,
      ref,
    },
  );

  return data;
};
