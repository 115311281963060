import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import {
  maskCpf,
  maskZipCode,
} from '../../../../../utils/formatters/handleMask';
import {
  IDetailedUser,
  showUsersService,
} from '../../../../../services/Users/ShowUsersService';
import deleteUserAvatarsService from '../../../../../services/Users/DeleteUserAvatarsService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { translateError } from '../../../../../utils/errors';
import { updateUsersService } from '../../../../../services/Users/UpdateUsersService';
import {
  validateCpf,
  validateBRPhone,
} from '../../../../../utils/documentValidation';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import {
  UserAvatarStatus,
  UserExperience,
  UserFaceIdStatus,
} from '../../../../../models/users';
import { listUserCategoriesService } from '../../../../../services/UserCategories/ListUserCategoriesService';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { IAddressBase } from '../../../../../models/address';
import AddressForm, {
  addressValidation,
} from '../../../../../components/AddressForm';
import FaceIdStatusTag from '../../components/FaceIDStatusTag';
import AvatarStatusCard from '../../components/AvatarStatusCard';
import { checkUsersFaceService } from '../../../../../services/Users/CheckUsersFaceService';

type UpdateMemberFormData = {
  address: IAddressBase;
  bio?: string;
  birthDate: Date;
  categoryId?: string;
  cpf?: string;
  email: string;
  experience: UserExperience;
  healthCheck?: Date;
  name: string;
  phone?: string;
};

interface ILocationState {
  memberId: string;
}

const memberUpdateFormSchema = Yup.object().shape({
  address: addressValidation.nullable().transform((value, originalValue) => {
    if (
      !!originalValue &&
      Object.values<string | undefined>(originalValue).some(
        (val) => !!val?.length,
      )
    ) {
      return originalValue;
    }

    return null;
  }),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  birthDate: Yup.date().nullable().required('Data de nascimento requerida'),
  categoryId: Yup.string().uuid().nullable(),
  cpf: Yup.string()
    .length(11, 'Valor inválido, requerido 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  experience: Yup.number()
    .integer()
    .oneOf([
      UserExperience['Nível 1 e 2'],
      UserExperience['Nível 3 e 4'],
      UserExperience['Nível 5'],
      UserExperience['Nível 6'],
      UserExperience['Nível 6 e 7'],
      UserExperience['Nível 7'],
      UserExperience['Nível 8'],
      UserExperience.Especial,
    ])
    .required('Experiência requerida.'),
  healthCheck: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string()
    .required('Nome requerido')
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'Nome inválido'),
  phone: Yup.string()
    .test('is-valid', 'Telefone inválido', (value) => validateBRPhone(value))
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const MemberUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();

  const { state } = useLocation<ILocationState>();

  const [avatarStatus, setAvatarStatus] = useState<UserAvatarStatus>(
    UserAvatarStatus.EMPTY,
  );
  const [avatarChanged, setAvatarChanged] = useState<boolean>(false);
  const [isCheckingAvatar, setIsCheckingAvatar] = useState(false);
  const [updatingMember, setUpdatingMember] = useState<IDetailedUser>();
  const [faceIdStatus, setFaceIdStatus] = useState<UserFaceIdStatus>(
    UserFaceIdStatus.DISABLED,
  );
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [userCategoriesSelectOptions, setUserCategoriesSelectOptions] =
    useState<SelectOption[]>([]);

  const formMethods = useForm({
    resolver: yupResolver(memberUpdateFormSchema),
  });

  const { register, handleSubmit, formState, reset, control, setError } =
    formMethods;

  const { errors } = formState;
  const { memberId } = state;

  const isMember = updatingMember?.featureGroup?.key.includes('MEMBER');

  const experienceSelectOptions = useMemo(
    () => [
      {
        label: 'Nível 1 e 2',
        value: UserExperience['Nível 1 e 2'],
      },
      {
        label: 'Nível 3 e 4',
        value: UserExperience['Nível 3 e 4'],
      },
      {
        label: 'Nível 5',
        value: UserExperience['Nível 5'],
      },
      {
        label: 'Nível 6',
        value: UserExperience['Nível 6'],
      },
      {
        label: 'Nível 6 e 7',
        value: UserExperience['Nível 6 e 7'],
      },
      {
        label: 'Nível 7',
        value: UserExperience['Nível 7'],
      },
      {
        label: 'Nível 8',
        value: UserExperience['Nível 8'],
      },
      {
        label: 'Especial',
        value: UserExperience.Especial,
      },
    ],
    [],
  );

  const checkUserAvatar = useCallback(
    async (file: File) => {
      setIsCheckingAvatar(true);

      try {
        const formData = new FormData();
        formData.append('face', file);

        const { avatarStatus: uploadedAvatarStatus } =
          await checkUsersFaceService({
            faceData: formData,
          });

        setAvatarStatus(uploadedAvatarStatus);

        if (avatarStatus === UserAvatarStatus.VALID) {
          toast({
            title: 'Foto validada',
            description: 'A foto foi validada com sucesso',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } catch (err) {
        setAvatarStatus(UserAvatarStatus.NOT_VERIFIED);

        toast({
          title: 'Foto não validada',
          description: 'Ocorreu um erro ao validar a foto',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } finally {
        setAvatarChanged(true);
        setIsCheckingAvatar(false);
      }
    },
    [toast, avatarStatus],
  );

  const getUserAvatarFile = useCallback(
    async (userAvatarUrl: string): Promise<File | undefined> => {
      try {
        const { data: file } = await axios.get<File>(userAvatarUrl, {
          responseType: 'blob',
        });
        return file;
      } catch (err) {
        return undefined;
      }
    },
    [],
  );

  const handleCheckUserAvatar = useCallback(async () => {
    if (avatar) {
      checkUserAvatar(avatar);
    }

    if (updatingMember && updatingMember.avatarUrl) {
      const avatarFile = await getUserAvatarFile(updatingMember.avatarUrl);

      if (avatarFile) {
        checkUserAvatar(avatarFile);
      }
    }
  }, [updatingMember, getUserAvatarFile, checkUserAvatar, avatar]);

  useEffect(() => {
    async function loadMember(): Promise<void> {
      try {
        const memberData = await showUsersService(memberId);
        setAvatarUrl(memberData.avatarUrl || undefined);
        setAvatarStatus(memberData.avatarStatus);
        setFaceIdStatus(memberData.faceIdStatus);
        setUpdatingMember(memberData);

        if (
          memberData.avatarStatus === 'VALID' &&
          memberData.faceIdStatus === 'DISABLED'
        ) {
          setAvatarChanged(true);
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados do membro, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadMember();
  }, [memberId, reset, toast]);

  useEffect(() => {
    async function loadUserCategories(ventureId: string): Promise<void> {
      const userCategories = await listUserCategoriesService(ventureId);

      setUserCategoriesSelectOptions(
        userCategories.map((userCategory) => ({
          label: userCategory.title,
          value: userCategory.id,
        })),
      );
    }

    if (updatingMember?.ventureId) {
      loadUserCategories(updatingMember.ventureId);
    }
  }, [updatingMember?.ventureId]);

  useEffect(() => {
    if (updatingMember) {
      reset({
        address: {
          city: updatingMember.address?.city,
          complement: updatingMember.address?.complement,
          country: updatingMember.address?.country,
          neighborhood: updatingMember.address?.neighborhood,
          number: updatingMember.address?.number,
          zipCode: updatingMember.address?.zipCode
            ? maskZipCode(updatingMember.address?.zipCode)
            : undefined,
          state: updatingMember.address?.state,
          street: updatingMember.address?.street,
        },
        bio: updatingMember.bio,
        birthDate: updatingMember.birthDate
          ? new Date(updatingMember.birthDate)
          : undefined,
        categoryId: updatingMember.categoryId,
        cpf: updatingMember.cpf ? maskCpf(updatingMember.cpf) : undefined,
        email: updatingMember.email,
        experience: updatingMember.experience,
        healthCheck: updatingMember.healthCheck
          ? new Date(updatingMember.healthCheck)
          : undefined,
        name: updatingMember.name,
        phone: updatingMember.phone,
      });
    }
  }, [reset, updatingMember]);

  const handleChangeAvatar = useCallback(
    (file: File) => {
      setAvatar(file);
      setAvatarUrl(URL.createObjectURL(file));
      checkUserAvatar(file);
    },
    [checkUserAvatar],
  );

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteUserAvatarsService(memberId);

    setAvatarChanged(true);
    setAvatarStatus(UserAvatarStatus.EMPTY);
    setAvatar(undefined);
    setAvatarUrl(undefined);

    handleToggleDeleteConfirmationModal();
  }, [memberId, handleToggleDeleteConfirmationModal]);

  const handleUpdateMember: SubmitHandler<UpdateMemberFormData> = useCallback(
    async ({
      address,
      bio,
      birthDate,
      categoryId,
      cpf,
      email,
      experience,
      healthCheck,
      name,
      phone,
    }) => {
      if (updatingMember) {
        if (isMember && !categoryId) {
          setError('categoryId', { message: 'Categoria requerida' });

          return;
        }

        try {
          await updateUsersService({
            userId: updatingMember.id,
            address,
            bio,
            birthDate,
            categoryId,
            cpf,
            email,
            experience,
            featureGroupId: updatingMember.featureGroupId,
            healthCheck,
            name,
            phone,
          });

          let avatarFile = avatar;

          if (
            !avatar &&
            updatingMember.avatarUrl &&
            avatarStatus === UserAvatarStatus.VALID &&
            faceIdStatus === UserFaceIdStatus.DISABLED
          ) {
            const getFile = await getUserAvatarFile(updatingMember.avatarUrl);

            if (getFile) {
              avatarFile = getFile;
            }
          }

          if (avatarFile) {
            const formData = new FormData();

            formData.append('avatar', avatarFile);
            formData.append('faceStatus', avatarStatus);

            await updateUserAvatarsService({
              userId: updatingMember.id,
              avatarData: formData,
            });
          }

          toast({
            title: 'Editado com sucesso',
            description: 'O membro foi editado corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao editar',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao editar o membro, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [
      avatar,
      goBack,
      isMember,
      setError,
      toast,
      updatingMember,
      avatarStatus,
      faceIdStatus,
      getUserAvatarFile,
    ],
  );

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      <FormProvider {...formMethods}>
        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handleUpdateMember)}
        >
          <Heading size="lg" fontWeight="normal">
            Editar membro
          </Heading>

          <Divider my="6" borderColor="gray.300" />

          <Flex direction="column" align="center" mb="8">
            <Box mb={6} alignSelf="flex-end">
              <FaceIdStatusTag
                avatarChanged={avatarChanged}
                avatarStatus={avatarStatus}
                faceIdStatus={faceIdStatus}
              />
            </Box>

            <AvatarDropzone
              avatarUrl={avatarUrl}
              onChange={handleChangeAvatar}
              onDelete={handleToggleDeleteConfirmationModal}
            />

            <Box mt={6}>
              <AvatarStatusCard avatarStatus={avatarStatus} />
            </Box>

            {(avatarStatus === UserAvatarStatus.NOT_VERIFIED ||
              isCheckingAvatar) && (
              <Box mt={3}>
                <Button
                  isLoading={isCheckingAvatar}
                  loadingText="Validando foto..."
                  colorScheme="blue"
                  onClick={handleCheckUserAvatar}
                >
                  Validar foto
                </Button>
              </Box>
            )}
          </Flex>

          <VStack spacing="8">
            <MaskedInput
              label="Nome completo"
              error={errors.name}
              {...register('name')}
            />

            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
                'repeat(12, 1fr)',
                'repeat(12, 1fr)',
              ]}
              columnGap="4"
              rowGap="8"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 6]}>
                <MaskedInput
                  label="CPF"
                  mask="cpf"
                  error={errors.cpf}
                  {...register('cpf')}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 6]}>
                <DatePicker
                  label="Data de nascimento"
                  isClearable={false}
                  maxDate={new Date()}
                  control={control}
                  error={errors.birthDate}
                  {...register('birthDate')}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 6]}>
                <InternationalPhoneInput
                  label="Telefone"
                  name="phone"
                  control={control}
                  error={errors.phone}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 6]}>
                <MaskedInput
                  label="E-mail"
                  type="email"
                  textTransform="lowercase"
                  error={errors.email}
                  {...register('email')}
                />
              </GridItem>

              <GridItem colSpan={isMember ? [12, 12, 6, 6, 6, 4] : [12, 12, 6]}>
                <DatePicker
                  label="Exame médico"
                  maxDate={new Date()}
                  control={control}
                  error={errors.healthCheck}
                  {...register('healthCheck')}
                />
              </GridItem>

              <GridItem colSpan={isMember ? [12, 12, 6, 6, 6, 4] : [12, 12, 6]}>
                <ReactSelect
                  label="Nível de experiência"
                  name="experience"
                  options={experienceSelectOptions}
                  control={control}
                  error={errors.experience}
                />
              </GridItem>

              {isMember && (
                <GridItem colSpan={[12, 12, 12, 12, 12, 4]}>
                  <ReactSelect
                    label="Categoria"
                    name="categoryId"
                    options={userCategoriesSelectOptions}
                    control={control}
                    error={errors.categoryId}
                  />
                </GridItem>
              )}
            </Grid>

            <MaskedInput
              label="Biografia"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.bio}
              {...register('bio')}
            />
          </VStack>

          <Divider my="6" borderColor="gray.300" />

          <AddressForm />

          <Flex mt="12" justify="flex-end">
            <ButtonGroup>
              <Button colorScheme="blackAlpha" onClick={goBack}>
                Cancelar
              </Button>

              <Button
                type="submit"
                colorScheme="green"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </Flex>
        </Box>
      </FormProvider>
    </DefaultLayout>
  );
};
