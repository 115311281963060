import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Heading,
  ButtonGroup,
} from '@chakra-ui/react';

interface IConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  message: string;
  warningMessage?: string;
}

export const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title = 'Confirmação de ação',
  message,
  warningMessage,
}: IConfirmationModalProps): JSX.Element => (
  <Modal size="lg" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>
        <Heading size="lg" fontWeight="normal">
          {title}
        </Heading>
      </ModalHeader>

      <ModalCloseButton />

      <ModalBody>
        <Text>{message}</Text>

        <Text mt="4" color="red.500" fontSize="sm">
          {warningMessage}
        </Text>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button colorScheme="blackAlpha" onClick={onClose}>
            Cancelar
          </Button>

          <Button colorScheme="green" onClick={onConfirm}>
            Confirmar
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
