import { HStack, Icon, Text } from '@chakra-ui/react';
import { ElementType } from 'react';
import { LinkProps } from 'react-router-dom';

import { ActiveLink } from './ActiveLink';

interface INavLinkProps extends LinkProps {
  icon: ElementType;
  children: string;
  shouldMatchExactHref?: boolean;
  to: string;
}

export const NavLink: React.FC<INavLinkProps> = ({
  icon,
  children,
  shouldMatchExactHref,
  to,
  ...rest
}) => (
  <ActiveLink to={to} shouldMatchExactHref={shouldMatchExactHref} {...rest}>
    <HStack>
      <Icon as={icon} fontSize="20" />
      <Text ml="4" fontWeight="medium">
        {children}
      </Text>
    </HStack>
  </ActiveLink>
);
