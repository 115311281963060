import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { createUsersService } from '../../../../../services/Users/CreateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { translateError } from '../../../../../utils/errors';
import {
  validateCpf,
  validateBRPhone,
} from '../../../../../utils/documentValidation';
import { listVenturesService } from '../../../../../services/Ventures/ListVenturesService';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { useAuth } from '../../../../../hooks/auth';
import {
  UserAvatarStatus,
  UserExperience,
  UserFaceIdStatus,
} from '../../../../../models/users';
import { listUserCategoriesService } from '../../../../../services/UserCategories/ListUserCategoriesService';
import { IAddressBase } from '../../../../../models/address';
import AddressForm, {
  addressValidation,
} from '../../../../../components/AddressForm';
import { checkUsersFaceService } from '../../../../../services/Users/CheckUsersFaceService';
import AvatarStatusCard from '../../components/AvatarStatusCard';
import FaceIdStatusTag from '../../components/FaceIDStatusTag';

type NewMemberFormData = {
  address?: IAddressBase;
  bio?: string;
  birthDate: Date;
  categoryId?: string;
  cpf?: string;
  email: string;
  experience: UserExperience;
  healthCheck?: Date;
  name: string;
  phone?: string;
  ventureId: string;
};

const registerMemberFormSchema = Yup.object().shape({
  address: addressValidation
    .nullable()
    .transform((value, originalValue) =>
      !!originalValue &&
      Object.values<string | undefined>(originalValue).some(
        (val) => !!val?.length,
      )
        ? originalValue
        : null,
    ),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  birthDate: Yup.date().nullable().required('Data de nascimento requerida'),
  categoryId: Yup.string().uuid().required('Categoria requerida'),
  cpf: Yup.string()
    .length(11, 'Valor inválido, requerido 11 dígitos')
    .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
    .nullable()
    .transform((_, originalValue) =>
      originalValue.replace(/\D/g, '').length
        ? originalValue.replace(/\D/g, '')
        : null,
    ),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  experience: Yup.number()
    .integer()
    .oneOf([
      UserExperience['Nível 1 e 2'],
      UserExperience['Nível 3 e 4'],
      UserExperience['Nível 5'],
      UserExperience['Nível 6'],
      UserExperience['Nível 6 e 7'],
      UserExperience['Nível 7'],
      UserExperience['Nível 8'],
      UserExperience.Especial,
    ])
    .required('Experiência requerida.'),
  healthCheck: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  name: Yup.string()
    .required('Nome requerido')
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'Nome inválido'),
  phone: Yup.string()
    .test('is-valid', 'Telefone inválido', (value) => validateBRPhone(value))
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  ventureId: Yup.string().uuid().required('Empreendimento requerido'),
});

const experienceSelectOptions = [
  {
    label: 'Nível 1 e 2',
    value: UserExperience['Nível 1 e 2'],
  },
  {
    label: 'Nível 3 e 4',
    value: UserExperience['Nível 3 e 4'],
  },
  {
    label: 'Nível 5',
    value: UserExperience['Nível 5'],
  },
  {
    label: 'Nível 6',
    value: UserExperience['Nível 6'],
  },
  {
    label: 'Nível 6 e 7',
    value: UserExperience['Nível 6 e 7'],
  },
  {
    label: 'Nível 7',
    value: UserExperience['Nível 7'],
  },
  {
    label: 'Nível 8',
    value: UserExperience['Nível 8'],
  },
  {
    label: 'Especial',
    value: UserExperience.Especial,
  },
];

export const MemberRegister = (): JSX.Element => {
  const { goBack, push } = useHistory();
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const [avatarStatus, setAvatarStatus] = useState<UserAvatarStatus>(
    UserAvatarStatus.EMPTY,
  );
  const faceIdStatus = UserFaceIdStatus.DISABLED;
  const [avatarChanged, setAvatarChanged] = useState<boolean>(true);
  const [isCheckingAvatar, setIsCheckingAvatar] = useState(false);
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [venturesSelectOptions, setVenturesSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [userCategoriesSelectOptions, setUserCategoriesSelectOptions] =
    useState<SelectOption[]>([]);

  const formMethods = useForm({
    resolver: yupResolver(registerMemberFormSchema),
  });

  const { register, handleSubmit, formState, control, setValue, watch } =
    formMethods;

  const { errors } = formState;

  useEffect(() => {
    async function loadVentures(): Promise<void> {
      const { items: ventures } = await listVenturesService();

      setVenturesSelectOptions(
        ventures.map((venture) => ({
          label: venture.name,
          value: venture.id,
        })),
      );
    }

    if (!authenticatedUser.ventureId) {
      loadVentures();
    } else {
      setValue('ventureId', authenticatedUser.ventureId);
    }
  }, [setValue, authenticatedUser.ventureId]);

  useEffect(() => {
    setValue('experience', UserExperience['Nível 1 e 2']);
    setValue('address', null);
  }, [setValue]);

  const selectedVentureId = watch('ventureId');

  useEffect(() => {
    async function loadUserCategories(ventureId: string): Promise<void> {
      const userCategories = await listUserCategoriesService(ventureId);

      setUserCategoriesSelectOptions(
        userCategories.map((userCategory) => ({
          label: userCategory.title,
          value: userCategory.id,
        })),
      );
    }

    if (selectedVentureId) {
      loadUserCategories(selectedVentureId);
    }
  }, [selectedVentureId]);

  const checkUserAvatar = useCallback(
    async (file: File) => {
      setIsCheckingAvatar(true);

      try {
        const formData = new FormData();
        formData.append('face', file);

        const { avatarStatus: uploadedAvatarStatus } =
          await checkUsersFaceService({
            faceData: formData,
          });

        setAvatarStatus(uploadedAvatarStatus);

        if (avatarStatus === UserAvatarStatus.VALID) {
          toast({
            title: 'Foto validada',
            description: 'A foto foi validada com sucesso',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } catch (err) {
        setAvatarStatus(UserAvatarStatus.NOT_VERIFIED);

        toast({
          title: 'Foto não validada',
          description: 'Ocorreu um erro ao validar a foto',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } finally {
        setAvatarChanged(true);
        setIsCheckingAvatar(false);
      }
    },
    [toast, avatarStatus],
  );

  const handleCheckUserAvatar = useCallback(async () => {
    if (avatar) {
      checkUserAvatar(avatar);
    }
  }, [checkUserAvatar, avatar]);

  const handleChangeAvatar = useCallback(
    (file: File) => {
      setAvatar(file);
      setAvatarUrl(URL.createObjectURL(file));
      checkUserAvatar(file);
    },
    [checkUserAvatar],
  );

  const handleDeleteAvatar = useCallback(() => {
    setAvatarChanged(true);
    setAvatarStatus(UserAvatarStatus.EMPTY);
    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, []);

  const handleNewMember: SubmitHandler<NewMemberFormData> = useCallback(
    async ({
      address,
      bio,
      birthDate,
      categoryId,
      cpf,
      email,
      experience,
      healthCheck,
      name,
      phone,
      ventureId,
    }) => {
      try {
        const member = await createUsersService({
          address,
          bio,
          birthDate,
          categoryId,
          cpf,
          email,
          experience,
          healthCheck,
          name,
          phone,
          featureGroupKey: 'MEMBER',
          ventureId,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);
          formData.append('faceStatus', avatarStatus);

          await updateUserAvatarsService({
            avatarData: formData,
            userId: member.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: 'O membro foi cadastrado corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        push('/members/details', { memberId: member.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao cadastrar o membro, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, push, toast, avatarStatus],
  );

  return (
    <DefaultLayout>
      <FormProvider {...formMethods}>
        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handleNewMember)}
        >
          <Heading size="lg" fontWeight="normal">
            Cadastrar membro
          </Heading>

          <Divider my="6" borderColor="gray.300" />

          <Flex direction="column" align="center" mb="8">
            <Box mb={6} alignSelf="flex-end">
              <FaceIdStatusTag
                avatarChanged={avatarChanged}
                avatarStatus={avatarStatus}
                faceIdStatus={faceIdStatus}
              />
            </Box>

            <AvatarDropzone
              avatarUrl={avatarUrl}
              onChange={handleChangeAvatar}
              onDelete={handleDeleteAvatar}
            />

            <Box mt={6}>
              <AvatarStatusCard avatarStatus={avatarStatus} />
            </Box>

            {(avatarStatus === UserAvatarStatus.NOT_VERIFIED ||
              isCheckingAvatar) && (
              <Box mt={3}>
                <Button
                  isDisabled={!avatar}
                  isLoading={isCheckingAvatar}
                  loadingText="Validando foto..."
                  colorScheme="blue"
                  onClick={handleCheckUserAvatar}
                >
                  Validar foto
                </Button>
              </Box>
            )}
          </Flex>

          <VStack spacing="8">
            <MaskedInput
              label="Nome completo"
              error={errors.name}
              {...register('name')}
            />

            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
                'repeat(12, 1fr)',
                'repeat(12, 1fr)',
              ]}
              columnGap="4"
              rowGap="8"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 6]}>
                <MaskedInput
                  label="CPF"
                  mask="cpf"
                  error={errors.cpf}
                  {...register('cpf')}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 6]}>
                <DatePicker
                  label="Data de nascimento"
                  isClearable={false}
                  maxDate={new Date()}
                  control={control}
                  error={errors.birthDate}
                  {...register('birthDate')}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 6]}>
                <InternationalPhoneInput
                  label="Telefone"
                  name="phone"
                  control={control}
                  error={errors.phone}
                />
              </GridItem>

              <GridItem colSpan={[12, 12, 6]}>
                <MaskedInput
                  label="E-mail"
                  type="email"
                  textTransform="lowercase"
                  error={errors.email}
                  {...register('email')}
                />
              </GridItem>

              <GridItem
                colSpan={[
                  12,
                  12,
                  6,
                  6,
                  6,
                  !authenticatedUser.ventureId ? 6 : 4,
                ]}
              >
                <DatePicker
                  label="Exame médico"
                  maxDate={new Date()}
                  control={control}
                  error={errors.healthCheck}
                  {...register('healthCheck')}
                />
              </GridItem>

              {!authenticatedUser.ventureId && (
                <GridItem colSpan={[12, 12, 6, 6, 6]}>
                  <ReactSelect
                    label="Empreendimento"
                    name="ventureId"
                    options={venturesSelectOptions}
                    control={control}
                    error={errors.ventureId}
                  />
                </GridItem>
              )}

              <GridItem
                colSpan={[
                  12,
                  12,
                  6,
                  6,
                  6,
                  !authenticatedUser.ventureId ? 6 : 4,
                ]}
              >
                <ReactSelect
                  label="Nível de experiência"
                  name="experience"
                  options={experienceSelectOptions}
                  control={control}
                  error={errors.experience}
                />
              </GridItem>

              <GridItem
                colSpan={[
                  12,
                  12,
                  !authenticatedUser.ventureId ? 6 : 12,
                  !authenticatedUser.ventureId ? 6 : 12,
                  !authenticatedUser.ventureId ? 6 : 12,
                  !authenticatedUser.ventureId ? 6 : 4,
                ]}
              >
                <ReactSelect
                  label="Categoria"
                  emptyOptionsMessage={
                    !selectedVentureId && 'Selecione um empreendimento antes'
                  }
                  name="categoryId"
                  options={userCategoriesSelectOptions}
                  control={control}
                  error={errors.categoryId}
                />
              </GridItem>
            </Grid>

            <MaskedInput
              label="Biografia"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.bio}
              {...register('bio')}
            />
          </VStack>

          <Divider my="6" borderColor="gray.300" />

          <AddressForm />

          <Flex mt="12" justify="flex-end">
            <ButtonGroup>
              <Button colorScheme="blackAlpha" onClick={goBack}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="green"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </Flex>
        </Box>
      </FormProvider>
    </DefaultLayout>
  );
};
