import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  SimpleGrid,
} from '@chakra-ui/react';

import axios from 'axios';

import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import {
  IDetailedActivity,
  showActivitiesService,
} from '../../../../../services/Activities/ShowActivitiesService';
import { updateActivitiesService } from '../../../../../services/Activities/UpdateActivitiesService';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { translateError } from '../../../../../utils/errors';
import { updateActivityAvatarsService } from '../../../../../services/Activities/UpdateActivityAvatarsService';
import deleteActivityAvatarsService from '../../../../../services/Activities/DeleteActivityAvatarsService';
import { listSpotsService } from '../../../../../services/Spots/ListSpotsService';
import {
  ReactSelect,
  SelectOption,
} from '../../../../../components/Form/ReactSelect';
import { Switch } from '../../../../../components/Form/Switch';
import {
  maskMoney,
  unmaskNumber,
} from '../../../../../utils/formatters/handleMask';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';

type UpdateActivityFormData = {
  bookingPrice?: number;
  description?: string;
  isActive: boolean;
  name: string;
  spotId: string;
};

interface ILocationState {
  activityId: string;
}

const activityUpdateFormSchema = Yup.object().shape({
  bookingPrice: Yup.number()
    .integer('Valores inteiros')
    .positive('Valores maiores que zero')
    .nullable()
    .transform((_, originalValue) =>
      originalValue === '' ? null : unmaskNumber(originalValue),
    ),
  description: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  isActive: Yup.boolean().default(true),
  name: Yup.string().required('Requerido'),
  spotId: Yup.string().uuid().required('Requerido'),
});

export const ActivityUpdate = (): JSX.Element => {
  const { goBack } = useHistory();
  const toast = useToast();

  const { state } = useLocation<ILocationState>();

  const formRef = useRef<HTMLDivElement & HTMLFormElement>(null);

  const [activity, setActivity] = useState<IDetailedActivity>();
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [spotsSelectOptions, setSpotsSelectOptions] = useState<SelectOption[]>(
    [],
  );
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(activityUpdateFormSchema),
  });

  const { errors } = formState;
  const { activityId } = state;

  useEffect(() => {
    async function loadSpots(): Promise<void> {
      const { items: spots } = await listSpotsService({ isActive: true });

      setSpotsSelectOptions(
        spots.map((spot) => ({
          label: spot.name,
          value: spot.id,
        })),
      );
    }

    loadSpots();
  }, []);

  useEffect(() => {
    async function loadActivity(): Promise<void> {
      try {
        const activityData = await showActivitiesService(activityId);
        setAvatarUrl(activityData.avatarUrl || undefined);

        setActivity(activityData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados da atividade, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadActivity();
  }, [activityId, toast]);

  useEffect(() => {
    if (activity) {
      reset({
        bookingPrice: activity.bookingPrice
          ? maskMoney(activity.bookingPrice)
          : undefined,
        description: activity.description,
        isActive: activity.isActive,
        name: activity.name,
        spotId: activity.spotId,
      });
    }
  }, [reset, activity]);

  const handleChangeAvatar = useCallback((file: File) => {
    setAvatar(file);
    setAvatarUrl(URL.createObjectURL(file));
  }, []);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteAvatar = useCallback(async () => {
    await deleteActivityAvatarsService(activityId);

    setAvatar(undefined);
    setAvatarUrl(undefined);
    handleToggleDeleteConfirmationModal();
  }, [activityId, handleToggleDeleteConfirmationModal]);

  const handleUpdateActivity: SubmitHandler<UpdateActivityFormData> =
    useCallback(
      async ({ bookingPrice, description, isActive, name, spotId }) => {
        try {
          await updateActivitiesService({
            activityId,
            bookingPrice,
            description,
            isActive,
            name,
            spotId,
            isVerified,
          });

          if (avatar) {
            const formData = new FormData();

            formData.append('avatar', avatar);

            await updateActivityAvatarsService({
              activityId,
              avatarData: formData,
            });
          }

          toast({
            title: 'Editado com sucesso',
            description: 'A atividade foi editada corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          goBack();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            if (err.response?.data.message === 'has-bookings-pending') {
              handleToggleVerifyConfirmationModal();

              return;
            }

            toast({
              title: 'Falha ao editar',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao editar a atividade, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [
        activityId,
        isVerified,
        avatar,
        toast,
        goBack,
        handleToggleVerifyConfirmationModal,
      ],
    );

  const handleVerify = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (isVerified) {
      formRef.current?.requestSubmit();
    }
  }, [handleSubmit, handleUpdateActivity, isVerified]);

  return (
    <DefaultLayout>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteAvatar}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={handleToggleVerifyConfirmationModal}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar ação?"
      />

      <Box
        ref={formRef}
        as="form"
        flex="1"
        borderRadius={8}
        bg="white"
        p="8"
        onSubmit={handleSubmit(handleUpdateActivity)}
      >
        <Heading size="lg" fontWeight="normal">
          Editar atividade
        </Heading>

        <Divider my="6" borderColor="gray.300" />

        <Flex justify="center" mb="8">
          <AvatarDropzone
            avatarUrl={avatarUrl}
            onChange={handleChangeAvatar}
            onDelete={handleToggleDeleteConfirmationModal}
          />
        </Flex>

        <VStack spacing="8">
          <MaskedInput
            label="Título"
            error={errors.name}
            {...register('name')}
          />

          <SimpleGrid minChildWidth="240px" spacing="8" w="100%">
            <ReactSelect
              label="Local"
              name="spotId"
              options={spotsSelectOptions}
              control={control}
              error={errors.spotId}
            />

            <MaskedInput
              label="Valor da reserva"
              mask="money"
              error={errors.bookingPrice}
              {...register('bookingPrice')}
            />
          </SimpleGrid>

          <MaskedInput
            label="Descrição"
            as="textarea"
            minHeight="160px"
            resize="none"
            py="2"
            error={errors.description}
            {...register('description')}
          />

          {!!activity && (
            <Box>
              <Switch
                label="Ativo"
                error={errors.isActive}
                {...register('isActive')}
              />
            </Box>
          )}
        </VStack>

        <Flex mt="12" justify="flex-end">
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={goBack}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="green"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </Flex>
      </Box>
    </DefaultLayout>
  );
};
