import {
  Box,
  BoxProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useUser } from '../../../../../../../../../hooks/user';
import { MemberBookingTable } from '../../../../../../components/MemberBookingTable';
import { MemberDependantTable } from './components/MemberDependantTable';
import { MemberGuestTable } from './components/MemberGuestTable';

export const MemberDetailsTabs = (props: BoxProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);

  const { user: member } = useUser();

  const handleTabChanges = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  return (
    <Box {...props}>
      <Tabs
        index={tabIndex}
        isLazy
        onChange={handleTabChanges}
        variant="enclosed-colored"
      >
        <TabList>
          <Tab>Reservas</Tab>

          {member?.featureGroup?.key.includes('MEMBER') && (
            <Tab>Dependentes</Tab>
          )}

          <Tab>Convidados</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <MemberBookingTable />
          </TabPanel>

          {member?.featureGroup?.key.includes('MEMBER') && (
            <TabPanel>
              <MemberDependantTable />
            </TabPanel>
          )}

          <TabPanel>
            <MemberGuestTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
