import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import { IDetailedBooking } from './ShowBookingsService';

interface IUpdateBookingItemsProps {
  bookingId: string;
  itemsId?: string[];
  scheduleItemsId?: string[];
}

export type IUpdatedBooking = IDetailedBooking;

export const updateBookingItemsService = async ({
  bookingId,
  itemsId,
  scheduleItemsId,
}: IUpdateBookingItemsProps): Promise<IUpdatedBooking> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedBooking>(
    `/bookings/${bookingId}/items`,
    {
      itemsId,
      scheduleItemsId,
    },
  );

  return data;
};
