import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Flex,
  Button,
  Text,
  Link as ChakraLink,
  VStack,
} from '@chakra-ui/react';

import { useAuth } from '../../../hooks/auth';
import { MaskedInput } from '../../../components/Form/MaskedInput';

type SignInFormData = {
  email: string;
  password: string;
};

const signInFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  password: Yup.string().required('Senha requerida'),
});

export const SignIn = (): JSX.Element => {
  const { signIn } = useAuth();
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(signInFormSchema),
  });

  const { errors } = formState;

  const handleSignIn: SubmitHandler<SignInFormData> = useCallback(
    async (data) => {
      await signIn({ email: data.email, password: data.password });
    },
    [signIn],
  );

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        as="form"
        width="100%"
        maxWidth={360}
        bg="white"
        p="8"
        borderRadius={8}
        flexDir="column"
        onSubmit={handleSubmit(handleSignIn)}
      >
        <Text fontSize="1.75rem" fontWeight="bold" letterSpacing="tight">
          JHSF-BOOKINGS
          <Text as="span" ml="1" color="blue.500">
            .
          </Text>
        </Text>

        <VStack spacing="8" mt="10">
          <MaskedInput
            label="E-mail"
            type="email"
            textTransform="lowercase"
            error={errors.email}
            {...register('email')}
          />

          <MaskedInput
            label="Senha"
            type="password"
            error={errors.password}
            {...register('password')}
          />
        </VStack>

        <Button
          type="submit"
          isLoading={formState.isSubmitting}
          mt="8"
          colorScheme="green"
          size="lg"
        >
          Entrar
        </Button>

        <ChakraLink as={Link} to="/users/passwords/forgot" mt="2" mx="auto">
          Esqueci minha senha
        </ChakraLink>
      </Flex>
    </Flex>
  );
};
