const styles = {
  global: {
    body: {
      bg: 'gray.100',
      color: 'gray.700',
    },
  },
};

export default styles;
