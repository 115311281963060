import {
  Box,
  BoxProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { ActivityBookings } from './components/ActivityBookings';
import { ActivityCalendar } from './components/ActivityCalendar';
import { ActivitySchedules } from './components/ActivitySchedules';
import { ActivityItems } from './components/ActivityItems';

export const ActivityDetailsTabs = (props: BoxProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChanges = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  return (
    <Box {...props}>
      <Tabs
        index={tabIndex}
        isLazy
        onChange={handleTabChanges}
        variant="enclosed-colored"
      >
        <TabList>
          <Tab>Agenda</Tab>
          <Tab>Horários dos eventos</Tab>
          <Tab>Opcionais disponíveis</Tab>
          <Tab>Reservas</Tab>
        </TabList>

        <TabPanels mt="4">
          <TabPanel>
            <ActivityCalendar />
          </TabPanel>

          <TabPanel>
            <ActivitySchedules />
          </TabPanel>

          <TabPanel>
            <ActivityItems />
          </TabPanel>

          <TabPanel>
            <ActivityBookings />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
