import {
  HStack,
  CircularProgress,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { MaskedInput } from '../MaskedInput';

interface IZipCodeInputProps extends ChakraInputProps {
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  loadingCep: boolean;
}

const ZipCodeInput = ({
  errors,
  register,
  loadingCep,
  onChange,
}: IZipCodeInputProps): JSX.Element => {
  const {
    onChange: formOnChange,
    name,
    onBlur,
    ref,
  } = register('address.zipCode');

  return (
    <HStack>
      <MaskedInput
        label="Código postal"
        mask="cep"
        error={errors.address?.zipCode}
        onChange={(event) => {
          if (onChange) {
            onChange(event);
          }

          return formOnChange(event);
        }}
        name={name}
        onBlur={onBlur}
        ref={ref}
      />

      {loadingCep && (
        <CircularProgress
          isIndeterminate
          color="teal"
          size="2rem"
          alignSelf="end"
          pb="10px"
        />
      )}
    </HStack>
  );
};

export default ZipCodeInput;
