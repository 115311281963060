import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import { ISpotModalityBase } from '../../models/spots';

interface IUpdateSpotModalityProps {
  modalityId: string;
  description?: string;
  isActive?: boolean;
  isVerified?: boolean;
  title: string;
}

export type IUpdatedSpotModality = ISpotModalityBase;

export const updateSpotModalitiesService = async ({
  modalityId,
  description,
  isActive,
  isVerified,
  title,
}: IUpdateSpotModalityProps): Promise<IUpdatedSpotModality> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedSpotModality>(
    `/spots/modalities/${modalityId}`,
    {
      description,
      isActive,
      title,
    },
    { params: { isVerified } },
  );

  return data;
};
