import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { MaskedInput } from '../../../../../../../components/Form/MaskedInput';
import { IFeatureBase } from '../../../../../../../models/features';
import {
  createFeaturesService,
  INewFeature,
} from '../../../../../../../services/Features/CreateFeaturesService';
import {
  IUpdatedFeature,
  updateFeaturesService,
} from '../../../../../../../services/Features/UpdateFeaturesService';
import { translateError } from '../../../../../../../utils/errors';

interface IHandleFeatureModalFormData {
  key: string;
  name: string;
}

interface IHandleFeatureModalProps {
  feature?: IFeatureBase;
  isOpen: boolean;
  onClose: () => void;
  onSave: (handledFeature: INewFeature | IUpdatedFeature) => void;
}

const handleFeatureModalFormSchema = Yup.object().shape({
  key: Yup.string().required('Requerido').uppercase(),
  name: Yup.string().required('Requerido'),
});

export const HandleFeatureModal = ({
  feature,
  isOpen,
  onClose,
  onSave,
}: IHandleFeatureModalProps): JSX.Element => {
  const toast = useToast();
  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(handleFeatureModalFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    if (feature) {
      reset({ key: feature.key, name: feature.name });
    }
  }, [feature, reset]);

  const handleFeatureSubmit: SubmitHandler<IHandleFeatureModalFormData> =
    useCallback(
      async ({ key, name }) => {
        if (!feature) {
          try {
            const newFeature = await createFeaturesService({
              key,
              name,
            });

            reset({});

            onSave(newFeature);

            toast({
              title: 'Cadastrado com sucesso',
              description: 'Permissão cadastrada corretamente.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha ao cadastrar',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao cadastrar a permissão, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        } else {
          try {
            const updatedFeature = await updateFeaturesService({
              key,
              featureId: feature.id,
              name,
            });

            reset({});

            onSave(updatedFeature);

            toast({
              title: 'Atualizado com sucesso',
              description: 'Permissão atualizada corretamente.',
              status: 'success',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status !== 401) {
              toast({
                title: 'Falha ao atualizar',
                description:
                  translateError({ message: err.response?.data.message }) ||
                  'Ocorreu um erro ao atualizar a permissão, tente novamente.',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            }
          }
        }
      },
      [feature, onSave, reset, toast],
    );

  const handleCloseModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleFeatureSubmit)}>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Permissão
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="4">
            <MaskedInput
              label="Nome"
              type="name"
              error={errors.name}
              {...register('name')}
            />

            <MaskedInput
              label="Chave"
              type="key"
              textTransform="uppercase"
              error={errors.key}
              {...register('key')}
            />
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
