import { Flex, FlexProps } from '@chakra-ui/react';
import { SpotDetailsTabs } from './components/SpotDetailsTabs';

const SpotDetailsData = (props: FlexProps): JSX.Element => (
  <Flex
    direction="column"
    w="100%"
    borderRadius={8}
    bg="white"
    p="8"
    {...props}
  >
    <SpotDetailsTabs />
  </Flex>
);

export default SpotDetailsData;
