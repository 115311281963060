import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  HStack,
  CircularProgress,
  ButtonGroup,
  IconButton,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { RiBookmark3Line, RiEyeLine, RiStarLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../../../../../../../../../components/LinkButton';
import {
  IActivityItemBase,
  IActivityScheduleBase,
} from '../../../../../../../../../../../../../../../models/activities';
import { UserExperience } from '../../../../../../../../../../../../../../../models/users';
import {
  IBookingQueue,
  IDetailedBookingQueueActivityScheduleItem,
  listBookingQueuesService,
} from '../../../../../../../../../../../../../../../services/BookingQueues/ListBookingQueuesService';
import { updateBookingQueuesPriorityService } from '../../../../../../../../../../../../../../../services/BookingQueues/UpdateBookingQueuesPriorityService';
import {
  maskDateTime,
  maskMoney,
  maskShortDate,
} from '../../../../../../../../../../../../../../../utils/formatters/handleMask';
import { translateError } from '../../../../../../../../../../../../../../../utils/errors';
import { HandleBookingQueueModal } from '../../../../../../../../../../../../../../../components/HandleBookingQueueModal';
import {
  IDetailedActivity,
  IDetailedActivitySpot,
} from '../../../../../../../../../../../../../../../services/Activities/ShowActivitiesService';

interface IBookingQueueTableItemActivitySchedule extends IActivityScheduleBase {
  formattedEndTime: string;
  formattedStartTime: string;
}

interface IBookingTableScheduleItem
  extends IDetailedBookingQueueActivityScheduleItem {
  formattedPrice: string;
}

type IBookingQueueTableItemSpot = IDetailedActivitySpot;

interface IBookingQueueTableItemActivity extends IDetailedActivity {
  formattedCreatedAt: string;
  spot: IBookingQueueTableItemSpot;
}

export interface IBookingQueueTableItem extends IBookingQueue {
  activitySchedule: IBookingQueueTableItemActivitySchedule;
  formattedBookedDate: string;
  formattedCreatedAt: string;
  scheduleItems: IBookingTableScheduleItem[];
  items: IActivityItemBase[];
  activity: IBookingQueueTableItemActivity;
}

interface IBookingQueueTableProps extends BoxProps {
  activityScheduleId: string;
  bookedDate: Date;
}

export const BookingQueueTable = ({
  activityScheduleId,
  bookedDate,
  ...rest
}: IBookingQueueTableProps): JSX.Element => {
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [isBookingRegisterModalVisible, setIsBookingRegisterModalVisible] =
    useState(false);
  const [bookingQueues, setBookingQueues] = useState<IBookingQueueTableItem[]>(
    [],
  );
  const [selectedBookingQueue, setSelectedBookingQueue] =
    useState<IBookingQueueTableItem>();

  const loadBookingQueues = useCallback(async (): Promise<void> => {
    setLoading(true);

    try {
      const bookingQueuesResult = await listBookingQueuesService({
        activityScheduleId,
        bookedDate: bookedDate.toLocaleDateString('fr-CA'),
        showQueuedBookingsOnly: true,
      });

      const parsedBookingQueues = bookingQueuesResult.map((bookingQueue) => {
        const [bookedYear, bookedMonth, bookedDay] =
          bookingQueue.bookedDate.split('-');

        return {
          ...bookingQueue,
          formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
          formattedCreatedAt: maskDateTime(bookingQueue.createdAt),
          activitySchedule: {
            ...bookingQueue.activitySchedule,
            formattedEndTime: bookingQueue.activitySchedule.endTime.slice(0, 5),
            formattedStartTime: bookingQueue.activitySchedule.startTime.slice(
              0,
              5,
            ),
          },
          scheduleItems: bookingQueue.scheduleItems.map((scheduleItem) => ({
            ...scheduleItem,
            formattedPrice: scheduleItem.price
              ? maskMoney(scheduleItem.price)
              : '-',
          })),
          activity: {
            ...bookingQueue.activity,
            formattedCreatedAt: maskShortDate(bookingQueue.activity.createdAt),
          },
          items: bookingQueue.items ?? [],
        };
      });

      setBookingQueues(parsedBookingQueues);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status !== 401) {
        toast({
          title: 'Falha ao carregar dados',
          description:
            translateError({ message: err.response?.data.message }) ||
            'Ocorreu um erro ao carregar os dados da fila de espera, tente novamente.',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [activityScheduleId, bookedDate, toast]);

  const handlePriority = async (bookingQueueId: string): Promise<void> => {
    await updateBookingQueuesPriorityService({
      bookingQueueId,
    });

    loadBookingQueues();
  };

  const handleDispatchQueue = (bookingQueue: IBookingQueueTableItem): void => {
    setSelectedBookingQueue(bookingQueue);
    setIsBookingRegisterModalVisible(true);
  };

  useEffect(() => {
    loadBookingQueues();
  }, [loadBookingQueues, activityScheduleId, bookedDate]);

  return (
    <Box {...rest}>
      {!!selectedBookingQueue && (
        <HandleBookingQueueModal
          activity={selectedBookingQueue.activity}
          bookingQueue={selectedBookingQueue}
          isOpen={isBookingRegisterModalVisible}
          isBookSubmiting
          onClose={() => {
            setSelectedBookingQueue(undefined);
            setIsBookingRegisterModalVisible(false);
          }}
        />
      )}

      {bookingQueues.length ? (
        <Table colorScheme="blue" mt="4">
          <Thead>
            <Tr>
              <Th>Membro</Th>
              <Th>Nível de experiência</Th>
              <Th textAlign="center">Data/horário da reserva</Th>
              <Th textAlign="center">Reservado em</Th>
              <Th textAlign="right" w="8" />
            </Tr>
          </Thead>

          <Tbody>
            {bookingQueues.map((item) => (
              <Tr key={item.id}>
                <Td>
                  <Text fontWeight="bold">{item.user.name}</Text>

                  <Text textColor="gray.400">
                    {item.user.featureGroup?.name}
                  </Text>

                  <Text textColor="gray.400" fontSize="sm">
                    {item.user.host?.name}
                  </Text>
                </Td>

                <Td>{UserExperience[item.user.experience]}</Td>

                <Td textAlign="center">
                  <Box>
                    <Text>{item.formattedBookedDate}</Text>
                    {item.activitySchedule ? (
                      <Text>{`${item.activitySchedule.formattedStartTime} - ${item.activitySchedule.formattedEndTime}`}</Text>
                    ) : (
                      <Text color="red.500">Sem horário</Text>
                    )}
                  </Box>
                </Td>

                <Td textAlign="center">
                  <Text>{item.formattedCreatedAt.split('-')[0]}</Text>
                  <Text>{item.formattedCreatedAt.split('-')[1]}</Text>
                </Td>

                <Td textAlign="right">
                  <ButtonGroup>
                    <IconButton
                      aria-label="Reservar"
                      size="sm"
                      colorScheme="green"
                      icon={<Icon as={RiBookmark3Line} fontSize="18" />}
                      onClick={() => {
                        handleDispatchQueue(item);
                      }}
                    />

                    <IconButton
                      aria-label="Priority"
                      size="sm"
                      icon={<Icon as={RiStarLine} fontSize="18" />}
                      fontSize="16"
                      colorScheme={!item.priorizedAt ? 'blackAlpha' : 'yellow'}
                      onClick={() => handlePriority(item.id)}
                    />

                    <LinkButton
                      px={0}
                      to={{
                        pathname: '/booking-queues/details',
                        state: {
                          bookingQueueId: item.id,
                        },
                      }}
                    >
                      <Icon as={RiEyeLine} fontSize="16" />
                    </LinkButton>
                  </ButtonGroup>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Flex w="full" h="24" justify="center" align="center">
          {loading ? (
            <HStack spacing="4">
              <CircularProgress isIndeterminate color="teal" size="2rem" />
              <Text color="gray.600">Carregando...</Text>
            </HStack>
          ) : (
            <Text color="gray.600">Nenhuma reserva na fila para esse dia</Text>
          )}
        </Flex>
      )}
    </Box>
  );
};
