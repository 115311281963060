import { useHistory } from 'react-router-dom';
import {
  Heading,
  Flex,
  Button,
  Icon,
  Text,
  useToast,
  ButtonGroup,
  HStack,
  VStack,
  Divider,
  useBreakpointValue,
  FlexProps,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import {
  RiBookmark3Line,
  RiBookmarkLine,
  RiDeleteBinLine,
  RiEditLine,
  RiEyeLine,
} from 'react-icons/ri';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { translateError } from '../../../../../../../utils/errors';
import { LinkButton } from '../../../../../../../components/LinkButton';
import { useAuth } from '../../../../../../../hooks/auth';
import { deleteBookingQueuesService } from '../../../../../../../services/BookingQueues/DeleteBookingQueuesService';
import { useBookingQueue } from '../../../../../../../hooks/bookingQueue';
import BookingQueueDetailsUserInfo from './components/BookingQueueDetailsUserInfo';
import { HandleBookingQueueModal } from '../../../../../../../components/HandleBookingQueueModal';
import { useActivity } from '../../../../../../../hooks/activity';
import { showActivitiesService } from '../../../../../../../services/Activities/ShowActivitiesService';
import { ConfirmationModal } from '../../../../../../../components/ConfirmationModal';

export const BookingQueueDetailsInfo = (props: FlexProps): JSX.Element => {
  const toast = useToast();

  const { activity, handleActivity } = useActivity();
  const { goBack } = useHistory();

  const { user: authenticatedUser } = useAuth();

  const { bookingQueue } = useBookingQueue();

  const dividerOrientation = useBreakpointValue({
    base: 'horizontal',
    xl: 'vertical',
  });

  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);

  const [
    isHandleBookingQueueModalVisible,
    setIsHandleBookingQueueModalVisible,
  ] = useState(false);

  const [isBookSubmiting, setIsBookSubmiting] = useState(false);

  useEffect(() => {
    async function loadActivity(activityId: string): Promise<void> {
      try {
        const activityData = await showActivitiesService(activityId);

        handleActivity(activityData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes da atividade, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    if (bookingQueue) {
      loadActivity(bookingQueue.activityId);
    }

    return () => handleActivity(undefined);
  }, [bookingQueue, handleActivity, toast]);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleBookingQueueModal = useCallback(() => {
    setIsHandleBookingQueueModalVisible((prevState) => !prevState);
  }, []);

  const handleDeleteBookingQueue = useCallback(async () => {
    if (bookingQueue?.id) {
      try {
        await deleteBookingQueuesService(bookingQueue.id);

        toast({
          title: 'Cancelada com sucesso',
          description: 'A reserva foi cancelada corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        goBack();
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao excluir',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao excluir a reserva na fila, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }
  }, [bookingQueue?.id, goBack, toast]);

  const handleBookingSubmit = (): void => {
    setIsBookSubmiting(true);

    handleToggleHandleBookingQueueModal();
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isDeleteConfirmationModalVisible}
        onClose={handleToggleDeleteConfirmationModal}
        onConfirm={handleDeleteBookingQueue}
        title="Confirmar exclusão"
        message="Deseja realmente excluir?"
      />

      {!!bookingQueue && !!activity && (
        <HandleBookingQueueModal
          activity={activity}
          bookingQueue={bookingQueue}
          isBookSubmiting={isBookSubmiting}
          isOpen={isHandleBookingQueueModalVisible}
          onClose={handleToggleHandleBookingQueueModal}
        />
      )}

      <Flex
        direction="column"
        w="100%"
        borderRadius={8}
        bg="white"
        p="8"
        {...props}
      >
        <Flex justify="space-between" align="center">
          <Heading size="lg" fontWeight="normal">
            Detalhes da reserva na fila
          </Heading>
        </Flex>

        {bookingQueue && (
          <Flex direction="column" mt="8">
            <Grid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(1, 1fr)',
                'repeat(12, 1fr)',
              ]}
              gap="4"
              width="100%"
            >
              <GridItem colSpan={[12, 12, 12, 12, 8]}>
                <Flex direction="column" px="8">
                  <VStack flex="1" alignItems="flex-start" pl="8">
                    <Text fontSize="4xl" fontWeight="bold">
                      {bookingQueue.activity.name}
                    </Text>

                    <HStack>
                      <Text>Local:</Text>
                      <Text color="gray.500">
                        {bookingQueue.activity.spot.name}
                      </Text>
                    </HStack>

                    <HStack>
                      <Text>Data/horário da reserva:</Text>
                      <Text color="gray.500">
                        {bookingQueue.formattedBookedDate}
                      </Text>
                      <Text
                        color={
                          bookingQueue.activitySchedule ? 'gray.500' : 'red.500'
                        }
                      >
                        {`${
                          bookingQueue.activitySchedule
                            ? `${bookingQueue.activitySchedule.formattedStartTime} - ${bookingQueue.activitySchedule.formattedEndTime}`
                            : 'Sem horário'
                        }`}
                      </Text>
                    </HStack>

                    {(!!bookingQueue.activitySchedule?.modality ||
                      !!bookingQueue.modalities.length) && (
                      <HStack>
                        <Text>Modalidades:</Text>
                        {bookingQueue.activitySchedule?.modality ? (
                          <Text color="gray.500">
                            {bookingQueue.activitySchedule.modality.title}
                          </Text>
                        ) : (
                          bookingQueue.modalities.map((modality) => (
                            <Text key={modality.id} color="gray.500">
                              {`${modality.title};`}
                            </Text>
                          ))
                        )}
                      </HStack>
                    )}

                    <HStack>
                      <Text>Reservado em:</Text>
                      <Text color="gray.500">
                        {bookingQueue.formattedCreatedAt}
                      </Text>
                    </HStack>
                  </VStack>

                  <ButtonGroup mt="8" pl="8">
                    <LinkButton
                      icon={<Icon as={RiEyeLine} fontSize="20" />}
                      to={{
                        pathname: '/activities/details',
                        state: {
                          activityId: bookingQueue.activityId,
                        },
                      }}
                    >
                      Atividade
                    </LinkButton>

                    <LinkButton
                      icon={<Icon as={RiEyeLine} fontSize="20" />}
                      to={{
                        pathname: '/spots/details',
                        state: {
                          spotId: bookingQueue.activity.spotId,
                        },
                      }}
                    >
                      Local
                    </LinkButton>

                    {!bookingQueue.isExpired &&
                      authenticatedUser.featureGroup.features.some((feature) =>
                        [
                          'BOOKING_QUEUE_FULL_ACCESS',
                          'BOOKING_QUEUE_WRITE_ACCESS',
                        ].includes(feature.key),
                      ) && (
                        <Button
                          colorScheme="yellow"
                          color="white"
                          size="sm"
                          leftIcon={<Icon as={RiEditLine} fontSize="16" />}
                          onClick={handleToggleHandleBookingQueueModal}
                        >
                          Editar
                        </Button>
                      )}

                    {!bookingQueue.isExpired &&
                      authenticatedUser.featureGroup.features.some((feature) =>
                        [
                          'BOOKING_FULL_ACCESS',
                          'BOOKING_WRITE_ACCESS',
                        ].includes(feature.key),
                      ) && (
                        <Button
                          size="sm"
                          fontSize="sm"
                          colorScheme="green"
                          onClick={handleBookingSubmit}
                          leftIcon={<Icon as={RiBookmark3Line} fontSize="20" />}
                        >
                          Reservar
                        </Button>
                      )}

                    {authenticatedUser.featureGroup.features.some((feature) =>
                      ['BOOKING_FULL_ACCESS', 'BOOKING_DELETE_ACCESS'].includes(
                        feature.key,
                      ),
                    ) && (
                      <Button
                        size="sm"
                        fontSize="sm"
                        colorScheme="red"
                        onClick={handleToggleDeleteConfirmationModal}
                        leftIcon={<Icon as={RiDeleteBinLine} fontSize="20" />}
                      >
                        Excluir
                      </Button>
                    )}
                  </ButtonGroup>
                </Flex>
              </GridItem>

              {bookingQueue.description && (
                <GridItem colSpan={[12, 12, 12, 12, 4]}>
                  <Flex height="100%">
                    <Divider
                      hidden={dividerOrientation === 'horizontal'}
                      px="2"
                      height="80%"
                      borderColor="gray.300"
                      orientation="vertical"
                    />

                    <Flex flexDirection="column" w="100%">
                      <Divider
                        hidden={dividerOrientation === 'vertical'}
                        borderColor="gray.300"
                        mt="4"
                        mb="8"
                      />
                      <Flex
                        flexDirection="column"
                        px={[8, 8, 8, 8, 0]}
                        w="100%"
                      >
                        <HStack mt={[0, 0, 0, 0, 2]}>
                          <Icon as={RiBookmarkLine} fontSize="20" />
                          <Text>Biografia</Text>
                        </HStack>

                        <Text
                          fontSize="sm"
                          color="gray.500"
                          mt="4"
                          px={[4, 4, 4, 4, 0]}
                        >
                          {bookingQueue.description}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </GridItem>
              )}
            </Grid>

            <Divider borderColor="gray.300" my="8" />

            <BookingQueueDetailsUserInfo user={bookingQueue.user} />
          </Flex>
        )}
      </Flex>
    </>
  );
};
