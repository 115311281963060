import { Box, useToast } from '@chakra-ui/react';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { listBookingsService } from '../../../../../../../../../../../services/Bookings/ListBookingsService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { maskDateTime } from '../../../../../../../../../../../utils/formatters/handleMask';
import { useActivity } from '../../../../../../../../../../../hooks/activity';
import {
  BookingTable,
  IBookingTableItem,
} from '../../../../../../../../../../../components/BookingTable';
import { createBookingExternalPaymentsService } from '../../../../../../../../../../../services/Bookings/CreateBookingExternalPaymentsService';

interface ILoadBookingsProps {
  activityId: string;
  spotName?: string;
  showPendingBookingsOnly?: boolean;
  userDocument?: string;
  userName?: string;
  sort?: 'bookedDate' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const ActivityBookings = (): JSX.Element => {
  const toast = useToast();
  const { activity } = useActivity();

  const [bookingsList, setBookingsList] = useState<IBookingTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('bookedDate');
  const [showPending, setShowPending] = useState(true);
  const [sortBy, setSortBy] = useState<'bookedDate' | 'createdAt'>(
    'bookedDate',
  );
  const [totalPages, setTotalPages] = useState<number>();

  const loadBookings = useCallback(
    async ({
      activityId,
      spotName,
      userDocument,
      userName,
      showPendingBookingsOnly,
      order,
      page,
      sort = 'bookedDate',
    }: ILoadBookingsProps) => {
      try {
        const bookings = await listBookingsService({
          activityId,
          spotName,
          userDocument: userDocument?.replace(/\D/g, ''),
          userName,
          showPendingBookingsOnly,
          limit: 20,
          page,
          sort,
          order,
        });

        const parsedBookings = bookings.items.map((booking) => {
          const [bookedYear, bookedMonth, bookedDay] =
            booking.bookedDate.split('-');

          return {
            ...booking,
            checkins: booking.checkins.map((checkin) => ({
              ...checkin,
              formattedCreatedAt: maskDateTime(checkin.createdAt),
            })),
            formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
            formattedCreatedAt: maskDateTime(booking.createdAt),
            formattedDeletedAt: booking.deletedAt
              ? maskDateTime(booking.deletedAt)
              : undefined,
            formattedEndTime: booking.endTime
              ? booking.endTime.slice(11, 16)
              : booking.activitySchedule.endTime.slice(0, 5),
            formattedStartTime: booking.startTime
              ? booking.startTime.slice(11, 16)
              : booking.activitySchedule.startTime.slice(0, 5),
          };
        });

        setTotalPages(bookings.pages);
        setBookingsList(parsedBookings);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os dados das reservas, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  useEffect(() => {
    setTotalPages(1);
    setBookingsList([]);

    if (activity) {
      loadBookings({
        ...currentFilters,
        activityId: activity.id,
        showPendingBookingsOnly: showPending,
        order: currentOrdering,
        page: currentPage,
        sort: 'bookedDate',
      });
    }
  }, [
    currentPage,
    loadBookings,
    currentOrdering,
    currentFilters,
    activity,
    showPending,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters(value ? { [filterBy]: value } : undefined);
    },
    [filterBy],
  );
  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'bookedDate' | 'createdAt') => {
    setSortBy(sort);
  }, []);

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handlePayment = useCallback(async (bookingId: string) => {
    const paydBooking = await createBookingExternalPaymentsService(bookingId);

    setBookingsList((prevState) =>
      prevState.map((booking) =>
        booking.id === paydBooking.id
          ? { ...booking, payment: paydBooking.payment }
          : booking,
      ),
    );
  }, []);

  const handleCheckin = (checkinBooking: IBookingTableItem): void => {
    setBookingsList((prevState) =>
      prevState.map((booking) =>
        booking.id === checkinBooking.id ? checkinBooking : booking,
      ),
    );
  };

  return (
    <Box>
      <BookingTable
        mt="4"
        bookings={bookingsList}
        currentPage={currentPage}
        filterBy={filterBy}
        filterByOptions={[
          {
            label: 'Nome do membro',
            value: 'userName',
          },
          {
            label: 'Documento do membro',
            value: 'userDocument',
          },
          {
            label: 'Data da reserva',
            value: 'bookedDate',
          },
        ]}
        onChangeFilterBy={handleFilterBy}
        onCheckin={handleCheckin}
        onFilter={debounceHandleFilter}
        onOrder={handleOrderList}
        onPageChange={handlePageChange}
        onPay={handlePayment}
        onSort={handleSortList}
        onShowPending={() => setShowPending((prevState) => !prevState)}
        order={currentOrdering}
        showActivityColumn={false}
        showPending={showPending}
        sortBy={sortBy}
        totalPages={totalPages}
      />
    </Box>
  );
};
