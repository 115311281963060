import {
  Box,
  Flex,
  Icon,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { PiCheckCircle, PiWarningCircle } from 'react-icons/pi';
import { useCallback, useEffect, useState } from 'react';
import { UserAvatarStatus } from '../../../../../models/users';

enum AvatarStatusCardBgColor {
  EMPTY = 'white',
  VALID = 'green.50',
  INVALID = 'red.50',
  NOT_VERIFIED = 'yellow.50',
  UNAVAILABLE = 'white',
}

enum AvatarStatusCardColor {
  EMPTY = 'gray.500',
  VALID = 'green.500',
  INVALID = 'red.500',
  NOT_VERIFIED = 'yellow.500',
  UNAVAILABLE = 'gray.500',
}

enum AvatarStatusCardTitle {
  EMPTY = 'Regras upload foto',
  VALID = 'Foto validada',
  INVALID = 'Foto fora das normas solicitadas',
  NOT_VERIFIED = 'Foto não verificada',
  UNAVAILABLE = 'FaceID desativado',
}

interface IAvatarStatusCardProps {
  avatarStatus: UserAvatarStatus;
}

const AvatarStatusCard = ({
  avatarStatus,
}: IAvatarStatusCardProps): JSX.Element => {
  const [cardText, setCardText] = useState<string[]>(['']);

  const handleCardText = useCallback(() => {
    switch (avatarStatus) {
      case UserAvatarStatus.EMPTY:
        setCardText([
          'Com fundo sem pessoas/objetos',
          'Busque um ambiente claro',
          'Rosto visível (evite acessórios)',
        ]);
        break;
      case UserAvatarStatus.VALID:
        setCardText(['O usuário pode acessar pela facial']);
        break;
      case UserAvatarStatus.INVALID:
        setCardText([
          'Com fundo sem pessoas/objetos',
          'Em um ambiente claro',
          'Rosto visível (evite acessórios)',
        ]);
        break;
      case UserAvatarStatus.NOT_VERIFIED:
        setCardText(['Tente validar a foto novamente']);
        break;
      default:
        setCardText(['']);
    }
  }, [avatarStatus]);

  useEffect(() => {
    handleCardText();
  }, [handleCardText]);

  return (
    <>
      <Flex
        py="3"
        px="4"
        border="1px"
        borderRadius="4px"
        borderColor={AvatarStatusCardColor[avatarStatus]}
        bgColor={AvatarStatusCardBgColor[avatarStatus]}
      >
        <Icon
          as={
            avatarStatus === UserAvatarStatus.VALID
              ? PiCheckCircle
              : PiWarningCircle
          }
          fontSize="24"
          fill={AvatarStatusCardColor[avatarStatus]}
        />
        <Box ml="8px">
          <Text
            fontWeight="bold"
            color={AvatarStatusCardColor[avatarStatus]}
            mb="1"
          >
            {AvatarStatusCardTitle[avatarStatus]}
          </Text>
          {cardText.length > 1 ? (
            <UnorderedList color={AvatarStatusCardColor[avatarStatus]}>
              {cardText.map((text) => (
                <ListItem key={text}>{text}</ListItem>
              ))}
            </UnorderedList>
          ) : (
            <Text color={AvatarStatusCardColor[avatarStatus]}>
              {cardText[0]}
            </Text>
          )}
        </Box>
      </Flex>
    </>
  );
};

export default AvatarStatusCard;
