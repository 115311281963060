import { IActivityBase } from '../../models/activities';
import { ISpotBase } from '../../models/spots';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IUpdateSpotAvatarProps {
  spotId: string;
  avatarData: FormData;
}

export interface IUpdatedSpotAvatar extends ISpotBase {
  activities: IActivityBase[];
}

export const updateSpotAvatarsService = async ({
  avatarData,
  spotId,
}: IUpdateSpotAvatarProps): Promise<IUpdatedSpotAvatar> => {
  const { data } = await jhsfBookingsApi.patch<IUpdatedSpotAvatar>(
    `/avatars/spots/${spotId}`,
    avatarData,
  );

  return data;
};
