import { IWeekdayActivityScheduleBase } from '../../models/activities';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export type IWeekdayActivitySchedule = IWeekdayActivityScheduleBase;

export interface IListWeekdaysActivityScheduleProps {
  activityId: string;
  activityScheduleId?: string;
  addDay?: number;
  isActive?: boolean;
  selectedMonth?: number;
  selectedYear?: number;
  showExpired?: boolean;
  startDate?: string;
  userId?: string;
}

export const listWeekdaysActivitiesSchedulesService = async ({
  activityId,
  activityScheduleId,
  addDay,
  selectedMonth,
  selectedYear,
  showExpired,
  startDate,
  userId,
}: IListWeekdaysActivityScheduleProps): Promise<IWeekdayActivitySchedule[]> => {
  const { data } = await jhsfBookingsApi.get(
    `/activities/${activityId}/schedules`,
    {
      params: {
        addDay,
        activityScheduleId,
        selectedMonth,
        selectedYear,
        showExpired,
        startDate: startDate || undefined,
        userId,
      },
    },
  );

  return data;
};
