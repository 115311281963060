import React, { createContext, useCallback, useState, useContext } from 'react';
import { IDetailedVenture } from '../services/Ventures/ShowVenturesService';
import { maskCnpj, maskDate } from '../utils/formatters/handleMask';

interface IVentureContextState extends IDetailedVenture {
  formattedCreatedAt: string;
}

interface IVentureContextData {
  venture?: IVentureContextState;
  handleVenture: (venture?: IDetailedVenture) => void;
}

const VentureContext = createContext<IVentureContextData>(
  {} as IVentureContextData,
);

const VentureProvider: React.FC = ({ children }) => {
  const [venture, setVenture] = useState<IVentureContextState>();

  const handleVenture = useCallback((ventureData?: IDetailedVenture) => {
    if (ventureData) {
      setVenture({
        ...ventureData,
        cnpj: maskCnpj(ventureData.cnpj).toString(),
        formattedCreatedAt: maskDate(ventureData.createdAt),
      });
    } else {
      setVenture(undefined);
    }
  }, []);

  return (
    <VentureContext.Provider
      value={{
        venture,
        handleVenture,
      }}
    >
      {children}
    </VentureContext.Provider>
  );
};

const useVenture = (): IVentureContextData => useContext(VentureContext);

export { VentureProvider, useVenture };
