import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Heading,
  Divider,
  VStack,
  Flex,
  Button,
  useToast,
  ButtonGroup,
  SimpleGrid,
  Grid,
  GridItem,
} from '@chakra-ui/react';

import axios from 'axios';
import { AvatarDropzone } from '../../../../../components/Form/AvatarDropzone';
import { MaskedInput } from '../../../../../components/Form/MaskedInput';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { InternationalPhoneInput } from '../../../../../components/Form/InternationalPhoneInput';
import { createUsersService } from '../../../../../services/Users/CreateUsersService';
import { updateUserAvatarsService } from '../../../../../services/Users/UpdateUserAvatarsService';
import { translateError } from '../../../../../utils/errors';
import {
  validateCpf,
  validateBRPhone,
} from '../../../../../utils/documentValidation';
import {
  AsyncSelect,
  AsyncSelectOption,
} from '../../../../../components/Form/AsyncSelect';
import { listUsersService } from '../../../../../services/Users/ListUsersService';
import { ReactSelect } from '../../../../../components/Form/ReactSelect';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import {
  UserAvatarStatus,
  UserExperience,
  UserFaceIdStatus,
} from '../../../../../models/users';
import { IAddressBase } from '../../../../../models/address';
import AddressForm, {
  addressValidation,
} from '../../../../../components/AddressForm';
import { checkUsersFaceService } from '../../../../../services/Users/CheckUsersFaceService';
import AvatarStatusCard from '../../components/AvatarStatusCard';
import FaceIdStatusTag from '../../components/FaceIDStatusTag';
import { Switch } from '../../../../../components/Form/Switch';

type NewGuestFormData = {
  address?: IAddressBase;
  bio?: string;
  birthDate: Date;
  cpf?: string;
  email: string;
  experience: UserExperience;
  healthCheck?: Date;
  hostId: string;
  name: string;
  foreignerDocument?: string;
  foreignerDocumentType?: string;
  useForeignerDocument: boolean;
  phone?: string;
};

interface ILocationState {
  groupKey?: 'GUEST' | 'DEPENDANT';
  hostId?: string;
}

const registerGuestFormSchema = Yup.object().shape({
  address: addressValidation
    .nullable()
    .transform((value, originalValue) =>
      !!originalValue &&
      Object.values<string | undefined>(originalValue).some(
        (val) => !!val?.length,
      )
        ? originalValue
        : null,
    ),
  bio: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  birthDate: Yup.date().nullable().required('Data de nascimento requerida'),
  cpf: Yup.string().when('useForeignerDocument', {
    is: (val: boolean) => val === false,
    then: Yup.string()
      .length(11, 'Valor inválido, requerido 11 dígitos')
      .test('is-valid', 'CPF inválido', (value) => validateCpf(value))
      .nullable()
      .transform((_, originalValue) =>
        originalValue.replace(/\D/g, '').length
          ? originalValue.replace(/\D/g, '')
          : null,
      )
      .required('CPF requerido'),
    otherwise: Yup.string()
      .nullable()
      .transform(() => null),
  }),
  email: Yup.string()
    .email('E-mail inválido')
    .required('E-mail requerido')
    .transform((value) => value.toLowerCase()),
  experience: Yup.number()
    .integer()
    .oneOf([
      UserExperience['Nível 1 e 2'],
      UserExperience['Nível 3 e 4'],
      UserExperience['Nível 5'],
      UserExperience['Nível 6'],
      UserExperience['Nível 6 e 7'],
      UserExperience['Nível 7'],
      UserExperience['Nível 8'],
      UserExperience.Especial,
    ])
    .required('Experiência requerida.'),
  healthCheck: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  hostId: Yup.string()
    .uuid()
    .required('Requerido')
    .transform((value) => value.value),
  useForeignerDocument: Yup.boolean().default(false),
  name: Yup.string()
    .required('Nome requerido')
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, 'Nome inválido'),
  foreignerDocument: Yup.string().when('useForeignerDocument', {
    is: (val: boolean) => val === true,
    then: Yup.string().when('foreignerDocumentType', {
      is: (val: string) => val === 'PASSPORT',
      then: Yup.string()
        .matches(/^[A-Z]{1,2}\d{6,8}$/, 'Deve seguir o padrão (ex. AB123456)')
        .nullable()
        .transform((_, originalValue) =>
          originalValue.length ? originalValue : null,
        )
        .uppercase()
        .required('Passaporte requerido'),
      otherwise: Yup.string().when('foreignerDocumentType', {
        is: (val: string) => val === 'RNE',
        then: Yup.string()
          .matches(/[A-Za-z0-9]$/, 'Apenas letras e números')
          .nullable()
          .min(8, 'Mínimo 8 dígitos')
          .max(12, 'Máximo 12 dígitos')
          .transform((_, originalValue) =>
            originalValue.length ? originalValue : null,
          )
          .uppercase()
          .required('RNE requerido'),
        otherwise: Yup.string().when('foreignerDocumentType', {
          is: (val: string) => val === 'DRIVERS_LICENSE',
          then: Yup.string()
            .matches(/[A-Za-z0-9]$/, 'Apenas letras e números')
            .nullable()
            .transform((_, originalValue) =>
              originalValue.length ? originalValue : null,
            )
            .uppercase()
            .required('Carteira de motorista requerida'),
          otherwise: Yup.string()
            .matches(/[A-Za-z0-9]$/, 'Apenas letras e números')
            .nullable()
            .transform((_, originalValue) =>
              originalValue.length ? originalValue : null,
            )
            .uppercase()
            .required('Documento requerido'),
        }),
      }),
    }),
  }),
  foreignerDocumentType: Yup.string().when('useForeignerDocument', {
    is: (val: boolean) => val === true,
    then: Yup.string().nullable().required('Tipo do documento requerido'),
    otherwise: Yup.string()
      .nullable()
      .transform(() => null),
  }),
  phone: Yup.string()
    .test('is-valid', 'Telefone inválido', (value) => validateBRPhone(value))
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
});

export const GuestRegister = (): JSX.Element => {
  const { state } = useLocation<ILocationState | undefined>();

  const featureGroupKey = state?.groupKey || 'DEPENDANT';

  const { goBack, push } = useHistory();

  const toast = useToast();

  const [avatarStatus, setAvatarStatus] = useState<UserAvatarStatus>(
    UserAvatarStatus.EMPTY,
  );
  const faceIdStatus = UserFaceIdStatus.DISABLED;
  const [avatarChanged, setAvatarChanged] = useState<boolean>(true);
  const [isCheckingAvatar, setIsCheckingAvatar] = useState(false);
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const formMethods = useForm({
    resolver: yupResolver(registerGuestFormSchema),
  });

  const {
    register,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    clearErrors,
  } = formMethods;

  const { errors } = formState;

  const isForeigner = watch('useForeignerDocument');
  const foreignerDocType = watch('foreignerDocumentType');

  const foreignerDocumentTypeSelectOptions = useMemo(
    () => [
      {
        label: 'RNE',
        value: 'RNE',
      },
      {
        label: 'Passaporte',
        value: 'PASSPORT',
      },
      {
        label: 'Carteira de motorista',
        value: 'DRIVERS_LICENSE',
      },
      {
        label: 'Outros',
        value: 'OTHERS',
      },
    ],
    [],
  );

  const experienceSelectOptions = useMemo(
    () => [
      {
        label: 'Nível 1 e 2',
        value: UserExperience['Nível 1 e 2'],
      },
      {
        label: 'Nível 3 e 4',
        value: UserExperience['Nível 3 e 4'],
      },
      {
        label: 'Nível 5',
        value: UserExperience['Nível 5'],
      },
      {
        label: 'Nível 6',
        value: UserExperience['Nível 6'],
      },
      {
        label: 'Nível 6 e 7',
        value: UserExperience['Nível 6 e 7'],
      },
      {
        label: 'Nível 7',
        value: UserExperience['Nível 7'],
      },
      {
        label: 'Nível 8',
        value: UserExperience['Nível 8'],
      },
      {
        label: 'Especial',
        value: UserExperience.Especial,
      },
    ],
    [],
  );

  const handleLoadHostSelectOption = useCallback(
    async (name?: string): Promise<AsyncSelectOption[]> => {
      const { items: hosts } = await listUsersService({
        name,
        featureGroups: ['MEMBER', 'DEPENDANT'],
        limit: 4,
      });

      const parsedUsersSelectOption: AsyncSelectOption[] = [
        ...hosts.map((host) => ({
          label: host.name,
          value: host.id,
        })),
      ];

      return parsedUsersSelectOption;
    },
    [],
  );

  useEffect(() => {
    if (state?.hostId) {
      setValue('hostId', { value: state.hostId });
    }
  }, [setValue, state?.hostId]);

  useEffect(() => {
    setValue('experience', UserExperience['Nível 1 e 2']);
  }, [setValue]);

  useEffect(() => {
    if (isForeigner) {
      setValue('cpf', '');
    }

    if (!isForeigner) {
      setValue('foreignerDocument', '');
      setValue('foreignerDocumentType', '');
    }
  }, [isForeigner, setValue]);

  useEffect(() => {
    clearErrors('foreignerDocument');
  }, [foreignerDocType, clearErrors]);

  const checkUserAvatar = useCallback(
    async (file: File) => {
      setIsCheckingAvatar(true);

      try {
        const formData = new FormData();
        formData.append('face', file);

        const { avatarStatus: uploadedAvatarStatus } =
          await checkUsersFaceService({
            faceData: formData,
          });

        setAvatarStatus(uploadedAvatarStatus);

        if (avatarStatus === UserAvatarStatus.VALID) {
          toast({
            title: 'Foto validada',
            description: 'A foto foi validada com sucesso',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } catch (err) {
        setAvatarStatus(UserAvatarStatus.NOT_VERIFIED);

        toast({
          title: 'Foto não validada',
          description: 'Ocorreu um erro ao validar a foto',
          status: 'error',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } finally {
        setAvatarChanged(true);
        setIsCheckingAvatar(false);
      }
    },
    [toast, avatarStatus],
  );

  const handleCheckUserAvatar = useCallback(async () => {
    if (avatar) {
      checkUserAvatar(avatar);
    }
  }, [checkUserAvatar, avatar]);

  const handleChangeAvatar = useCallback(
    (file: File) => {
      setAvatar(file);
      setAvatarUrl(URL.createObjectURL(file));

      if (featureGroupKey === 'DEPENDANT') {
        checkUserAvatar(file);
      }
    },
    [checkUserAvatar, featureGroupKey],
  );

  const handleDeleteAvatar = useCallback(() => {
    if (featureGroupKey === 'DEPENDANT') {
      setAvatarChanged(true);
      setAvatarStatus(UserAvatarStatus.EMPTY);
    }

    setAvatar(undefined);
    setAvatarUrl(undefined);
  }, [featureGroupKey]);

  const handleNewGuest: SubmitHandler<NewGuestFormData> = useCallback(
    async ({
      address,
      bio,
      birthDate,
      cpf,
      email,
      experience,
      healthCheck,
      hostId,
      name,
      foreignerDocument,
      foreignerDocumentType,
      useForeignerDocument,
      phone,
    }) => {
      try {
        const guest = await createUsersService({
          address,
          bio,
          birthDate,
          cpf,
          email,
          experience,
          featureGroupKey,
          healthCheck,
          name,
          hostId,
          foreignerDocument,
          foreignerDocumentType,
          useForeignerDocument,
          phone,
        });

        if (avatar) {
          const formData = new FormData();

          formData.append('avatar', avatar);

          if (featureGroupKey === 'DEPENDANT') {
            formData.append('faceStatus', avatarStatus);
          } else {
            formData.append('faceStatus', UserAvatarStatus.NOT_VERIFIED);
          }

          await updateUserAvatarsService({
            avatarData: formData,
            userId: guest.id,
          });
        }

        toast({
          title: 'Cadastrado com sucesso',
          description: `O ${
            featureGroupKey === 'GUEST' ? 'convidado' : 'dependente'
          } foi cadastrado corretamente.`,
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });

        if (featureGroupKey === 'GUEST') {
          push('/guests/details', { guestId: guest.id });

          return;
        }

        push('/members/details', { memberId: guest.id });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha no cadastro',
            description:
              translateError({ message: err.response?.data.message }) ||
              `Ocorreu um erro ao cadastrar o  ${
                featureGroupKey === 'GUEST' ? 'convidado' : 'dependente'
              }, tente novamente.`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [avatar, featureGroupKey, push, toast, avatarStatus],
  );

  return (
    <DefaultLayout>
      <FormProvider {...formMethods}>
        <Box
          as="form"
          flex="1"
          borderRadius={8}
          bg="white"
          p="8"
          onSubmit={handleSubmit(handleNewGuest)}
        >
          <Heading size="lg" fontWeight="normal">
            Cadastrar
            {featureGroupKey === 'GUEST' ? ' convidado' : ' dependente'}
          </Heading>

          <Divider my="6" borderColor="gray.300" />

          <Flex direction="column" align="center" mb="8">
            {featureGroupKey === 'DEPENDANT' && (
              <Box mb={6} alignSelf="flex-end">
                <FaceIdStatusTag
                  avatarChanged={avatarChanged}
                  avatarStatus={avatarStatus}
                  faceIdStatus={faceIdStatus}
                />
              </Box>
            )}

            <AvatarDropzone
              avatarUrl={avatarUrl}
              onChange={handleChangeAvatar}
              onDelete={handleDeleteAvatar}
            />

            {featureGroupKey === 'DEPENDANT' && (
              <Box mt={6}>
                <AvatarStatusCard avatarStatus={avatarStatus} />
              </Box>
            )}

            {((featureGroupKey === 'DEPENDANT' &&
              avatarStatus === UserAvatarStatus.NOT_VERIFIED) ||
              isCheckingAvatar) && (
              <Box mt={3}>
                <Button
                  isDisabled={!avatar}
                  isLoading={isCheckingAvatar}
                  loadingText="Validando foto..."
                  colorScheme="blue"
                  onClick={handleCheckUserAvatar}
                >
                  Validar foto
                </Button>
              </Box>
            )}
          </Flex>

          <VStack spacing="8">
            <MaskedInput
              label="Nome completo"
              error={errors.name}
              {...register('name')}
            />

            <Grid gap="4" templateColumns="repeat(12, 1fr)" w="full">
              <GridItem
                colSpan={!isForeigner ? [12, 8, 9, 9, 10] : [12, 12, 12, 9, 10]}
              >
                {!isForeigner ? (
                  <MaskedInput
                    label="CPF"
                    mask="cpf"
                    error={errors.cpf}
                    {...register('cpf')}
                  />
                ) : (
                  <Grid
                    templateColumns="repeat(12, 1fr)"
                    gap={[6, 6, 0]}
                    w="full"
                  >
                    <GridItem colSpan={[12, 12, 5]}>
                      <ReactSelect
                        label="Tipo"
                        name="foreignerDocumentType"
                        options={foreignerDocumentTypeSelectOptions}
                        control={control}
                        error={errors.foreignerDocumentType}
                      />
                    </GridItem>

                    <GridItem colSpan={[12, 12, 7]}>
                      <MaskedInput
                        label="Documento"
                        error={errors.foreignerDocument}
                        {...register('foreignerDocument')}
                      />
                    </GridItem>
                  </Grid>
                )}
              </GridItem>

              <GridItem
                colSpan={!isForeigner ? [12, 4, 3, 3, 2] : [12, 12, 12, 3, 2]}
              >
                <Switch
                  label="Usar documento internacional"
                  error={errors.useForeignerDocument}
                  {...register('useForeignerDocument')}
                />
              </GridItem>
            </Grid>

            <SimpleGrid minChildWidth="240px" spacing="6" w="full">
              <InternationalPhoneInput
                flex={1}
                label="Telefone"
                name="phone"
                control={control}
                error={errors.phone}
              />

              <MaskedInput
                label="E-mail"
                type="email"
                textTransform="lowercase"
                error={errors.email}
                {...register('email')}
              />
            </SimpleGrid>

            <SimpleGrid minChildWidth="240px" spacing="6" w="full">
              <DatePicker
                label="Data de nascimento"
                isClearable={false}
                maxDate={new Date()}
                control={control}
                error={errors.birthDate}
                {...register('birthDate')}
              />

              <DatePicker
                label="Exame médico"
                maxDate={new Date()}
                control={control}
                error={errors.healthCheck}
                {...register('healthCheck')}
              />

              <ReactSelect
                label="Nível de experiência"
                name="experience"
                options={experienceSelectOptions}
                control={control}
                error={errors.experience}
              />
            </SimpleGrid>

            {!state?.hostId && (
              <AsyncSelect
                label="Responsável"
                name="hostId"
                loadOptions={handleLoadHostSelectOption}
                control={control}
                error={errors.hostId}
              />
            )}

            <MaskedInput
              label="Biografia"
              as="textarea"
              minHeight="160px"
              resize="none"
              py="2"
              error={errors.bio}
              {...register('bio')}
            />
          </VStack>

          <Divider my="6" borderColor="gray.300" />

          <AddressForm />

          <Flex mt="12" justify="flex-end">
            <ButtonGroup>
              <Button colorScheme="blackAlpha" onClick={goBack}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme="green"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </Flex>
        </Box>
      </FormProvider>
    </DefaultLayout>
  );
};
