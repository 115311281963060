import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import { IActivityScheduleItemBase } from '../../models/activities';

interface ICreateActivityScheduleItemProps {
  scheduleId: string;
  description?: string;
  isActive: boolean;
  isRequired: boolean;
  name: string;
  price?: number;
  totalAmount: number;
  userId?: string;
}

export type INewActivityScheduleItem = IActivityScheduleItemBase;

export const createActivityScheduleItemsService = async ({
  scheduleId,
  description,
  isActive,
  isRequired,
  name,
  price,
  totalAmount,
  userId,
}: ICreateActivityScheduleItemProps): Promise<INewActivityScheduleItem> => {
  const { data } = await jhsfBookingsApi.post<INewActivityScheduleItem>(
    `/activities/schedules/${scheduleId}/items`,
    {
      description,
      isActive,
      isRequired,
      name,
      price,
      totalAmount,
      userId,
    },
  );

  return data;
};
