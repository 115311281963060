import { IUserCategoryBase } from '../../models/ventures';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export const listUserCategoriesService = async (
  ventureId: string,
): Promise<IUserCategoryBase[]> => {
  const { data } = await jhsfBookingsApi.get<IUserCategoryBase[]>(
    `/user-categories/ventures/${ventureId}`,
  );

  return data;
};
