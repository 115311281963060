import { IActivityBase } from '../../models/activities';
import { IVentureBase } from '../../models/ventures';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IUpdateActivityProps {
  activityId: string;
  bookingPrice?: number;
  description?: string;
  isActive?: boolean;
  isVerified?: boolean;
  name: string;
  spotId: string;
}

export interface IUpdatedActivity extends IActivityBase {
  ventures: IVentureBase[];
}

export const updateActivitiesService = async ({
  activityId,
  bookingPrice,
  description,
  isActive,
  isVerified,
  name,
  spotId,
}: IUpdateActivityProps): Promise<IUpdatedActivity> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedActivity>(
    `/activities/${activityId}`,
    {
      bookingPrice,
      description,
      isActive,
      name,
      spotId,
    },
    { params: { isVerified } },
  );

  return data;
};
