/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export const maskCnpj = (value: string | number): string =>
  String(value)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');

export const maskCpf = (value: string | number): string =>
  String(value)
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{2})$/, '$1-$2');

export const maskZipCode = (value: string | number): string | number =>
  String(value)
    .replace(/\D/g, '')
    .replace(/^(\d{5})(\d)/, '$1-$2');

export const maskMoney = (value: string | number): string => {
  const match = String(value).replace(/\D/g, '').length;

  if (match) {
    const formatted = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value) / 100);

    return formatted;
  }

  return '';
};

export const maskShortDate = (
  date: Date | string,
  locales = 'pt-BR',
): string => {
  const formatted = new Date(date).toLocaleDateString(locales, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return formatted;
};

export const maskDate = (date: Date | string): string => {
  const formatted = new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  });

  return formatted;
};

export const maskDateTime = (date: Date | string): string => {
  const currentDate = new Date(date);

  const formattedDate = currentDate.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const formattedTime = currentDate.toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return `${formattedDate} - ${formattedTime}`;
};

export const maskLongDate = (date: Date | string): string => {
  const currentDate = new Date(date);

  const formattedDate = format(currentDate, "E',' d 'de' MMMM yyyy", {
    locale: ptBR,
  });
  return formattedDate;
};

export const maskPhone = (value: string | number): string | number => {
  const match = String(value).match(/^(\d{2})(\d{5}|\d{4})(\d{4})/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return value;
};

export const unmaskNumber = (
  maskedValue: string | number | null,
): number | null => {
  const stringValue = String(maskedValue).replace(/\D/g, '');

  return stringValue ? Number(stringValue) : null;
};
