import {
  Box,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  ButtonGroup,
} from '@chakra-ui/react';
import { RiAddLine, RiEyeLine } from 'react-icons/ri';
import { LinkButton } from '../../../../../../../../../../../components/LinkButton';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { useVenture } from '../../../../../../../../../../../hooks/venture';

export const VentureSpotTable = (props: BoxProps): JSX.Element => {
  const { user: authenticatedUser } = useAuth();

  const { venture } = useVenture();

  return (
    <Box {...props}>
      {authenticatedUser.featureGroup.features.some((feature) =>
        ['SPOT_FULL_ACCESS', 'SPOT_WRITE_ACCESS'].includes(feature.key),
      ) &&
        venture && (
          <ButtonGroup width="100%" justifyContent="flex-end">
            <LinkButton
              colorScheme="green"
              icon={<Icon as={RiAddLine} fontSize="16" />}
              to={{
                pathname: '/spots/register',
                state: { ventureId: venture.id },
              }}
            >
              Cadastrar novo
            </LinkButton>
          </ButtonGroup>
        )}

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {venture?.spots.map((spot) => (
            <Tr key={spot.id}>
              <Td>
                <Text fontWeight="bold">{spot.name}</Text>
              </Td>

              <Td textAlign="right">
                <LinkButton
                  px={0}
                  to={{
                    pathname: '/spots/details',
                    state: {
                      spotId: spot.id,
                    },
                  }}
                >
                  <Icon as={RiEyeLine} fontSize="16" />
                </LinkButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
