import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IDeleteActivityScheduleItemsProps {
  itemId: string;
  isVerified?: boolean;
}

const deleteActivityScheduleItemsService = async ({
  itemId,
  isVerified,
}: IDeleteActivityScheduleItemsProps): Promise<void> => {
  await jhsfBookingsApi.delete(`/activities/schedules/items/${itemId}`, {
    params: { isVerified },
  });
};

export default deleteActivityScheduleItemsService;
