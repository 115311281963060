import React, { createContext, useCallback, useState, useContext } from 'react';
import { IDetailedSpot } from '../services/Spots/ShowSpotsService';
import { maskDate } from '../utils/formatters/handleMask';

interface ISpotContextState extends IDetailedSpot {
  formattedCreatedAt: string;
}

interface ISpotContextData {
  spot?: ISpotContextState;
  handleSpot: (spot?: IDetailedSpot) => void;
}

const SpotContext = createContext<ISpotContextData>({} as ISpotContextData);

const SpotProvider: React.FC = ({ children }) => {
  const [spot, setSpot] = useState<ISpotContextState>();

  const handleSpot = useCallback((spotData?: IDetailedSpot) => {
    if (spotData) {
      setSpot({
        ...spotData,
        formattedCreatedAt: maskDate(spotData.createdAt),
      });
    } else {
      setSpot(undefined);
    }
  }, []);

  return (
    <SpotContext.Provider
      value={{
        spot,
        handleSpot,
      }}
    >
      {children}
    </SpotContext.Provider>
  );
};

const useSpot = (): ISpotContextData => useContext(SpotContext);

export { SpotProvider, useSpot };
