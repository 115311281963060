import { Button, ButtonProps } from '@chakra-ui/react';

interface IPaginationItemProps extends ButtonProps {
  number: number;
  isCurrent?: boolean;
}

export const PaginationItem = ({
  isCurrent = false,
  number,
  ...rest
}: IPaginationItemProps): JSX.Element => {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        w="4"
        colorScheme="blue"
        disabled
        _disabled={{
          cursor: 'default',
        }}
        {...rest}
      >
        {number}
      </Button>
    );
  }

  return (
    <Button
      size="sm"
      fontSize="xs"
      w="4"
      bg="gray.100"
      _hover={{
        bg: 'gray.300',
      }}
      {...rest}
    >
      {number}
    </Button>
  );
};
