import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import { IActivityItemBase } from '../../models/activities';

interface IUpdateActivityItemProps {
  activityItemId: string;
  description?: string;
  isActive?: boolean;
  isVerified?: boolean;
  name: string;
  // totalAmount: number;
}

export type IUpdatedActivityItem = IActivityItemBase;

export const updateActivityItemsService = async ({
  activityItemId,
  description,
  isActive,
  isVerified,
  name,
}: // totalAmount,
IUpdateActivityItemProps): Promise<IUpdatedActivityItem> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedActivityItem>(
    `/activities/items/${activityItemId}`,
    {
      description,
      isActive,
      name,
      // totalAmount,
    },
    { params: { isVerified } },
  );

  return data;
};
