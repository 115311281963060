import { ISpotBase } from '../../models/spots';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface ICreateSpotProps {
  description?: string;
  isActive: boolean;
  name: string;
  ventureId: string;
}

export type INewSpot = ISpotBase;

export const createSpotsService = async ({
  description,
  isActive,
  name,
  ventureId,
}: ICreateSpotProps): Promise<INewSpot> => {
  const { data } = await jhsfBookingsApi.post<INewSpot>('/spots', {
    description,
    isActive,
    name,
    ventureId,
  });

  return data;
};
