import { IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

const setUsersDefaultPasswordService = async (
  userId: string,
): Promise<IUserBase> => {
  const { data } = await jhsfBookingsApi.post<IUserBase>(
    `/users/${userId}/default-password`,
  );

  return data;
};

export default setUsersDefaultPasswordService;
