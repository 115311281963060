import { Box, BoxProps, useToast } from '@chakra-ui/react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import {
  IBookingTableItem,
  BookingTable,
} from '../../../../../../../../../../../components/BookingTable';
import { useUser } from '../../../../../../../../../../../hooks/user';
import { createBookingExternalPaymentsService } from '../../../../../../../../../../../services/Bookings/CreateBookingExternalPaymentsService';
import { listBookingsService } from '../../../../../../../../../../../services/Bookings/ListBookingsService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { maskDateTime } from '../../../../../../../../../../../utils/formatters/handleMask';

interface ILoadBookingsProps {
  activityName?: string;
  bookedDate?: string;
  spotName?: string;
  showPendingBookingsOnly?: boolean;
  userDocument?: string;
  userName?: string;
  sort?: 'bookedDate' | 'createdAt';
  order?: 'ASC' | 'DESC';
  page?: number;
}

export const UserBookingsList = (props: BoxProps): JSX.Element => {
  const toast = useToast();

  const { user } = useUser();

  const [bookingsList, setBookingsList] = useState<IBookingTableItem[]>([]);
  const [currentFilters, setCurrentFilters] =
    useState<{ [key: string]: string }>();
  const [currentOrdering, setCurrentOrdering] = useState<'ASC' | 'DESC'>('ASC');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterBy, setFilterBy] = useState('activityName');
  const [showPending, setShowPending] = useState(true);
  const [sortBy, setSortBy] = useState<'bookedDate' | 'createdAt'>(
    'bookedDate',
  );
  const [totalPages, setTotalPages] = useState<number>();

  const loadBookings = useCallback(
    async ({
      activityName,
      bookedDate,
      spotName,
      userDocument,
      userName,
      showPendingBookingsOnly,
      order,
      page,
      sort = 'bookedDate',
    }: ILoadBookingsProps) => {
      if (user) {
        try {
          const bookings = await listBookingsService({
            activityName,
            bookedDate,
            sellerId: user.id,
            spotName,
            userDocument: userDocument?.replace(/\D/g, ''),
            userName,
            showPendingBookingsOnly,
            limit: 20,
            page,
            sort,
            order,
          });

          const parsedBookings = bookings.items.map((booking) => {
            const [bookedYear, bookedMonth, bookedDay] =
              booking.bookedDate.split('-');

            return {
              ...booking,
              checkins: booking.checkins.map((checkin) => ({
                ...checkin,
                formattedCreatedAt: maskDateTime(checkin.createdAt),
              })),
              formattedBookedDate: `${bookedDay}/${bookedMonth}/${bookedYear}`,
              formattedCreatedAt: maskDateTime(booking.createdAt),
              formattedDeletedAt: booking.deletedAt
                ? maskDateTime(booking.deletedAt)
                : undefined,
              formattedEndTime: booking.endTime
                ? booking.endTime.slice(11, 16)
                : booking.activitySchedule.endTime.slice(0, 5),
              formattedStartTime: booking.startTime
                ? booking.startTime.slice(11, 16)
                : booking.activitySchedule.startTime.slice(0, 5),
            };
          });

          setTotalPages(bookings.pages);
          setBookingsList(parsedBookings);
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            toast({
              title: 'Falha ao carregar dados',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao carregar os dados das reservas, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [toast, user],
  );

  useEffect(() => {
    setTotalPages(1);
    setBookingsList([]);

    loadBookings({
      ...currentFilters,
      showPendingBookingsOnly: showPending,
      order: currentOrdering,
      page: currentPage,
      sort: sortBy,
    });
  }, [
    currentPage,
    loadBookings,
    currentOrdering,
    currentFilters,
    showPending,
    sortBy,
  ]);

  const handleFilterBy = useCallback((value: string) => {
    setFilterBy(value);
  }, []);

  const handleFilter = useCallback(
    (value: string) => {
      setCurrentFilters(value ? { [filterBy]: value } : undefined);
    },
    [filterBy],
  );
  const debounceHandleFilter = debounce(handleFilter, 1000);

  const handleSortList = useCallback((sort: 'bookedDate' | 'createdAt') => {
    setSortBy(sort);
  }, []);

  const handleOrderList = useCallback((order: 'ASC' | 'DESC') => {
    setCurrentOrdering(order);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);
  const handlePayment = useCallback(
    async (bookingId: string) => {
      try {
        const paydBooking = await createBookingExternalPaymentsService(
          bookingId,
        );

        setBookingsList((prevState) =>
          prevState.map((booking) =>
            booking.id === paydBooking.id
              ? { ...booking, payment: paydBooking.payment }
              : booking,
          ),
        );

        toast({
          title: 'Transação concluída com sucesso',
          description: 'A transação foi efetuada corretamente.',
          status: 'success',
          duration: 3000,
          isClosable: true,
          variant: 'subtle',
          position: 'top-right',
        });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha na transação',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao efetuar a transação, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    },
    [toast],
  );

  const handleCheckin = (checkinBooking: IBookingTableItem): void => {
    setBookingsList((prevState) =>
      prevState.map((booking) =>
        booking.id === checkinBooking.id ? checkinBooking : booking,
      ),
    );
  };

  return (
    <Box {...props}>
      <BookingTable
        bookings={bookingsList}
        currentPage={currentPage}
        filterBy={filterBy}
        onChangeFilterBy={handleFilterBy}
        onCheckin={handleCheckin}
        onFilter={debounceHandleFilter}
        onOrder={handleOrderList}
        onPageChange={handlePageChange}
        onPay={handlePayment}
        onSort={handleSortList}
        onShowPending={() => setShowPending((prevState) => !prevState)}
        order={currentOrdering}
        showPending={showPending}
        sortBy={sortBy}
        totalPages={totalPages}
      />
    </Box>
  );
};
