import { useLocation } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { showVenturesService } from '../../../../../services/Ventures/ShowVenturesService';
import { translateError } from '../../../../../utils/errors';
import VentureDetailsData from './components/VentureDetailsData';
import { VentureDetailsInfo } from './components/VentureDetailsInfo';
import { useVenture } from '../../../../../hooks/venture';

interface ILocationState {
  ventureId: string;
}

export const VentureDetails = (): JSX.Element => {
  const toast = useToast();
  const { handleVenture, venture } = useVenture();

  const {
    state: { ventureId },
  } = useLocation<ILocationState>();

  useEffect(() => {
    async function loadVenture(): Promise<void> {
      try {
        const ventureData = await showVenturesService(ventureId);

        handleVenture(ventureData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes do empreendimento, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadVenture();

    return () => handleVenture(undefined);
  }, [ventureId, toast, handleVenture]);

  return (
    <DefaultLayout>
      {venture && (
        <Flex direction="column" w="100%">
          <VentureDetailsInfo minW="510px" />

          <VentureDetailsData flex="1" mt="8" minW="510px" />
        </Flex>
      )}
    </DefaultLayout>
  );
};
