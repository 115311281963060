import { INotificationBase } from '../../models/notifications';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IUpdateNotificationsProps {
  isUnread?: boolean;
  notificationId: string;
}

export const updateNotifications = async ({
  isUnread,
  notificationId,
}: IUpdateNotificationsProps): Promise<INotificationBase> => {
  const { data } = await jhsfBookingsApi.patch<INotificationBase>(
    `/notifications/${notificationId}`,
    {
      isUnread,
    },
  );

  return data;
};
