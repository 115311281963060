import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  IconButton,
  ButtonGroup,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
  RiAddLine,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBinLine,
  RiEditLine,
} from 'react-icons/ri';
import { ConfirmationModal } from '../../../../../../../../../../../components/ConfirmationModal';
import { useActivity } from '../../../../../../../../../../../hooks/activity';
import { useAuth } from '../../../../../../../../../../../hooks/auth';
import { IActivityItemBase } from '../../../../../../../../../../../models/activities';
import deleteActivityItemsService from '../../../../../../../../../../../services/Activities/DeleteActivityItemsService';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { HandleActivityItemModal } from './components/HandleActivityItemModal';

export type IActivityItem = IActivityItemBase;

export const ActivityItems = (props: BoxProps): JSX.Element => {
  const toast = useToast();

  const { user: authenticatedUser } = useAuth();

  const { activity, handleActivity } = useActivity();

  const [handlingActivityItem, setHandlingActivityItem] =
    useState<IActivityItemBase>();
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [
    isHandleActivityItemModalVisible,
    setIsHandleActivityItemModalVisible,
  ] = useState(false);
  const [
    isVerifyConfirmationModalVisible,
    setIsVerifyConfirmationModalVisible,
  ] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleToggleDeleteConfirmationModal = useCallback(() => {
    setIsDeleteConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleHandleActivityItemModal = useCallback(() => {
    setIsHandleActivityItemModalVisible((prevState) => !prevState);
  }, []);

  const handleToggleVerifyConfirmationModal = useCallback(() => {
    setIsVerifyConfirmationModalVisible((prevState) => !prevState);
  }, []);

  const handleActivityItem = useCallback(
    (activityItem: IActivityItemBase, action: 'delete' | 'update') => {
      setHandlingActivityItem(activityItem);

      switch (action) {
        case 'delete':
          handleToggleDeleteConfirmationModal();

          break;

        case 'update':
          handleToggleHandleActivityItemModal();

          break;

        default:
          break;
      }
    },
    [handleToggleDeleteConfirmationModal, handleToggleHandleActivityItemModal],
  );

  const handleDeleteActivityItem = useCallback(
    async (activityItemId: string) => {
      if (activity) {
        try {
          await deleteActivityItemsService({
            activityItemId,
            isVerified,
          });

          setHandlingActivityItem(undefined);
          setIsVerified(false);
          setIsVerifyConfirmationModalVisible(false);

          handleActivity({
            ...activity,
            items: activity.items.filter((item) => item.id !== activityItemId),
          });

          toast({
            title: 'Excluído com sucesso',
            description: 'O item foi excluído corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            if (err.response?.data.message === 'has-bookings-pending') {
              handleToggleDeleteConfirmationModal();
              handleToggleVerifyConfirmationModal();

              return;
            }

            toast({
              title: 'Falha ao excluir',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao excluir o item, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      }
    },
    [
      activity,
      handleActivity,
      handleToggleDeleteConfirmationModal,
      handleToggleVerifyConfirmationModal,
      isVerified,
      toast,
    ],
  );

  const handleVerify = useCallback(() => {
    setIsVerified(true);
  }, []);

  useEffect(() => {
    if (handlingActivityItem && isVerified) {
      handleDeleteActivityItem(handlingActivityItem.id);
    }
  }, [handleDeleteActivityItem, handlingActivityItem, isVerified]);

  return (
    <Box {...props}>
      {handlingActivityItem && (
        <ConfirmationModal
          isOpen={isDeleteConfirmationModalVisible}
          onClose={() => {
            setHandlingActivityItem(undefined);
            handleToggleDeleteConfirmationModal();
          }}
          onConfirm={() => handleDeleteActivityItem(handlingActivityItem.id)}
          title="Confirmar exclusão"
          message="Deseja realmente excluir?"
        />
      )}

      <ConfirmationModal
        isOpen={isVerifyConfirmationModalVisible}
        onClose={() => {
          setHandlingActivityItem(undefined);
          handleToggleVerifyConfirmationModal();
        }}
        onConfirm={handleVerify}
        message="Existem reservas pendentes, confirmar ação?"
      />

      <HandleActivityItemModal
        activityItem={handlingActivityItem}
        isOpen={isHandleActivityItemModalVisible}
        onClose={() => {
          setHandlingActivityItem(undefined);
          handleToggleHandleActivityItemModal();
        }}
        onSave={() => {
          setHandlingActivityItem(undefined);
          handleToggleHandleActivityItemModal();
        }}
      />

      <Flex justifyContent="flex-end">
        {authenticatedUser.featureGroup.features.some((feature) =>
          ['ACTIVITY_ITEM_FULL_ACCESS', 'ACTIVITY_ITEM_WRITE_ACCESS'].includes(
            feature.key,
          ),
        ) && (
          <Button
            colorScheme="green"
            size="sm"
            leftIcon={<Icon as={RiAddLine} fontSize="16" />}
            onClick={() => {
              setHandlingActivityItem(undefined);
              handleToggleHandleActivityItemModal();
            }}
          >
            Novo item
          </Button>
        )}
      </Flex>

      <Table colorScheme="blue" mt="4">
        <Thead>
          <Tr>
            <Th>Nome</Th>
            <Th>Descrição</Th>
            <Th textAlign="center">Ativo</Th>
            {/* <Th textAlign="center">Quantidade</Th> */}
            <Th textAlign="right" w="8" />
          </Tr>
        </Thead>

        <Tbody>
          {activity?.items.map((activityItem) => (
            <Tr key={activityItem.id}>
              <Td>{activityItem.name}</Td>

              <Td>{activityItem.description}</Td>

              <Td textAlign="center">
                {activityItem.isActive ? (
                  <Icon as={RiCheckboxCircleLine} color="green" />
                ) : (
                  <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                )}
              </Td>

              {/* <Td textAlign="center">{activityItem.totalAmount}</Td> */}

              <Td textAlign="right">
                <ButtonGroup>
                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_ITEM_FULL_ACCESS',
                      'ACTIVITY_ITEM_WRITE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Edit"
                      size="sm"
                      icon={<Icon as={RiEditLine} />}
                      fontSize="16"
                      color="white"
                      colorScheme="yellow"
                      onClick={() => handleActivityItem(activityItem, 'update')}
                    />
                  )}

                  {authenticatedUser.featureGroup.features.some((feature) =>
                    [
                      'ACTIVITY_ITEM_FULL_ACCESS',
                      'ACTIVITY_ITEM_DELETE_ACCESS',
                    ].includes(feature.key),
                  ) && (
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      icon={<Icon as={RiDeleteBinLine} />}
                      fontSize="16"
                      colorScheme="red"
                      onClick={() => handleActivityItem(activityItem, 'delete')}
                    />
                  )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
