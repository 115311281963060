import {
  IActivityItemBase,
  IActivityScheduleBase,
  IActivityScheduleItemBase,
} from '../../models/activities';
import { IBookingQueueBase } from '../../models/bookingQueues';
import { ISpotModalityBase } from '../../models/spots';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IUpdateBookingQueueProps {
  bookingQueueId: string;
  activityScheduleId?: string;
  bookedDate: Date;
  description?: string;
  items?: IActivityItemBase[];
  scheduleItems?: IActivityScheduleItemBase[];
  modalities?: ISpotModalityBase[];
}

export interface IUpdatedBookingQueue extends IBookingQueueBase {
  activitySchedule?: IActivityScheduleBase;
  items: IActivityItemBase[];
  modalities: ISpotModalityBase[];
}

export const updateBookingQueuesService = async ({
  bookingQueueId,
  activityScheduleId,
  bookedDate,
  description,
  items,
  scheduleItems,
  modalities,
}: IUpdateBookingQueueProps): Promise<IUpdatedBookingQueue> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedBookingQueue>(
    `/booking-queues/${bookingQueueId}`,
    {
      activityScheduleId,
      bookedDate,
      description,
      items,
      scheduleItems,
      modalities,
    },
  );

  return data;
};
