import { IUserCategoryBase, IVentureBase } from '../../models/ventures';
import { IUserAddressBase, IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';
import { IFeatureGroupBase, IFeatureBase } from '../../models/features';

interface IDetailedUserHost extends IUserBase {
  category: IUserCategoryBase;
}

export interface IShowUserDetailedFeatureGroup extends IFeatureGroupBase {
  features: IFeatureBase[];
}

export interface IDetailedUser extends IUserBase {
  address?: IUserAddressBase;
  category?: IUserCategoryBase;
  featureGroup?: IShowUserDetailedFeatureGroup;
  host?: IDetailedUserHost;
  hostBlocked?: boolean;
  titularBlocked?: boolean;
  venture?: IVentureBase;
}

export const showUsersService = async (
  userId: string,
): Promise<IDetailedUser> => {
  const { data } = await jhsfBookingsApi.get<IDetailedUser>(`/users/${userId}`);

  return data;
};
