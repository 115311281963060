import React, { useEffect, useState } from 'react';
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiEyeLine,
  RiStarLine,
} from 'react-icons/ri';
import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  BoxProps,
  Icon,
  ButtonGroup,
  IconButton,
} from '@chakra-ui/react';
import { IBookingQueue } from '../../services/BookingQueues/ListBookingQueuesService';
import { UserExperience } from '../../models/users';
import { maskCpf, maskShortDate } from '../../utils/formatters/handleMask';
import { LinkButton } from '../LinkButton';
import { WaveSideEnum } from '../../models/activities';

interface IBookingQueueTableProps extends BoxProps {
  items: IBookingQueue[] | undefined;
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (page: number) => void;
  onChangePriority: (bookingQueueId: string) => void;
}

export const BookingQueueTable = ({
  items,
  onChangePriority,
  ...rest
}: IBookingQueueTableProps): JSX.Element => {
  const [localItems, setLocalItems] = useState(items);

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  return (
    <Box {...rest}>
      {!localItems && (
        <Box textAlign="center">
          <h3>Preencha os filtros para visualizar a listagem.</h3>
        </Box>
      )}
      {localItems?.length === 0 && (
        <Box textAlign="center">
          <h3>Nenhum resultado encontrado.</h3>
        </Box>
      )}
      {localItems && localItems.length > 0 && (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Data</Th>
              <Th>Atividade</Th>
              <Th>Início</Th>
              <Th>Fim</Th>
              <Th>Vagas total</Th>
              <Th>Lado da onda</Th>
              <Th>Min. experiência</Th>
              <Th>Nome</Th>
              <Th>Titular</Th>
              <Th textAlign="right" w="8" />
            </Tr>
          </Thead>
          <Tbody>
            {localItems &&
              localItems.map((item) => (
                <Tr key={item.id}>
                  <Td>
                    <b>{maskShortDate(`${item.bookedDate}T00:00:00`)}</b>
                  </Td>
                  <Td minWidth="280px">
                    <span style={{ marginRight: '0.5rem' }}>
                      {item.activity.isActive ? (
                        <Icon as={RiCheckboxCircleLine} color="green" />
                      ) : (
                        <Icon as={RiCheckboxBlankCircleLine} color="gray.400" />
                      )}
                    </span>
                    <span>{item.activity.name}</span>
                  </Td>
                  <Td>{item.activitySchedule.startTime.slice(0, 5)}</Td>
                  <Td>{item.activitySchedule.endTime.slice(0, 5)}</Td>
                  <Td>{item.activitySchedule.totalVacancies ?? 'Ilimitado'}</Td>
                  <Td>
                    {item.activitySchedule.waveSide &&
                      WaveSideEnum[item.activitySchedule.waveSide]}
                  </Td>
                  <Td>
                    {UserExperience[item.activitySchedule.minUserExperience]}
                  </Td>
                  <Td>
                    {item.user.name}
                    <br />
                    {item.user.cpf && maskCpf(item.user.cpf)}
                  </Td>
                  <Td>
                    {item.titularUser ? (
                      <>
                        {item.titularUser.name}
                        <br />
                        {item.titularUser.cpf && maskCpf(item.titularUser.cpf)}
                      </>
                    ) : (
                      '-'
                    )}
                  </Td>
                  <Td textAlign="right">
                    <ButtonGroup>
                      <IconButton
                        aria-label="Priority"
                        size="sm"
                        icon={<Icon as={RiStarLine} fontSize="18" />}
                        fontSize="16"
                        colorScheme={
                          !item.priorizedAt ? 'blackAlpha' : 'yellow'
                        }
                        onClick={() => {
                          const priorizedAtValue = item.priorizedAt
                            ? undefined
                            : new Date().toISOString();
                          setLocalItems(
                            (prevItems) =>
                              prevItems &&
                              prevItems.map((i) =>
                                i.id === item.id
                                  ? { ...i, priorizedAt: priorizedAtValue }
                                  : i,
                              ),
                          );

                          onChangePriority(item.id);
                        }}
                      />

                      <LinkButton
                        px={0}
                        to={{
                          pathname: '/booking-queues/details',
                          state: {
                            bookingQueueId: item.id,
                          },
                        }}
                      >
                        <Icon as={RiEyeLine} fontSize="16" />
                      </LinkButton>
                    </ButtonGroup>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};
