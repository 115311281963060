/* eslint-disable operator-assignment */
/* eslint-disable no-plusplus */
export const validateCpf = (value?: string): boolean => {
  const invalidValues = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];

  if (!value) {
    return true;
  }

  if (value.length !== 11 || invalidValues.includes(value)) {
    return false;
  }

  let cpfAux = value.substring(0, 9);
  let j = 10;
  let sum = 0;

  for (let i = 0; i < 9; i++) {
    const number = Number(cpfAux.charAt(i));

    if (Number.isNaN(number)) {
      return false;
    }

    sum += number * j;
    j--;
  }

  let rest = sum % 11;
  let fistDigit = 11 - rest;

  if (fistDigit > 9) {
    fistDigit = 0;
  }

  j = 11;
  sum = 0;
  cpfAux = cpfAux + String(fistDigit);

  for (let i = 0; i < 10; i++) {
    const number = Number(cpfAux.charAt(i));

    sum += number * j;
    j--;
  }

  rest = sum % 11;
  let secondDigit = 11 - rest;

  if (secondDigit > 9) {
    secondDigit = 0;
  }

  cpfAux = cpfAux + String(secondDigit);

  return value === cpfAux;
};

export const validateCnpj = (value?: string): boolean => {
  const invalidValues = [
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ];

  if (!value) {
    return true;
  }

  if (value.length !== 14 || invalidValues.includes(value)) {
    return false;
  }

  let numbersLength = value.length - 2;
  const lastDigits = value.substring(numbersLength);

  let sum = 0;
  let pos = numbersLength - 7;

  for (let i = numbersLength; i >= 1; i--) {
    sum += +value.charAt(numbersLength - i) * pos--;

    if (pos < 2) {
      pos = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result !== +lastDigits.charAt(0)) {
    return false;
  }

  numbersLength = numbersLength + 1;

  sum = 0;
  pos = numbersLength - 7;

  for (let i = numbersLength; i >= 1; i--) {
    sum += +value.charAt(numbersLength - i) * pos--;

    if (pos < 2) {
      pos = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  return result === +lastDigits.charAt(1);
};

export const validateBRPhone = (value?: string): boolean => {
  if (!value?.includes('+55')) {
    return true;
  }

  return !!value.replace(/\D/g, '').match(/^\d{2}\d{2}9?\d{4}\d{4}$/);
};
