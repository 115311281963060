import { FormEvent } from 'react';

export const cep = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 9;

  e.currentTarget.value = e.currentTarget.value
    .replace(/\D/g, '')
    .replace(/^(\d{5})(\d)/, '$1-$2');

  return e;
};

export const cnpj = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 18;

  e.currentTarget.value = e.currentTarget.value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');

  return e;
};

export const cpf = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 14;

  if (!e.currentTarget.value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    e.currentTarget.value = e.currentTarget.value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{2})$/, '$1-$2');
  }

  return e;
};

export const time = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 5;

  e.currentTarget.value = e.currentTarget.value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1:$2');

  return e;
};

export const rg = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 10;

  if (!e.currentTarget.value.match(/^(\d{2}).(\d{3}).(\d{3})$/)) {
    e.currentTarget.value = e.currentTarget.value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2');
  }

  return e;
};

export const refMask = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');

  return e;
};

export const money = (
  e: FormEvent<HTMLInputElement>,
): FormEvent<HTMLInputElement> => {
  e.currentTarget.maxLength = 17;

  e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');

  if (e.currentTarget.value.length) {
    e.currentTarget.value = `R$ ${e.currentTarget.value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2')
      .replace(/(?=(\d{3})+(\D))\B/g, '.')}`;
  }

  return e;
};
