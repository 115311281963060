import React, { createContext, useCallback, useState, useContext } from 'react';
import { IDetailedActivity } from '../services/Activities/ShowActivitiesService';
import { maskDate } from '../utils/formatters/handleMask';

export interface IActivityContextState extends IDetailedActivity {
  formattedCreatedAt: string;
  // schedules: IActivityContextSchedule[];
}

interface IActivityContextData {
  activity?: IActivityContextState;
  handleActivity: (activity?: IDetailedActivity) => void;
}

const ActivityContext = createContext<IActivityContextData>(
  {} as IActivityContextData,
);

const ActivityProvider: React.FC = ({ children }) => {
  const [activity, setActivity] = useState<IActivityContextState>();

  const handleActivity = useCallback((activityData?: IDetailedActivity) => {
    if (activityData) {
      const parsedActivity = {
        ...activityData,
        formattedCreatedAt: maskDate(activityData.createdAt),
      };

      setActivity(parsedActivity);
    } else {
      setActivity(undefined);
    }
  }, []);

  return (
    <ActivityContext.Provider
      value={{
        activity,
        handleActivity,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};

const useActivity = (): IActivityContextData => useContext(ActivityContext);

export { ActivityProvider, useActivity };
