import { IActivityScheduleItemBase } from '../../models/activities';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IListActivityScheduleItemsProps {
  scheduleId: string;
  bookedDate?: string;
  bookingId?: string;
}

export interface IAvailableScheduleItems {
  activityScheduleItems: IActivityScheduleItemBase[];
  activityScheduleRequiredItems: IActivityScheduleItemBase[];
}

export const listAvailableActivityScheduleItemsService = async ({
  bookedDate,
  scheduleId,
  bookingId,
}: IListActivityScheduleItemsProps): Promise<IAvailableScheduleItems> => {
  const { data } = await jhsfBookingsApi.get<IAvailableScheduleItems>(
    `/activities/schedules/${scheduleId}/items`,
    {
      params: {
        bookedDate,
        bookingId,
      },
    },
  );

  return data;
};
