import { INotificationBase } from '../../models/notifications';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IListNotificationsProps {
  isUnread?: boolean;
}

export const listNotifications = async ({
  isUnread,
}: IListNotificationsProps): Promise<INotificationBase[]> => {
  const { data } = await jhsfBookingsApi.get<INotificationBase[]>(
    '/notifications',
    {
      params: { isUnread },
    },
  );

  return data;
};
