import React from 'react';
import { ActivityProvider } from './activity';

import { AuthProvider } from './auth';
import { BookingProvider } from './booking';
import { BookingQueueProvider } from './bookingQueue';
import { CalendarEventProvider } from './calendarEvent';
import { SidebarDrawerProvider } from './sidebarDrawer';
import { SpotProvider } from './spot';
import { UserProvider } from './user';
import { VentureProvider } from './venture';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <SidebarDrawerProvider>
      <VentureProvider>
        <SpotProvider>
          <ActivityProvider>
            <CalendarEventProvider>
              <UserProvider>
                <BookingProvider>
                  <BookingQueueProvider>{children}</BookingQueueProvider>
                </BookingProvider>
              </UserProvider>
            </CalendarEventProvider>
          </ActivityProvider>
        </SpotProvider>
      </VentureProvider>
    </SidebarDrawerProvider>
  </AuthProvider>
);

export default AppProvider;
