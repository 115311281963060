import { IAddressBase } from '../../models/address';
import { IFeatureBase, IFeatureGroupBase } from '../../models/features';
import { IUserBase, UserGender } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IUpdateUserProfileProps {
  address?: IAddressBase;
  bio?: string;
  birthDate?: Date;
  cpf?: string;
  cnpj?: string;
  companyName?: string;
  email: string;
  gender?: UserGender;
  name: string;
  password?: string;
  passwordConfirmation?: string;
  tradeName?: string;
  phone?: string;
}

export interface IUpdateProfileDetailedFeatureGroup extends IFeatureGroupBase {
  features: IFeatureBase[];
}

export interface IUpdatedProfile extends IUserBase {
  featureGroup: IUpdateProfileDetailedFeatureGroup;
}

export const updateUsersProfileService = async ({
  address,
  bio,
  birthDate,
  cpf,
  cnpj,
  companyName,
  email,
  gender,
  name,
  password,
  passwordConfirmation,
  tradeName,
  phone,
}: IUpdateUserProfileProps): Promise<IUpdatedProfile> => {
  const { data } = await jhsfBookingsApi.put<IUpdatedProfile>(
    '/users/profile',
    {
      address,
      bio,
      birthDate,
      cpf,
      cnpj,
      companyName,
      email,
      gender,
      name,
      password,
      passwordConfirmation,
      tradeName,
      phone,
    },
  );

  return data;
};
