import { useState, ChangeEvent, useMemo, useEffect } from 'react';

import { AsYouType } from 'libphonenumber-js';
import {
  Text,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  Select,
  Box,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { countries } from './countries';

export interface IPhoneInputProps extends InputProps {
  value?: string;
  defaultCountryCode?: string;
  onChangePhone: (value: string) => void;
}

export const PhoneInput = ({
  value,
  defaultCountryCode = '+55',
  isDisabled,
  onChangePhone,
  ...rest
}: IPhoneInputProps): JSX.Element => {
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [selectedCountryCode, setSelectedCountryCode] = useState<
    string | undefined
  >(defaultCountryCode);

  const options = useMemo(
    () =>
      countries.map((country) => ({
        label: country.dialCode,
        value: country.dialCode,
      })),
    [],
  );

  useEffect(() => {
    if (value) {
      const [countryCode, ...rst] = value.split(' ');

      setSelectedCountryCode(countryCode);
      setPhoneNumber(rst.join(' '));
    }
  }, [value]);

  const onCountryChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    setSelectedCountryCode(e.target.value);

    onChangePhone(`${e.target.value} ${phoneNumber}`);
  };

  const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (selectedCountryCode?.replace(/\D/g, '') === '55') {
      e.currentTarget.maxLength = 13;
    } else {
      e.currentTarget.maxLength = 12;
    }

    const cleanNumber = e.target.value.replace(/\D/g, '');

    setPhoneNumber(cleanNumber);

    if (!cleanNumber.length) {
      onChangePhone('');

      return;
    }

    const parsedNumber = new AsYouType().input(
      `${selectedCountryCode}${e.target.value.replace(/\D/g, '')}`,
    );

    const gapIndex = parsedNumber.indexOf(' ');

    if (gapIndex > 0) {
      const parsedPhoneNumberWithoutCountryCode = parsedNumber.slice(gapIndex);

      e.currentTarget.value = parsedPhoneNumberWithoutCountryCode;
    }

    onChangePhone(parsedNumber);
  };

  return (
    <InputGroup size="lg">
      <InputLeftElement width="20">
        <Select
          id="dialCode"
          borderColor="gray.300"
          bg="gray.100"
          focusBorderColor="blue.300"
          isDisabled={isDisabled}
          variant="outline"
          size="lg"
          zIndex={1}
          opacity={0}
          position="absolute"
          iconColor="transparent"
          value={selectedCountryCode}
          onChange={onCountryChange}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>

        <Flex
          px="1"
          flex={1}
          alignItems="center"
          justify="space-between"
          borderRightWidth={1}
          borderColor="gray.300"
        >
          <Box flex={1} textAlign="center">
            <Text>{selectedCountryCode}</Text>
          </Box>

          <Icon as={ChevronDownIcon} />
        </Flex>
      </InputLeftElement>

      <Input
        borderColor="gray.300"
        bg="gray.100"
        focusBorderColor="blue.300"
        isDisabled={isDisabled}
        variant="outline"
        size="lg"
        pl="5.5rem"
        type="tel"
        value={phoneNumber}
        onChange={onPhoneNumberChange}
        {...rest}
      />
    </InputGroup>
  );
};
