import axios, { AxiosResponse } from 'axios';

export interface ICountryResponse {
  id: {
    M49: number;
    'ISO-ALPHA-2': string;
    'ISO-ALPHA-3': string;
  };
  nome: string;
}

export interface ICountry {
  id: {
    M49: number;
    isoAlpha2: string;
    isoAlpha3: string;
  };
  name: string;
}

export const searchCountriesService = async (): Promise<
  AxiosResponse<ICountry[]>
> => {
  const countriesResponse = await axios.get<ICountryResponse[]>(
    'https://servicodados.ibge.gov.br/api/v1/localidades/paises',
  );

  const countries: ICountry[] = countriesResponse.data.map((country) => ({
    id: {
      M49: Number(country.id.M49),
      isoAlpha2: country.id['ISO-ALPHA-2'],
      isoAlpha3: country.id['ISO-ALPHA-3'],
    },
    name: country.nome,
  }));

  return { ...countriesResponse, data: countries };
};
