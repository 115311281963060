import {
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputFieldProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction, useCallback } from 'react';
import { FieldError } from 'react-hook-form';

interface INumberInputProps extends NumberInputFieldProps {
  name: string;
  label?: string;
  error?: FieldError;
  precision?: number;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, INumberInputProps> =
  ({ name, label, precision, error = null, ...rest }, ref) => {
    const handleInputMask = useCallback(
      (e: React.FormEvent<HTMLInputElement>): void => {
        e.currentTarget.value = e.currentTarget.value.replace(/\D/g, '');
      },
      [],
    );

    return (
      <FormControl isInvalid={!!error}>
        {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

        <ChakraNumberInput precision={precision} size="lg">
          <NumberInputField
            ref={ref}
            name={name}
            id={name}
            borderColor="gray.300"
            bg="gray.100"
            focusBorderColor="blue.300"
            variant="outline"
            onKeyUp={handleInputMask}
            {...rest}
          />
        </ChakraNumberInput>

        {!!error && (
          <FormErrorMessage position="absolute">
            {error.message}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  };

export const NumberInput = forwardRef(InputBase);
