import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IDeleteActivityItemsProps {
  activityItemId: string;
  isVerified?: boolean;
}

const deleteActivityItemsService = async ({
  activityItemId,
  isVerified,
}: IDeleteActivityItemsProps): Promise<void> => {
  await jhsfBookingsApi.delete(`/activities/items/${activityItemId}`, {
    params: { isVerified },
  });
};

export default deleteActivityItemsService;
