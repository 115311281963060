import {
  IActivityScheduleBase,
  IActivityScheduleBlockBase,
} from '../../models/activities';
import { IBookingBase } from '../../models/bookings';
import { ISpotModalityBase } from '../../models/spots';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export interface IActivityScheduleListItem extends IActivityScheduleBase {
  bookings: IBookingBase[];
  modality?: ISpotModalityBase;
  scheduleBlocks: IActivityScheduleBlockBase[];
}

export interface IActivityScheduleList {
  items: IActivityScheduleListItem[];
  pages: number;
  total: number;
}

interface IListActivityScheduleProps {
  activityId: string;
  showExpired?: boolean;
  sort?: string;
  order?: 'ASC' | 'DESC';
  skip?: number;
  take?: number;
}

export const listActivitiesSchedulesService = async ({
  activityId,
  showExpired,
}: IListActivityScheduleProps): Promise<IActivityScheduleList> => {
  const { data } = await jhsfBookingsApi.get<IActivityScheduleList>(
    `/activities/${activityId}/schedules/list`,
    {
      params: {
        showExpired,
      },
    },
  );

  return data;
};
