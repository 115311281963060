import { useToast } from '@chakra-ui/toast';
import { useState, useEffect } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import { searchCountriesService } from '../../../services/Address/SearchCountriesService';
import { ReactSelect } from '../ReactSelect';

interface ICountrySelectProps {
  control: Control<FieldValues>;
  errors: FieldErrors<FieldValues>;
}

interface ICountryOption {
  label: string;
  value: string;
}

const CountrySelect = ({
  control,
  errors,
}: ICountrySelectProps): JSX.Element => {
  const toast = useToast();
  const [countries, setCountries] = useState<ICountryOption[]>([]);

  useEffect(() => {
    const loadCountries = async (): Promise<void> => {
      try {
        const countriesResponse = await searchCountriesService();
        const countrySelectOptions = countriesResponse.data.map((c) => ({
          label: `${c.id.isoAlpha2}`,
          value: c.id.isoAlpha2,
        }));
        setCountries(countrySelectOptions);
      } catch (error) {
        toast({
          description: 'Não foi possivel obter a lista de paises',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
          status: 'warning',
          variant: 'subtle',
        });
      }
    };

    loadCountries();
  }, [toast]);

  return (
    <ReactSelect
      control={control}
      label="País"
      name="address.country"
      options={countries}
      error={errors.address?.country}
    />
  );
};

export default CountrySelect;
