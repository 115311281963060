import { useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { CalendarIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { IActivityScheduleItemBase } from '../../../../../../../../../../../../../models/activities';
import { IUserBase } from '../../../../../../../../../../../../../models/users';
import { maskShortDate } from '../../../../../../../../../../../../../utils/formatters/handleMask';
import { BookingQueueTable } from './components/BookingQueueTable';

export interface IActivityScheduleItem extends IActivityScheduleItemBase {
  formattedPrice?: string;
  user?: IUserBase;
}

interface IActivityScheduleBookingQueueModalProps {
  scheduleId: string;
  isOpen: boolean;
  onClose: () => void;
}
export const ActivityScheduleBookingQueueModal = ({
  scheduleId,
  isOpen,
  onClose,
}: IActivityScheduleBookingQueueModalProps): JSX.Element => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleSelectedDate = (date: Date): void => {
    setSelectedDate(date);
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <Modal
        size="6xl"
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={handleClose}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>
            <Heading size="lg" fontWeight="normal">
              Fila de reservas do evento
            </Heading>
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody minH="80vh" overflow="auto">
            <HStack justify="center">
              <Box>
                <ReactDatePicker
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                  onChange={handleSelectedDate}
                  minDate={new Date()}
                  todayButton="Hoje"
                  selected={selectedDate}
                  customInput={
                    <Button alignItems="center" justifyContent="space-between">
                      <CalendarIcon mr="2" />

                      <Text textTransform="capitalize" fontWeight="bold">
                        {maskShortDate(selectedDate)}
                      </Text>
                    </Button>
                  }
                />
              </Box>
            </HStack>

            <BookingQueueTable
              flex={1}
              activityScheduleId={scheduleId}
              bookedDate={selectedDate}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
