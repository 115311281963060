import { Flex, FlexProps } from '@chakra-ui/react';
import { BookingDetailsTabs } from './components/BookingDetailsTabs';

const BookingDetailsData = (props: FlexProps): JSX.Element => (
  <Flex
    direction="column"
    w="100%"
    borderRadius={8}
    bg="white"
    p="8"
    {...props}
  >
    <BookingDetailsTabs />
  </Flex>
);

export default BookingDetailsData;
