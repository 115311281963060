import { Icon, Tooltip } from '@chakra-ui/react';
import { PiCheckCircle, PiWarningCircle } from 'react-icons/pi';
import {
  UserAvatarStatus,
  UserFaceIdStatus,
} from '../../../../../models/users';

enum FaceIdStatusBadgeColor {
  ENABLED = 'green.400',
  DISABLED = 'gray.400',
}

enum AvatarStatusTooltipLabel {
  EMPTY = 'Sem foto',
  VALID = '',
  INVALID = 'Foto inválida',
  NOT_VERIFIED = 'Foto não verificada',
  UNAVAILABLE = 'FaceID desativado',
}

interface IFaceIdStatusBadgeProps {
  faceIdStatus: UserFaceIdStatus;
  avatarStatus: UserAvatarStatus;
}

const FaceIdStatusBadge = ({
  faceIdStatus,
  avatarStatus,
}: IFaceIdStatusBadgeProps): JSX.Element => (
  <Tooltip
    placement="bottom"
    hasArrow
    label={
      faceIdStatus === UserFaceIdStatus.DISABLED
        ? AvatarStatusTooltipLabel[avatarStatus]
        : ''
    }
  >
    <span>
      <Icon
        as={
          faceIdStatus === UserFaceIdStatus.ENABLED
            ? PiCheckCircle
            : PiWarningCircle
        }
        color={FaceIdStatusBadgeColor[faceIdStatus]}
        fontSize={26}
        cursor="pointer"
      />
    </span>
  </Tooltip>
);

export default FaceIdStatusBadge;
