import {
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
  FormLabel,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';

import { FieldError } from 'react-hook-form';

interface ISwitchProps extends ChakraSwitchProps {
  name: string;
  label?: string;
  error?: FieldError;
}

const SwitchBase: ForwardRefRenderFunction<HTMLInputElement, ISwitchProps> = (
  { name, label, error = null, ...rest },
  ref,
) => (
  <FormControl isInvalid={!!error} w="auto">
    {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

    <ChakraSwitch ref={ref} name={name} id={name} {...rest} />

    {!!error && (
      <FormErrorMessage position="absolute">{error.message}</FormErrorMessage>
    )}
  </FormControl>
);

export const Switch = forwardRef(SwitchBase);
