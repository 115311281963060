import { IActivityBase, IActivityScheduleBase } from '../../models/activities';
import {
  IBookingBase,
  IBookingCheckinBase,
  IBookingItemBase,
  IBookingPaymentBase,
  IBookingPaymentItemBase,
  IBookingScheduleItemBase,
} from '../../models/bookings';
import { IFeatureGroupBase } from '../../models/features';
import { ISpotBase } from '../../models/spots';
import { IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export interface IDetailedBookingActivity extends IActivityBase {
  spot: ISpotBase;
}

export interface IDetailedBookingActivitySchedule
  extends IActivityScheduleBase {
  activity: IDetailedBookingActivity;
}

export interface IDetailedBookingUser extends IUserBase {
  featureGroup: IFeatureGroupBase;
}

export interface IDetailedBookingActivityScheduleItem
  extends IBookingScheduleItemBase {
  user?: IUserBase;
}

interface IDetailedBookingPayment extends IBookingPaymentBase {
  items: IBookingPaymentItemBase[];
}

export interface IDetailedBooking extends IBookingBase {
  activitySchedule: IDetailedBookingActivitySchedule;
  checkins: IBookingCheckinBase[];
  user: IDetailedBookingUser;
  bookingItems: IBookingItemBase[];
  payment?: IDetailedBookingPayment;
  bookingScheduleItems: IDetailedBookingActivityScheduleItem[];
}

export const showBookingsService = async (
  bookingId: string,
): Promise<IDetailedBooking> => {
  const { data } = await jhsfBookingsApi.get<IDetailedBooking>(
    `/bookings/${bookingId}`,
  );

  return data;
};
