import { useLocation } from 'react-router-dom';
import { Flex, useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import axios from 'axios';
import { DefaultLayout } from '../../../_layout/DefaultLayout';
import { showSpotsService } from '../../../../../services/Spots/ShowSpotsService';
import { translateError } from '../../../../../utils/errors';
import { useSpot } from '../../../../../hooks/spot';
import SpotDetailsData from './components/SpotDetailsData';
import { SpotDetailsInfo } from './components/SpotDetailsInfo';

interface ILocationState {
  spotId: string;
}

export const SpotDetails = (): JSX.Element => {
  const toast = useToast();
  const { handleSpot, spot } = useSpot();

  const {
    state: { spotId },
  } = useLocation<ILocationState>();
  useEffect(() => {
    async function loadSpot(): Promise<void> {
      try {
        const spotData = await showSpotsService(spotId);

        handleSpot(spotData);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os detalhes do local, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    }

    loadSpot();

    return () => handleSpot(undefined);
  }, [handleSpot, spotId, toast]);

  return (
    <DefaultLayout>
      {spot && (
        <Flex direction="column" w="100%">
          <SpotDetailsInfo minW="510px" />

          <SpotDetailsData flex="1" mt="8" minW="510px" />
        </Flex>
      )}
    </DefaultLayout>
  );
};
