import { IBookingCheckinBase } from '../../models/bookings';
import { IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

export interface IBookingCheckin extends IBookingCheckinBase {
  user: IUserBase;
}

export const createBookingCheckinsService = async (
  bookingId: string,
): Promise<IBookingCheckin> => {
  const { data } = await jhsfBookingsApi.post<IBookingCheckin>(
    `/bookings/${bookingId}/checkins`,
  );

  return data;
};
