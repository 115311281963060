import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ButtonGroup,
  Text,
  HStack,
  VStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { addDays, addHours } from 'date-fns';
import { DatePicker } from '../../../../../components/Form/DatePicker';
import { useUser } from '../../../../../hooks/user';
import { Switch } from '../../../../../components/Form/Switch';
import { UpdateUserBlocksService } from '../../../../../services/Users/UpdateUserBlocksService';

interface IUserBlockModalFormData {
  blockExpiresAt?: Date | null;
  isBlocked: boolean;
}

interface IUserBlockModalProps {
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const userBlockFormSchema = Yup.object().shape({
  blockExpiresAt: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  isBlocked: Yup.boolean(),
});

export const UserBlockModal = ({
  description,
  isOpen,
  onClose,
  onSave,
}: IUserBlockModalProps): JSX.Element => {
  const { user: member, handleUser: handleMember } = useUser();

  const { control, register, formState, handleSubmit, reset, setValue, watch } =
    useForm({
      resolver: yupResolver(userBlockFormSchema),
    });

  const { errors } = formState;

  const currentIsBlocked = watch('isBlocked');

  useEffect(() => {
    if (currentIsBlocked) {
      setValue('blockExpiresAt', null);
    }
  }, [currentIsBlocked, setValue]);

  useEffect(() => {
    if (member) {
      reset({
        blockExpiresAt: member.blockedUntil
          ? addHours(new Date(member.blockedUntil), 3)
          : undefined,
        isBlocked: member.isBlocked,
      });
    }
  }, [member, reset]);

  const handleClose = (): void => {
    reset({});

    onClose();
  };

  const handleUserBlock: SubmitHandler<IUserBlockModalFormData> = useCallback(
    async ({ isBlocked, blockExpiresAt }) => {
      if (member) {
        const updatedMember = await UpdateUserBlocksService({
          userId: member.id,
          blockExpiresAt,
          isBlocked,
        });

        handleMember(updatedMember);

        onSave();
      }
    },
    [handleMember, member, onSave],
  );

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleUserBlock)}>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Gerenciamento de bloqueios
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text color="red.500">ATENÇÃO:</Text>

          <Text mt="2">
            {description ??
              'Ao bloquear ou suspender um membro serão canceladas todas as reservas/filas pendentes incluindo dos seus dependentes e convidados.'}
          </Text>

          <Text mt="8">Período de suspensão:</Text>

          <VStack align="flex-start" spacing="4" mt="4">
            <Switch
              label="Indeterminado"
              error={errors.isBlocked}
              {...register('isBlocked')}
            />

            <HStack align="flex-end" spacing="4">
              <DatePicker
                label="Término"
                isDisabled={currentIsBlocked}
                hideTodayButton
                minDate={addDays(new Date(), 1)}
                placeholder={currentIsBlocked ? 'Indeterminado' : undefined}
                control={control}
                error={errors.blockExpiresAt}
                {...register('blockExpiresAt')}
              />

              <Text display="block">às 00:00 horas</Text>
            </HStack>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup mt="4">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
