import { IUserCategoryBase } from '../../models/ventures';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface ICreateUsercategoriesProps {
  guestBookingPrice?: number;
  maxDayBookings?: number;
  maxGuestDayBookings?: number;
  title: string;
  ventureId: string;
}

export type INewUserCategory = IUserCategoryBase;

export const createUserCategoriesService = async ({
  guestBookingPrice,
  maxDayBookings,
  maxGuestDayBookings,
  title,
  ventureId,
}: ICreateUsercategoriesProps): Promise<INewUserCategory> => {
  const { data } = await jhsfBookingsApi.post<INewUserCategory>(
    '/user-categories',
    {
      guestBookingPrice,
      maxDayBookings,
      maxGuestDayBookings,
      title,
      ventureId,
    },
  );

  return data;
};
