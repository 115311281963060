import { IFeatureGroupBase } from '../../models/features';
import { IVentureBase } from '../../models/ventures';
import { IUserBase } from '../../models/users';
import jhsfBookingsApi from '../apis/jhsfBookingsApi';

interface IListUserProps {
  cpf?: string;
  email?: string;
  experience?: number;
  featureGroups?: Array<string>;
  hostId?: string;
  ignoreFeatureGroups?: Array<string>;
  minExperience?: number;
  name?: string;
  phone?: string;
  ventureId?: string;
  sort?: 'cpf' | 'name' | 'phone' | 'email' | 'createdAt';
  order?: 'ASC' | 'DESC';
  limit?: number;
  page?: number;
}

export interface IUserListItem extends IUserBase {
  featureGroup?: IFeatureGroupBase;
  venture?: IVentureBase;
}

export interface IUserList {
  items: IUserListItem[];
  pages: number;
  total: number;
}

export const listUsersService = async (
  filters?: IListUserProps,
): Promise<IUserList> => {
  const params =
    filters && Object.keys(filters).length !== 0
      ? {
          cpf: filters.cpf || undefined,
          email: filters.email || undefined,
          featureGroups: filters.featureGroups || undefined,
          hostId: filters.hostId || undefined,
          ignoreFeatureGroups: filters.ignoreFeatureGroups || undefined,
          experience: filters.experience,
          minExperience: filters.minExperience || undefined,
          name: filters.name || undefined,
          phone: filters.phone || undefined,
          ventureId: filters.ventureId || undefined,
          sort: filters.sort,
          order: filters.order,
          limit: filters.limit,
          page: filters.page,
        }
      : undefined;

  const { data } = await jhsfBookingsApi.get<IUserList>('/users', { params });

  return data;
};
