import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';
import './styles.css';

import { Box, useToast } from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { lastDayOfWeek, subDays } from 'date-fns';

import ScheduleBookingsModal from './components/ScheduleBookingsModal';
import { useActivity } from '../../../../../../../../../../../hooks/activity';
import { translateError } from '../../../../../../../../../../../utils/errors';
import { EventComponent } from './components/EventComponent';
import { ToolbarComponent } from './components/ToolbarComponent';
import { BookingRegisterModal } from '../BookingRegisterModal';
import {
  IWeekdayActivitySchedule,
  listWeekdaysActivitiesSchedulesService,
} from '../../../../../../../../../../../services/Activities/ListWeekdaysActivitySchedulesService';
import { useCalendarEvents } from '../../../../../../../../../../../hooks/calendarEvent';

const localizer = momentLocalizer(moment);

export interface ICalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  schedules: IWeekdayActivitySchedule[];
  isFull?: boolean;
  isActive: boolean;
}

export const ActivityCalendar: React.FC = () => {
  const toast = useToast();

  const { activity } = useActivity();
  const { calendarEvents, handleCalendarEvents } = useCalendarEvents();

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    subDays(lastDayOfWeek(new Date()), 6),
  );
  const [isScheduleBookingsModalVisible, setIsScheduleBookingsModalVisible] =
    useState<boolean>(false);
  const [selectedSchedule, setSelectedSchedule] = useState<ICalendarEvent>();
  const [activitySchedule, setActivitySchedule] =
    useState<IWeekdayActivitySchedule>();

  const loadSchedules = useCallback(
    async (activityId: string) => {
      try {
        setLoading(true);

        const events = await listWeekdaysActivitiesSchedulesService({
          activityId,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          addDay: 6,
        });

        const eventsParsed = events.reduce((parsedEvents, event) => {
          const eventId = event.date + event.startTime + event.endTime;

          const eventIndex = parsedEvents.findIndex(
            (schedule) => schedule.id === eventId,
          );

          if (eventIndex >= 0) {
            const eventsAux = parsedEvents;

            const eventSchedules = [
              ...parsedEvents[eventIndex].schedules,
              event,
            ];

            const eventVacancies = eventSchedules.reduce(
              (vacancies, e) => vacancies + e.vacancies - e.booked,
              0,
            );

            eventsAux[eventIndex] = {
              ...parsedEvents[eventIndex],
              schedules: eventSchedules,
              title:
                eventVacancies > 0 ? `${eventVacancies} vaga(s)` : 'Lotado',
              isFull: eventVacancies <= 0,
              isActive: event.isActive,
            };

            return eventsAux;
          }

          const eventVacancies = event.vacancies - event.booked;

          return [
            ...parsedEvents,
            {
              id: eventId,
              end: new Date(`${event.date} ${event.endTime}`),
              start: new Date(`${event.date} ${event.startTime}`),
              schedules: [event],
              title:
                eventVacancies > 0 ? `${eventVacancies} vaga(s)` : 'Lotado',
              isFull: eventVacancies <= 0,
              isActive: event.isActive,
            },
          ];
        }, [] as ICalendarEvent[]);

        handleCalendarEvents(eventsParsed);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os eventos, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [handleCalendarEvents, startDate, toast],
  );

  useEffect(() => {
    if (activity) {
      loadSchedules(activity.id);
    }
  }, [activity, loadSchedules]);

  const toggleScheduleBookingsModalVisible = (): void => {
    setIsScheduleBookingsModalVisible((prevState) => !prevState);
  };
  const handleScheduleBookingsModal = (event?: ICalendarEvent): void => {
    setSelectedSchedule(event);
    toggleScheduleBookingsModalVisible();
  };

  const handleCreateBookingModal = (
    schedule?: IWeekdayActivitySchedule,
  ): void => {
    setActivitySchedule(schedule);
    toggleScheduleBookingsModalVisible();
  };

  return (
    <Box>
      {!!selectedSchedule && (
        <ScheduleBookingsModal
          eventId={selectedSchedule.id}
          isOpen={isScheduleBookingsModalVisible}
          onClose={handleScheduleBookingsModal}
          onCreateBooking={handleCreateBookingModal}
        />
      )}

      {!!activitySchedule && (
        <BookingRegisterModal
          activityScheduleId={activitySchedule.activityScheduleId}
          defaultBookedDate={selectedSchedule?.start}
          isOpen={!!activitySchedule}
          minExperience={activitySchedule.experience}
          startDate={activitySchedule.startDate}
          onClose={handleCreateBookingModal}
        />
      )}

      <Calendar
        localizer={localizer}
        components={{
          toolbar: (e) => ToolbarComponent({ ...e, loading }),
          event: (e) => EventComponent(e),
        }}
        views={['week', 'day']}
        defaultView="week"
        dayLayoutAlgorithm="no-overlap"
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ flex: 1 }}
        messages={{
          next: 'Próximo',
          previous: 'Anterior',
          today: 'Hoje',
          month: 'Mês',
          week: 'Semana',
          day: 'Dia',
          agenda: 'Agenda',
          date: 'Data',
          time: 'Hora',
          event: 'Evento',
          allDay: 'Dia todo',
          tomorrow: 'Amanhã',
          yesterday: 'Ontem',
          work_week: 'Dias úteis',
          showMore: (total) => `+${total}`,
        }}
        onSelectEvent={(event) => handleScheduleBookingsModal(event)}
        onRangeChange={(range) => {
          const rangeDate = range as Date[];
          setStartDate(rangeDate[0]);
        }}
        culture="pt-BR"
      />
    </Box>
  );
};
