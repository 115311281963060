import { IEntityBase } from './entityBase';

export enum BookingPaymentStatus {
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  ONGOING = 'ONGOING',
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  VALIDATING = 'VALIDATING',
  PAYMENT_EXTERNAL = 'PAYMENT_EXTERNAL',
}

export enum PrettyBookingPaymentStatus {
  PROCESSING = 'Registrando pagamento',
  FAILED = 'Falha no registro do pagamento',
  ONGOING = 'Pagemento em andamento',
  WAITING = 'Aguardando pagamento',
  APPROVED = 'Pagamento aprovado',
  PENDING = 'Processando pagamento',
  REJECTED = 'Pagamento rejeitado',
  CANCELED = 'Pagamento cancelado',
  VALIDATING = 'Validando pagamento',
  PAYMENT_EXTERNAL = 'Pagamento externo',
}

export interface IBookingPaymentItemBase extends IEntityBase {
  activityScheduleId: string;
  brand: string;
  costCenter: string;
  minUserExperience: number;
  name: string;
  price: number;
  productCode: string;
  productType: string;
  quantity: number;
  sellerId?: string | null;
  type: 'MEMBER_BOOKING_CHARGE' | 'GUEST_BOOKING_CHARGE' | 'ITEM_CHARGE';
  waveSide?: 'COMBO' | 'LEFT' | 'RIGHT' | null;
  paymentId: string;
}

export interface IBookingPaymentBase extends IEntityBase {
  orderRef: string;
  orderId: string;
  status: BookingPaymentStatus;
  bookingId: string;
  userId: string;
}

export interface IBookingCheckinBase extends IEntityBase {
  bookingId: string;
  userId: string;
}

export interface IBookingItemBase extends IEntityBase {
  activityItemId: string;
  description?: string;
  name: string;
  bookingId: string;
}

export interface IBookingScheduleItemBase extends IEntityBase {
  scheduleItemId: string;
  description?: string;
  name: string;
  price?: number | null;
  totalAmount: number;
  bookingId: string;
  isRequired: boolean;
  userId?: string | null;
}

export interface IBookingBase extends IEntityBase {
  activityScheduleId: string;
  bookedDate: string;
  bookingPrice?: number;
  description?: string;
  endTime?: string;
  isActive: boolean;
  isExpired?: boolean;
  startTime?: string;
  titularUserId: string;
  userId: string;
  deletedAt?: string;
}
