import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  useToast,
  ButtonGroup,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { MaskedInput } from '../../../../../../../../../../../../../components/Form/MaskedInput';
import { translateError } from '../../../../../../../../../../../../../utils/errors';
import { createUserCategoriesService } from '../../../../../../../../../../../../../services/UserCategories/CreateUserCategoriesService';
import { IUserCategoryBase } from '../../../../../../../../../../../../../models/ventures';
import { useVenture } from '../../../../../../../../../../../../../hooks/venture';
import { updateUserCategoriesService } from '../../../../../../../../../../../../../services/UserCategories/UpdateUserCategoriesService';
import { NumberInput } from '../../../../../../../../../../../../../components/Form/NumberInput';
import {
  maskMoney,
  unmaskNumber,
} from '../../../../../../../../../../../../../utils/formatters/handleMask';

interface IHandleUserCategoryModalFormData {
  guestBookingPrice?: number;
  maxDayBookings?: number;
  maxGuestDayBookings?: number;
  title: string;
}

interface IHandleUserCategoryModalProps {
  category?: IUserCategoryBase;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const userCategoryFormSchema = Yup.object().shape({
  guestBookingPrice: Yup.number()
    .integer('Valores inteiros')
    .positive('Valores maiores que zero')
    .nullable()
    .transform((_, originalValue) =>
      originalValue === '' ? null : unmaskNumber(originalValue),
    ),
  maxDayBookings: Yup.number().when('maxGuestDayBookings', {
    is: (val?: number) => !!val,
    then: Yup.number()
      .nullable()
      .integer('Valores inteiros')
      .min(
        Yup.ref('maxGuestDayBookings'),
        'Mínimo igual ao máx. reservas de convidados',
      )
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
    otherwise: Yup.number()
      .nullable()
      .integer('Valores inteiros')
      .positive('Valores maiores que zero')
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ),
  }),
  maxGuestDayBookings: Yup.number()
    .nullable()
    .integer('Valores inteiros')
    .positive('Valores maiores que zero')
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  title: Yup.string()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Requerido'),
});

export const HandleUserCategoryModal = ({
  category,
  isOpen,
  onClose,
  onSave,
}: IHandleUserCategoryModalProps): JSX.Element => {
  const toast = useToast();
  const { venture, handleVenture } = useVenture();

  const { register, formState, handleSubmit, reset } = useForm({
    resolver: yupResolver(userCategoryFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    reset({
      guestBookingPrice: category?.guestBookingPrice
        ? maskMoney(category.guestBookingPrice)
        : undefined,
      maxDayBookings: category?.maxDayBookings,
      maxGuestDayBookings: category?.maxGuestDayBookings,
      title: category?.title,
    });
  }, [category, reset]);

  const handleVentureModalitySubmit: SubmitHandler<IHandleUserCategoryModalFormData> =
    useCallback(
      async ({
        guestBookingPrice,
        maxDayBookings,
        maxGuestDayBookings,
        title,
      }) => {
        if (venture) {
          if (!category) {
            try {
              const newUserCategory = await createUserCategoriesService({
                guestBookingPrice,
                maxDayBookings,
                maxGuestDayBookings,
                title,
                ventureId: venture.id,
              });

              const userCategories = [
                newUserCategory,
                ...venture.userCategories,
              ];

              handleVenture({
                ...venture,
                userCategories,
              });

              reset({
                guestBookingPrice: null,
                maxDayBookings: null,
                maxGuestDayBookings: null,
                title: null,
              });

              onSave();

              toast({
                title: 'Cadastrado com sucesso',
                description: 'Categoria cadastrada corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            } catch (err) {
              if (axios.isAxiosError(err) && err.response?.status !== 401) {
                toast({
                  title: 'Falha ao cadastrar',
                  description:
                    translateError({ message: err.response?.data.message }) ||
                    'Ocorreu um erro ao cadastrar a categoria, tente novamente.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });
              }
            }
          } else {
            try {
              const updatedCategory = await updateUserCategoriesService({
                categoryId: category.id,
                guestBookingPrice,
                maxDayBookings,
                maxGuestDayBookings,
                title,
              });

              handleVenture({
                ...venture,
                userCategories: venture.userCategories.map((ventureModality) =>
                  ventureModality.id === updatedCategory.id
                    ? { ...ventureModality, ...updatedCategory }
                    : ventureModality,
                ),
              });

              reset({
                guestBookingPrice: null,
                maxDayBookings: null,
                maxGuestDayBookings: null,
                title: null,
              });

              onSave();

              toast({
                title: 'Atualizado com sucesso',
                description: 'Categoria atualizada corretamente.',
                status: 'success',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });
            } catch (err) {
              if (axios.isAxiosError(err) && err.response?.status !== 401) {
                toast({
                  title: 'Falha ao atualizar',
                  description:
                    translateError({ message: err.response?.data.message }) ||
                    'Ocorreu um erro ao atualizar a categoria, tente novamente.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'top-right',
                });
              }
            }
          }
        }
      },
      [category, handleVenture, onSave, reset, toast, venture],
    );

  const handleCloseModal = useCallback(() => {
    reset({
      guestBookingPrice: null,
      maxDayBookings: null,
      maxGuestDayBookings: null,
      title: null,
    });

    onClose();
  }, [onClose, reset]);

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />

      <ModalContent
        as="form"
        onSubmit={handleSubmit(handleVentureModalitySubmit)}
      >
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Categoria de membros
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="8">
            <MaskedInput
              label="Título"
              error={errors.title}
              {...register('title')}
            />

            <SimpleGrid
              columns={[1, 1, 3]}
              spacing="8"
              w="100%"
              alignItems="flex-end"
            >
              <NumberInput
                label="Reservas por dia"
                error={errors.maxDayBookings}
                {...register('maxDayBookings')}
              />

              <NumberInput
                label="Reservas de convidados por dia"
                error={errors.maxGuestDayBookings}
                {...register('maxGuestDayBookings')}
              />

              <MaskedInput
                label="Valor da reserva de convidados"
                mask="money"
                error={errors.guestBookingPrice}
                {...register('guestBookingPrice')}
              />
            </SimpleGrid>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
