import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ButtonGroup,
  Text,
  Switch,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from '../../../../../../../../../hooks/user';
import { enableUsersPaymentRegisterService } from '../../../../../../../../../services/Users/EnableUsersPaymentRegisterService';

interface IUserPaymentRegisterModalFormData {
  blockExpiresAt?: Date | null;
  paymentRegistered: boolean;
}

interface IUserPaymentRegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const userPaymentRegisterFormSchema = Yup.object().shape({
  blockExpiresAt: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  paymentRegistered: Yup.boolean(),
});

export const UserPaymentRegisterModal = ({
  isOpen,
  onClose,
  onSave,
}: IUserPaymentRegisterModalProps): JSX.Element => {
  const { user, handleUser: handleMember } = useUser();

  const { register, formState, handleSubmit, reset, setValue, watch } = useForm(
    {
      resolver: yupResolver(userPaymentRegisterFormSchema),
    },
  );

  const { errors } = formState;

  const currentpaymentRegistered = watch('paymentRegistered');

  useEffect(() => {
    if (currentpaymentRegistered) {
      setValue('blockExpiresAt', null);
    }
  }, [currentpaymentRegistered, setValue]);

  useEffect(() => {
    if (user) {
      reset({
        paymentRegistered: user.paymentRegistered,
      });
    }
  }, [user, reset]);

  const handleClose = (): void => {
    reset({});

    onClose();
  };

  const handleUserBlock: SubmitHandler<IUserPaymentRegisterModalFormData> =
    useCallback(
      async ({ paymentRegistered }) => {
        if (user && paymentRegistered) {
          const updatedUser = await enableUsersPaymentRegisterService(user.id);

          handleMember(updatedUser);

          onSave();
        }
      },
      [handleMember, user, onSave],
    );

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(handleUserBlock)}>
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Ativação de loja
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text mt="2">
            Ao ativar uma loja será possível cadastrar items de evento.
          </Text>

          <Switch
            mt="4"
            label="Indeterminado"
            error={errors.paymentRegistered}
            {...register('paymentRegistered')}
          />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup mt="4">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={formState.isSubmitting}
            >
              Salvar
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
