import { Flex, Button, Icon, Link as ChakraLink, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { RiSmartphoneLine } from 'react-icons/ri';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

interface ILocationState {
  loginPermissions: string[];
  ventureRef: string;
}

export const PasswordResetConfirm = (): JSX.Element => {
  const { state } = useLocation<ILocationState>();

  const handleOpenApp = useCallback((ventureRef: string) => {
    window.location.replace(
      `${ventureRef.toLowerCase()}://${ventureRef.toLowerCase()}`,
    );
  }, []);

  return (
    <Flex w="100vw" h="100vh" align="center" justify="center">
      <Flex
        maxWidth={360}
        minH={400}
        bg="white"
        p="8"
        borderRadius={8}
        flexDir="column"
      >
        <Text fontSize="1.75rem" fontWeight="bold" letterSpacing="tight">
          JHSF-BOOKINGS
          <Text as="span" ml="1" color="blue.500">
            .
          </Text>
        </Text>

        <Flex flexDirection="column" justifyContent="space-evenly" flex={1}>
          <Text fontSize="lg">Sua senha foi salva com sucesso!</Text>

          {state.loginPermissions.some((loginPermission) =>
            ['APP_CONCIERGE_ACCESS', 'APP_MEMBERS_ACCESS'].includes(
              loginPermission,
            ),
          ) && (
            <Button
              mt="8"
              colorScheme="green"
              size="lg"
              w="100%"
              onClick={() => handleOpenApp(state.ventureRef)}
              leftIcon={<Icon as={RiSmartphoneLine} fontSize="24" />}
            >
              Abrir aplicativo
            </Button>
          )}

          {state.loginPermissions.some(
            (loginPermission) => loginPermission === 'WEB_ACCESS',
          ) && (
            <ChakraLink as={Link} to="/" mt="2" mx="auto">
              Voltar para o login
            </ChakraLink>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
