import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Heading,
  ButtonGroup,
  VStack,
  useToast,
  Box,
  Text,
  Flex,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { ReactMultiSelect } from '../../../../../../../../../components/Form/ReactMultiSelect';
import { SelectOption } from '../../../../../../../../../components/Form/ReactSelect';
import { IBookingContextState } from '../../../../../../../../../hooks/booking';
import {
  IActivityScheduleItemBase,
  IActivityItemBase,
} from '../../../../../../../../../models/activities';
import { listAvailableActivityScheduleItemsService } from '../../../../../../../../../services/Activities/ListAvailableActivityScheduleItemsService';
import { showActivitiesService } from '../../../../../../../../../services/Activities/ShowActivitiesService';
import { IDetailedBooking } from '../../../../../../../../../services/Bookings/ShowBookingsService';
import { updateBookingItemsService } from '../../../../../../../../../services/Bookings/UpdateBookingItemsService';
import { translateError } from '../../../../../../../../../utils/errors';
import { maskMoney } from '../../../../../../../../../utils/formatters/handleMask';

type UpdateBookingFormData = {
  itemsId?: string[];
  scheduleItemsId?: string[];
};

interface IBookingUpdateModalScheduleItem extends IActivityScheduleItemBase {
  formattedPrice?: string;
}

interface IBookingUpdateModalProps {
  booking: IBookingContextState;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (booking: IDetailedBooking) => void;
}

const updateBookingFormSchema = Yup.object().shape({
  itemsId: Yup.array()
    .nullable()
    .transform((_, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
  scheduleItemsId: Yup.array()
    .nullable()
    .transform((_, originalValue) =>
      originalValue.map((val: SelectOption) => val.value),
    ),
});

export const BookingUpdateModal = ({
  booking,
  isOpen,
  onClose,
  onConfirm,
}: IBookingUpdateModalProps): JSX.Element => {
  const toast = useToast();

  const [scheduleItems, setScheduleItems] = useState<
    IBookingUpdateModalScheduleItem[]
  >([]);
  const [scheduleRequiredItems, setScheduleRequiredItems] = useState<
    IBookingUpdateModalScheduleItem[]
  >([]);
  const [activityItems, setActivityItems] = useState<IActivityItemBase[]>([]);

  const activityItemsSelectOptions = useMemo(
    () =>
      activityItems.map((activityItem) => ({
        label: activityItem.name,
        value: activityItem.id,
      })),
    [activityItems],
  );

  const scheduleItemsSelectOptions = useMemo(
    () =>
      scheduleItems.map((scheduleItem) => ({
        label: scheduleItem.formattedPrice
          ? scheduleItem.name.concat(` - ${scheduleItem.formattedPrice}`)
          : scheduleItem.name,
        value: scheduleItem.id,
      })),
    [scheduleItems],
  );

  const { formState, handleSubmit, reset, control, watch } = useForm({
    resolver: yupResolver(updateBookingFormSchema),
  });

  const selectedActivityItems = watch('itemsId');
  const selectedScheduleItems = watch('scheduleItemsId');

  const { errors } = formState;

  useEffect(() => {
    const loadScheduleItems = async (): Promise<void> => {
      try {
        const { activityScheduleItems, activityScheduleRequiredItems } =
          await listAvailableActivityScheduleItemsService({
            scheduleId: booking.activityScheduleId,
            bookedDate: booking.bookedDate,
            bookingId: booking.id,
          });

        setScheduleItems(
          activityScheduleItems.map((scheduleItem) => ({
            ...scheduleItem,
            formattedPrice: scheduleItem.price
              ? maskMoney(scheduleItem.price)
              : undefined,
          })),
        );

        setScheduleRequiredItems(
          activityScheduleRequiredItems.map((scheduleItem) => ({
            ...scheduleItem,
            formattedPrice: scheduleItem.price
              ? maskMoney(scheduleItem.price)
              : undefined,
          })),
        );
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status !== 401) {
          toast({
            title: 'Falha ao carregar dados',
            description:
              translateError({ message: err.response?.data.message }) ||
              'Ocorreu um erro ao carregar os opcionais do evento, tente novamente.',
            status: 'error',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });
        }
      }
    };

    if (isOpen) {
      loadScheduleItems();
    }
  }, [
    booking.activityScheduleId,
    booking.bookedDate,
    toast,
    isOpen,
    booking.id,
    selectedScheduleItems,
  ]);

  useEffect(() => {
    const loadActivityItems = async (): Promise<void> => {
      const activity = await showActivitiesService(
        booking.activitySchedule.activityId,
      );

      setActivityItems(activity.items);
    };

    if (isOpen) {
      loadActivityItems();
    }
  }, [booking.activitySchedule.activityId, isOpen, selectedActivityItems]);

  useEffect(() => {
    if (isOpen) {
      reset({
        itemsId: booking.bookingItems.map((item) => ({
          label: item.name,
          value: item.activityItemId,
        })),
        scheduleItemsId: booking.bookingScheduleItems
          .filter((scheduleItem) => !scheduleItem.isRequired)
          .map((scheduleItem) => ({
            label: scheduleItem.name.concat(
              ` - ${scheduleItem.formattedPrice}`,
            ),
            value: scheduleItem.scheduleItemId,
          })),
      });
    }
  }, [booking.bookingItems, booking.bookingScheduleItems, reset, isOpen]);

  const handleCloseModal = useCallback(() => {
    reset({
      itemsId: [],
      scheduleItemsId: [],
    });

    setActivityItems([]);
    setScheduleItems([]);

    onClose();
  }, [onClose, reset]);

  const handleBookingUpdateSubmit: SubmitHandler<UpdateBookingFormData> =
    useCallback(
      async ({ itemsId, scheduleItemsId }) => {
        try {
          const updatedBooking = await updateBookingItemsService({
            bookingId: booking.id,
            itemsId,
            scheduleItemsId,
          });

          toast({
            title: 'Atualizado com sucesso',
            description: 'A reserva foi atualizada corretamente.',
            status: 'success',
            duration: 3000,
            isClosable: true,
            variant: 'subtle',
            position: 'top-right',
          });

          onConfirm(updatedBooking);
          handleCloseModal();
        } catch (err) {
          if (axios.isAxiosError(err) && err.response?.status !== 401) {
            if (
              [
                'user-address-missing',
                'user-birth-date-missing',
                'user-cpf-missing',
                'user-phone-missing',
              ].includes(err.response?.data.message)
            ) {
              toast({
                title: 'Cadastro incompleto',
                description: `A reserva foi alterada, necessário completar o cadastro do membro para efetuar pagamento. ${translateError(
                  { message: err.response?.data.message },
                )}`,
                status: 'warning',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top-right',
              });

              handleCloseModal();

              onConfirm({
                ...booking,
                bookingItems: err.response?.data.metaData.bookingItems,
                bookingScheduleItems:
                  err.response?.data.metaData.bookingScheduleItems,
              });

              return;
            }

            toast({
              title: 'Falha na atualização',
              description:
                translateError({ message: err.response?.data.message }) ||
                'Ocorreu um erro ao atualizar a reserva, tente novamente.',
              status: 'error',
              duration: 3000,
              isClosable: true,
              variant: 'subtle',
              position: 'top-right',
            });
          }
        }
      },
      [booking, toast, onConfirm, handleCloseModal],
    );

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleCloseModal}>
      <ModalOverlay />

      <ModalContent
        as="form"
        onSubmit={handleSubmit(handleBookingUpdateSubmit)}
      >
        <ModalHeader>
          <Heading size="lg" fontWeight="normal">
            Alterar opcionais da reserva
          </Heading>
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <VStack spacing="8">
            {(!!activityItemsSelectOptions.length ||
              !!booking.bookingItems.length) && (
              <ReactMultiSelect
                label="Opcionais da atividade"
                name="itemsId"
                options={activityItemsSelectOptions}
                control={control}
                error={errors.itemsId}
                o
              />
            )}
            {(!!scheduleItemsSelectOptions?.length ||
              !!booking.bookingScheduleItems.length) && (
              <ReactMultiSelect
                label="Opcionais do evento"
                name="scheduleItemsId"
                options={scheduleItemsSelectOptions}
                control={control}
                error={errors.scheduleItemsId}
              />
            )}
            {!!scheduleRequiredItems.length && (
              <Flex direction="column" w="full">
                <Text fontWeight="medium" mr="3" mb="2">
                  Items obrigatórios
                </Text>

                <UnorderedList>
                  {scheduleRequiredItems.map((scheduleItem) => (
                    <ListItem key={scheduleItem.id}>
                      <Text>
                        {scheduleItem.formattedPrice
                          ? scheduleItem.name.concat(
                              ` - ${scheduleItem.formattedPrice}`,
                            )
                          : scheduleItem.name}
                      </Text>
                    </ListItem>
                  ))}
                </UnorderedList>
              </Flex>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Box>
            <ButtonGroup mt="6" w="full" justifyContent="flex-end">
              <Button colorScheme="blackAlpha" onClick={handleCloseModal}>
                Cancelar
              </Button>

              <Button
                colorScheme="green"
                type="submit"
                isLoading={formState.isSubmitting}
              >
                Salvar
              </Button>
            </ButtonGroup>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
